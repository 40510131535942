<template>
  <component
    :is="tag"
    class="d-flex align-items-center"
    v-on="$listeners"
  >
    <!-- d-flex align-items-center -->
    <!-- py-75 px-1 -->

    <slot name="contextmenu" />
    <slot name="checkbox" />
    <slot
      name="mask"
      class="chat-contact-mask"
    />

    <div @click="$emit('submit-show-content', todoData.id)">
      <b-avatar
        size="42"
        :src="`/admin/images/todo/items/${todoData.status}.svg`"
        class="badge-minimal mr-75"
        badge
        variant="light-secondary"
        :class="calcRemainDays(todoData.deadline_at) <= 1 && updateExtendedOnline(todoData.deadline_at) ? 'border-deadline': 'border'"
      >
        <template #badge>
          <span
            class="bullet filter-dot"
            :style="{'--text-color':`${todoData.priority_info.color}`}"
          />
        </template>
      </b-avatar>
    </div>

    <div class="d-flex align-self-start w-100 justify-content-between">
      <div
        class="chat-info flex-grow-1 pl-0"
        @click="$emit('submit-show-content', todoData.id)"
      >
        <div
          class="text-truncate"
          :class="{'text-primary': isChatContact }"
        >
          <!-- <small v-if="!todoData.display_status">
            排定於 {{ todoData.scheduled_at }} 顯示
          </small> -->
          <!-- .display_status -->
          <small v-if="todoData.department_id && (todoData.type_id || todoData.execution_type_id)">
            <!-- {{ todoData.department_info.name }} -->
            {{ todoData.type_info.name }} {{ todoData.execution_type_info.name }}
          </small>
          <!-- $store.state['admin-todo'].quickOptions. -->
          <small
            v-else
          >
            尚未設定
          </small>
        </div>

        <h5
          class="text-truncate mb-0"
          :class="!todoData.client_name && !todoData.department_id && !todoData.created_user_id && todoData.display_status ? 'mb-75' : 'mb-0'"
        >
          {{ todoData.title }}
        </h5>

        <!-- <small
          class="card-text text-truncate"
          style="font-size: 10px;"
          :class="!todoData.client_name ? 'mb-75' : 'mb-0'"
        >
          {{ todoData.type_info.name }} {{ todoData.execution_type_info.name }}
        </small> -->

        <div>
          <small
            v-if="!todoData.display_status"
            class="card-text text-truncate"
          >
            <!-- {{ new Date(todoData.scheduled_at) }} / {{ new Date() }} -->
            <span v-if="todoData.scheduled_at && new Date(todoData.scheduled_at) >= new Date() && updateExtendedOnline(todoData.scheduled_at, 'skip')">
              排定於
              <span v-if="calcRemainDays(todoData.scheduled_at) >= 1">{{ resolveTime(todoData.scheduled_at) }}</span>
              <span v-else>{{ updateExtendedOnline(todoData.scheduled_at, 'skip') }} 後</span>顯示
            </span>

            <span v-else-if="!todoData.scheduled_at">
              排定於 <span class="text-danger">永久😠</span> 後顯示
            </span>

            <div
              v-else
              class="text-truncate"
            >
              {{ todoData.client_name ? todoData.client_name : (todoData.department_id ? todoData.department_info.name : todoData.created_user_info.name ) }}
              <!-- department_id: null,
              created_user_id: null, -->
            </div>
          </small>

          <small
            v-else
            class="card-text text-truncate"
          >
            <div class="text-truncate">
              <!-- {{ todoData.client_name ? todoData.client_name : (todoData.department_id ? todoData.department_info.name : todoData.created_user_info.name) }} -->
              {{ (todoData.customer && todoData.customer.name) ? todoData.customer.name : (todoData.department_id ? todoData.department_info.name : todoData.created_user_info.name) }}
              {{ todoData.client_name ? `( 原: ${todoData.client_name} )` : null }}
            </div>
          </small>
        </div>

      </div>

      <div class="chat-meta text-nowrap">
        <small class="float-right my-25 chat-time">
          <span v-if="todoData.review_user_id">
            <b-img
              src="/admin/images/todo/action/done.svg"
              width="14"
              height="14"
              style="border: none"
            />
          </span>
          <span v-if="todoData.updated_at">
            <span
              v-b-tooltip.hover.focus.v-secondary
              :title="`${moment(todoData.updated_at).format('HH:mm')}`"
            >
              {{ updateOnline(todoData.updated_at, refonlineTime) }}
            </span>
          </span>
        </small>

        <div>
          <b-badge
            v-if="!todoData.has_view"
            pill
            variant="secondary"
            class="float-right"
          >
            <!-- badge-glow -->
            <span v-if="todoData.view_info.total === 0">
              {{ todoData.has_view ? '已讀' : '未讀' }}
            </span>
            <span
              v-else
            >
              {{ todoData.view_info.total }}
              <!-- style="color: black" -->
            </span>
          </b-badge>

          <small
            class="float-right mr-25"
          >
            <span v-if="calcRemainDays(todoData.deadline_at) <= 1 && updateExtendedOnline(todoData.deadline_at)">
              <b-img
                src="/admin/images/todo/action/alarm-clock.svg"
                width="16"
                height="16"
                style="border: none"
                class="alarm-clock"
              />
            </span>
          </small>
        </div>

        <!-- <div class="mt-3">
          <small class="float-right mr-25 mt-25">
            1234
          </small>
        </div> -->
      </div>
    </div>

    <!-- {{ todoData }} -->
  </component>
</template>

<script>
import moment from 'moment'
import {
  BBadge, VBTooltip, BImg, BAvatar,
} from 'bootstrap-vue'
import { useTodoSetting } from '../useTodo'

export default {
  components: {
    BImg,
    BBadge,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    todoData: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (處理時間)
    resolveTime(time) {
      if (time) return moment(time).format('YYYY/MM/DD HH:mm')
      return moment().format('YYYY/MM/DD HH:mm')
    },

    // (計算)剩餘天數
    calcRemainDays(end) {
      const startDate = moment(new Date())
      let daysDiff = 0
      const endDate = moment(end)
      daysDiff = endDate.diff(startDate, 'days')
      return daysDiff
    },

    updateExtendedOnline(time, skip) {
      const endTime = Math.floor(new Date(this.resolveTime(time).replace(/-/g, '/')).getTime() / 1000)
      const today = Math.floor(new Date().getTime() / 1000)
      let diffTime = endTime - today
      if (diffTime <= 0) return false
      if (this.todoData.status === 'completed') return false
      if (this.todoData.review_user_id) return false

      // 定義單位時間
      const second = 1
      const minute = second * 60
      const hour = minute * 60
      const day = hour * 24

      const days = Math.floor(diffTime / day)
      diffTime -= days * 24 * 60 * 60

      const hours = Math.floor(diffTime / hour)
      diffTime -= hours * 60 * 60

      const minutes = Math.floor(diffTime / minute)
      diffTime -= minutes * 60

      if (!skip) if (hours >= 6) return false

      if (days > 0) return `${days} 天 ${hours} 小時`
      if (hours > 0) return `${hours} 小時 ${minutes} 分`
      return `${minutes} 分 ${diffTime} 秒`
    },
  },
  setup() {
    const {
      // ui,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useTodoSetting()

    return {
      refonlineTime,
      onlineTime,
      updateOnline,
    }
  },
}
</script>

<style lang="scss" scoped>
.badge-minimal {
  position: relative;
}

.border-deadline {
  animation: breathe 1.5s infinite;
  @keyframes breathe {
    0% {
      box-shadow: 0 0 0 2px rgba(253, 73, 73, 0);
    }
    25% {
      box-shadow: 0 0 0 2px rgba(253, 73, 73, 0.4);
    }
    50% {
      box-shadow: 0 0 0 2px rgba(253, 73, 73, 0.8);
    }
    75% {
      box-shadow: 0 0 0 2px rgba(253, 73, 73, 0.4);
    }
    100% {
      box-shadow: 0 0 0 2px rgba(253, 73, 73, 0);
    }
  }
}

.filter-dot {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 9px;
  width: 9px;
  z-index: 10;
  background-color: var(--text-color);
  // box-shadow: 0 0 5px 2px var(--text-color);
  // border-radius: 50%;
  // padding: 2px;
  // margin-right: 5px;
}

.todo-status-icon {
  height: 14px;
  width: 14px;
  border: none !important;
  margin-top: -2px;
}

.text-truncate {
  max-width: 180px;
}

.alarm-clock {
  animation: shake 1s ease-in-out infinite; /* 持續時間 */
}

@keyframes shake {
  0%, 100% { transform: translate(0, 0) rotate(0); }
  10% { transform: translate(-1px, 0) rotate(-1deg); }
  10% { transform: translate(1px, 0) rotate(1deg); }
  10% { transform: translate(-1px, 0) rotate(-1deg); }
  40% { transform: translate(1px, 0) rotate(1deg); }
  50% { transform: translate(-1px, 0) rotate(-1deg); }
  60% { transform: translate(1px, 0) rotate(1deg); }
  70% { transform: translate(-1px, 0) rotate(-1deg); }
  80% { transform: translate(1px, 0) rotate(1deg); }
  90% { transform: translate(-1px, 0) rotate(-1deg); }
}

.chat-contact-mask {
  // background-color: rgba(108, 196, 255, 0.1);
  position: absolute;
  z-index: 20;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

@media (max-width: 400px) {
  .text-truncate {
    max-width: 100px;
  }
}
</style>
