<template>
  <div
    class="chat-profile-sidebar"
    :class="{'show': shallOpenTodoAddSidebarState}"
  >
    <!-- Header -->
    <header class="d-flex align-items-center justify-content-between mx-1 my-2">
      <div>
        <h4 class="chat-user-name mb-0">
          新增待辦
        </h4>
      </div>

      <div
        class="cursor-pointer table-header-close"
        style="height: 15px; width: 20px;"
        @click="$emit('close-sidebar')"
      >
        <div class="line" />
        <div class="line" />
      </div>
    </header>

    <hr class="mb-0 dividing-line">

    <div class="d-flex align-items-center justify-content-between flex-column scroll-area w-100">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
        class="w-100"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <app-collapse
            accordion
            class="mb-1 box-shadow-none"
          >
            <app-collapse-item
              title="基本設定"
              is-visible
              class="box-shadow-none"
            >
              <!-- 自訂義模板 -->
              <b-form-group label-for="config">
                <!-- <label class="mb-0">自訂義模板</label> -->

                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">自訂義模板</label>

                    <span>
                      <div
                        v-b-tooltip.hover.v-secondary
                        title="部門設定"
                        class="d-flex align-items-center actions-link-btn-sm"
                        @click="$emit('open-todo-quick-list-data')"
                      >
                        <b-img
                          src="/admin/images/table/settings.svg"
                          class="actions-link-btn-image"
                          rounded
                        />
                      </div>
                    </span>
                  </div>
                </template>

                <v-select
                  v-model="configId"
                  :options="$store.state['admin-todo'].quickOptions"
                  label="name"
                  placeholder="請選擇自訂義模板"
                  @input="setTodoConfigData"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <!-- 待辦標題 -->
              <validation-provider name="待辦標題">
                <b-form-group label-for="title">
                  <template #label>
                    <label class="mb-0 pb-0">待辦標題</label>
                  </template>
                  <b-form-input
                    id="title"
                    ref="titleInput"
                    v-model="todoData.title"
                    trim
                    placeholder="請輸入待辦標題"
                  />
                </b-form-group>
              </validation-provider>

              <!-- 委託客戶 -->
              <validation-provider name="委託客戶">
                <b-form-group label-for="client_name_add">
                  <template #label>
                    <div class="d-flex align-items-center">
                      <label class="mb-0">委託客戶(手動)</label>

                      <span>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="可辨識名稱即可"
                          class="d-flex align-items-center"
                          style="padding: 0 5px;"
                        >
                          <b-img
                            src="/admin/images/common/info.svg"
                            class="actions-link-btn-image cursor-pointer"
                            rounded
                            style=" width: 14px; height: 14px;"
                          />
                        </div>
                      </span>
                    </div>
                  </template>

                  <b-form-input
                    id="client_name"
                    v-model="todoData.client_name"
                    trim
                    placeholder="請填寫委託客戶"
                    class="mb-1"
                  />

                </b-form-group>
              </validation-provider>

              <!-- 負責品牌 -->
              <b-form-group label-for="admin_brand_id">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">負責品牌</label>

                    <span>
                      <div
                        v-b-tooltip.hover.v-secondary
                        title="公司品牌設定"
                        class="d-flex align-items-center actions-link-btn-sm"
                        @click="() => { $refs.adminBrandExplorerAddModal.getData(null) }"
                      >
                        <b-img
                          src="/admin/images/table/settings.svg"
                          class="actions-link-btn-image"
                          rounded
                        />
                      </div>
                    </span>
                  </div>
                </template>

                <v-select
                  v-model="todoData.admin_brand_id"
                  :options="adminBrandOptions"
                  :clearable="true"
                  label="name"
                  :reduce="option => option.id"
                  placeholder="請選擇品牌"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <!-- 委託客戶 -->
              <b-form-group label-for="customer_id">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">委託客戶(資料庫)</label>

                    <span>
                      <div
                        v-b-tooltip.hover.v-secondary
                        title="新增客戶"
                        class="d-flex align-items-center actions-link-btn-sm"
                        @click="submitCustomerUpdateModal(null)"
                      >
                        <!-- @click="() => { $refs.adminBrandExplorerAddModal.getData(null) }" -->
                        <b-img
                          src="/admin/images/table/plus.svg"
                          class="actions-link-btn-image"
                          rounded
                        />
                      </div>
                    </span>
                  </div>
                </template>

                <v-select
                  v-model="todoData.customer"
                  :options="filteredCustomerList"
                  label="name"
                  placeholder="請選擇委託客戶"
                  :filterable="false"
                  @search="fetchCustomerOptions"
                  @input="() => todoData.customer_id = todoData.customer ? todoData.customer.id : null"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                      <span
                        class="text-primary cursor-pointer"
                        @click="submitCustomerUpdateModal({name: search})"
                      >
                        立即新增
                      </span>
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>

                  <template v-slot:option="option">
                    <div class="d-flex align-items-center">
                      <div>
                        <b-avatar
                          variant="secondary"
                          :src="option.image"
                        />
                      </div>

                      <div class="ml-1">
                        <div class="font-weight-bold d-block text-nowrap mb-0 text-truncate">
                          {{ option.name }}{{ option.admin_brand_id ? `(${option.admin_brand.name})` : '' }}
                        </div>
                        <small class="text-muted">{{ option.email }}</small>
                      </div>
                    </div>
                  </template>

                  <template #selected-option="option">
                    <div class="text-nowrap mb-0 text-truncate">
                      {{ option.name }}{{ option.admin_brand_id ? `(${option.admin_brand.name})` : '' }}
                    </div>
                  </template>
                </v-select>

                <div
                  v-if="todoData.customer"
                  class="p-1 border mt-50"
                >
                  <div
                    class="d-flex justify-content-between align-items-center auth-users"
                  >
                    <div class="d-flex justify-content-start align-items-center">
                      <div class="mr-1">
                        <b-avatar
                          size="40"
                          variant="light-primary"
                          :src="todoData.customer.image"
                        />
                      </div>

                      <div class="profile-user-info">
                        <h6 class="mb-0">
                          <div class="text-truncate">
                            {{ todoData.customer.name }}{{ todoData.customer.admin_brand_id ? `(${todoData.customer.admin_brand.name})` : '' }}
                          </div>
                        </h6>
                        <small
                          class="text-muted text-truncate"
                          style="word-break: break-all"
                        >
                          {{ todoData.customer.email }}
                        </small>
                      </div>
                    </div>

                    <div class="text-nowrap">
                      <b-button
                        v-b-tooltip.hover.focus.v-secondary
                        title="編輯"
                        variant="flat-secondary"
                        size="sm"
                        class="text-nowrap p-50 btn-icon"
                        @click="submitCustomerUpdateModal(todoData.customer)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>

                      <b-button
                        v-b-tooltip.hover.focus.v-secondary
                        title="詳情"
                        variant="flat-secondary"
                        size="sm"
                        class="text-nowrap p-50 btn-icon"
                        @click="submitCustomerLinkUser(todoData.customer)"
                      >
                        <feather-icon icon="ExternalLinkIcon" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-form-group>

              <!-- 群組 -->
              <b-form-group label-for="group">
                <template #label>
                  <label class="mb-0">
                    群組
                  </label>
                </template>

                <v-select
                  v-model="todoData.group_id"
                  :options="groupOptions"
                  :clearable="false"
                  label="name"
                  :reduce="option => option.id"
                  placeholder="請選擇群組"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <!-- 優先程度 -->
              <b-form-group label-for="priority">
                <template #label>
                  <label class="mb-0">
                    優先程度
                  </label>
                </template>

                <v-select
                  v-model="todoData.priority"
                  :options="priorityOptions"
                  :clearable="false"
                  label="title"
                  :reduce="option => option.value"
                  placeholder="請選擇狀態"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>

                  <template v-slot:option="option">
                    <div class="d-flex align-items-center">
                      <span
                        class="bullet mr-1 border filter-dot"
                        :style="{'background-color': option.color}"
                      />
                      {{ option.title }} ( {{ option.sign }} )
                    </div>
                  </template>

                  <template #selected-option="option">
                    <div class="d-flex align-items-center">
                      <span
                        class="bullet mr-1 border filter-dot"
                        :style="{'background-color': option.color}"
                      />
                      {{ option.title }} ( {{ option.sign }} )
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </app-collapse-item>

            <app-collapse-item
              title="進階設定"
              class="box-shadow-none"
            >
              <!-- 顯示狀態 -->
              <b-form-group label-for="display_status">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                      <label class="mb-0">顯示狀態</label>

                      <span>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="設定為關閉，會在排定時間自動開啟"
                          class="d-flex align-items-center"
                          style="padding: 0 5px;"
                        >
                          <b-img
                            src="/admin/images/common/info.svg"
                            class="actions-link-btn-image cursor-pointer"
                            rounded
                            style=" width: 14px; height: 14px;"
                          />
                        </div>
                      </span>
                    </div>

                    <toggle-button
                      v-model="todoData.display_status"
                      :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                      :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                      :width="60"
                      :height="24"
                      class="m-25 mb-50"
                      :labels="{checked: '開啟', unchecked: '關閉'}"
                      :sync="true"
                    />
                  </div>
                </template>
              </b-form-group>

              <!-- 狀態 -->
              <b-form-group label-for="status">
                <template #label>
                  <label class="mb-0">
                    狀態
                  </label>
                </template>

                <v-select
                  v-model="todoData.status"
                  :options="todoStatusOptions"
                  :clearable="false"
                  label="title"
                  :reduce="option => option.value"
                  placeholder="請選擇狀態"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>

                  <template v-slot:option="option">
                    <div class="d-flex align-items-center">
                      <span
                        class="bullet mr-1 border filter-dot"
                        :style="{'background-color': option.color}"
                      />
                      {{ option.title }} ( {{ option.value }} )
                    </div>
                  </template>

                  <template #selected-option="option">
                    <div class="d-flex align-items-center">
                      <span
                        class="bullet mr-1 border filter-dot"
                        :style="{'background-color': option.color}"
                      />
                      {{ option.title }} ( {{ option.value }} )
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <!-- 截止時間 -->
              <b-form-group label-for="deadline_at">
                <template #label>
                  <label class="mb-0">截止時間</label>
                </template>

                <flat-pickr
                  v-model="todoData.deadline_at"
                  class="date-form-control form-control"
                  :config="dateConfig"
                  placeholder="YYYY-MM-DD"
                />

                <div class="mt-25">
                  <b-badge
                    v-for="btn in timeOptions"
                    :key="btn.value"
                    variant="light-primary"
                    class="mr-50 mt-25 cursor-pointer badge-btn"
                    @click="choiceDeadlineTime(btn.value)"
                  >
                    {{ btn.label }}
                  </b-badge>
                </div>
              </b-form-group>

              <!-- 排定時間 -->
              <!-- v-if="!todoData.display_status" -->
              <b-form-group label-for="scheduled_at">
                <template #label>
                  <div class="d-flex align-items-center">
                    <label class="mb-0">排定時間</label>

                    <span>
                      <div
                        v-b-tooltip.hover.v-secondary
                        title="指定時間此待辦才會顯示出來"
                        class="d-flex align-items-center"
                        style="padding: 0 5px;"
                      >
                        <b-img
                          src="/admin/images/common/info.svg"
                          class="actions-link-btn-image cursor-pointer"
                          rounded
                          style=" width: 14px; height: 14px;"
                        />
                      </div>
                    </span>
                  </div>
                </template>

                <flat-pickr
                  v-model="todoData.scheduled_at"
                  class="date-form-control form-control"
                  :config="{
                    ...dateConfig,
                    minDate: new Date(),
                  }"
                  placeholder="YYYY-MM-DD"
                />

                <div class="mt-25">
                  <b-badge
                    v-for="btn in timeOptions"
                    :key="btn.value"
                    variant="light-primary"
                    class="mr-50 mt-25 cursor-pointer badge-btn"
                    @click="choiceScheduledTime(btn.value)"
                  >
                    {{ btn.label }}
                  </b-badge>
                </div>
              </b-form-group>

              <!-- 負責部門 -->
              <b-form-group label-for="department_id">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">負責部門</label>

                    <span>
                      <div
                        v-b-tooltip.hover.v-secondary
                        title="部門設定"
                        class="d-flex align-items-center actions-link-btn-sm"
                        @click="() => { $refs.departmentExplorerAddModal.getData(null) }"
                      >
                        <b-img
                          src="/admin/images/table/settings.svg"
                          class="actions-link-btn-image"
                          rounded
                        />
                      </div>
                    </span>
                  </div>
                </template>

                <v-select
                  v-model="todoData.department_id"
                  :options="departmentsOptions"
                  :clearable="false"
                  label="name"
                  :reduce="option => option.id"
                  placeholder="請選擇部門"
                  @input="() => {
                    filterTodoTypeOptions(todoData.department_id)
                    filterTodoExecutionTypeOptions(todoData.department_id)
                  }"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <div>
                <!--  v-if="todoData.department_id" -->
                <!-- 作業分類 -->
                <b-form-group label-for="type_id">
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        作業分類
                      </label>

                      <span>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="作業分類設定"
                          class="d-flex align-items-center actions-link-btn-sm"
                          @click="submitTodoTypeExplorerModal"
                        >
                          <b-img
                            src="/admin/images/table/settings.svg"
                            class="actions-link-btn-image"
                            rounded
                          />
                        </div>
                      </span>
                    </div>
                  </template>

                  <v-select
                    v-model="todoData.type_id"
                    :options="typeOptions"
                    label="name"
                    :reduce="option => option.id"
                    placeholder="請選擇作業分類"
                    :disabled="!todoData.department_id"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <!-- 項目設定 -->
                <b-form-group label-for="execution_type_id">
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">項目設定</label>

                      <span>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="項目設定設定"
                          class="d-flex align-items-center actions-link-btn-sm"
                          @click="submitTodoExecutionTypeExplorerModal"
                        >
                          <b-img
                            src="/admin/images/table/settings.svg"
                            class="actions-link-btn-image"
                            rounded
                          />
                        </div>
                      </span>
                    </div>
                  </template>

                  <v-select
                    v-model="todoData.execution_type_id"
                    :options="executionTypeOptions"
                    label="name"
                    :reduce="option => option.id"
                    placeholder="請選擇項目設定"
                    :disabled="!todoData.department_id"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </div>
            </app-collapse-item>

            <app-collapse-item
              title="執行人員"
              class="box-shadow-none"
            >
              <div
                v-for="role in roleOptions"
                :key="role.value"
                class="mb-1"
              >
                <div>
                  <div class="d-flex justify-content-between align-items-center mb-75">
                    <h5 class="mb-0">
                      {{ role.title }} ({{ role.value }})
                    </h5>

                    <span>
                      <b-img
                        v-b-tooltip.hover.bottom.focus
                        :title="role.description"
                        src="/admin/images/common/info.svg"
                        class="cursor-pointer"
                        style="height: 17px;"
                        rounded
                      />

                      <b-img
                        v-b-tooltip.hover.bottom.focus
                        :title="`新增${role.title}`"
                        src="/admin/images/table/plus.svg"
                        class="image-icon cursor-pointer ml-50"
                        rounded
                        @click="submitEditAssignedUser(groupedUsers[role.value] ? groupedUsers[role.value] : [], role.value)"
                      />
                    </span>
                  </div>

                  <div
                    v-if="groupedUsers[role.value]"
                    class="ml-1"
                  >
                    <div
                      v-for="(user, index) in groupedUsers[role.value]"
                      :key="`auth-${index}`"
                      class="mb-2"
                    >
                      <div class="d-flex justify-content-between align-items-center auth-users">
                        <div
                          class="d-flex justify-content-start align-items-center"
                          @click="submitDescriptionAssignedUser(user, role.value, index)"
                        >
                          <div class="mr-1">
                            <b-avatar
                              size="40"
                              variant="light-primary"
                            />
                          </div>

                          <div class="profile-user-info cursor-pointer">
                            <h6
                              class="mb-0"
                            >
                              <span class="text-primary">{{ user.user_info.name }}</span>
                              <small
                                v-if="user.department_info.id"
                                style="word-break: break-all"
                              >
                                ( {{ user.department_info.name }} )
                              </small>
                            </h6>

                            <small
                              style="word-break: break-all"
                              class="text-muted"
                            >{{ user.description }}</small>
                          </div>
                        </div>

                        <div>
                          <feather-icon
                            v-b-tooltip.hover.bottom.focus
                            title="移除"
                            icon="XIcon"
                            size="20"
                            class="cursor-pointer text-muted"
                            @click="submitRemoveAssignedUser(user, role.value, index)"
                          />
                        </div>
                      </div>
                    </div>

                  </div>

                  <div
                    v-if="!groupedUsers[role.value] || groupedUsers[role.value].length === 0"
                    class="cursor-pointer mb-2 ml-1"
                  >
                    <div
                      class="d-flex justify-content-start align-items-center"
                      @click="submitEditAssignedUser(groupedUsers[role.value] ? groupedUsers[role.value] : [], role.value)"
                    >
                      <div class="mr-1">
                        <b-avatar
                          size="40"
                          variant="light-primary"
                        >
                          <feather-icon
                            size="21"
                            icon="PlusIcon"
                          />
                        </b-avatar>
                      </div>

                      <div class="profile-user-info">
                        新增{{ role.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </app-collapse-item>
          </app-collapse>
        </b-form>
      </validation-observer>
    </div>

    <hr class="mt-0 dividing-line">

    <div class="text-right px-1">
      <b-button
        variant="outline-secondary"
        :disabled="isBusy"
        class="mr-1"
        @click="() => {
          resetTodoTypeData()
          resetMetaList()
        }"
      >
        <span v-if="!isBusy">重設</span>

        <b-spinner
          v-else
          small
        />
      </b-button>

      <b-button
        variant="primary"
        :disabled="isBusy"
        @click="handleOk"
      >
        <span v-if="!isBusy">新增</span>

        <b-spinner
          v-else
          small
        />
      </b-button>
    </div>

    <department-explorer-modal
      ref="departmentExplorerAddModal"
      department-explorer-id="departmentExplorerAddModal"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

    <admin-brand-explorer-modal
      ref="adminBrandExplorerAddModal"
      brand-explorer-id="adminBrandExplorerAddModal"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

    <todo-type-explorer-modal
      ref="todoTypeExplorerAddModal"
      todo-type-explorer-id="todoTypeExplorerAddModal"
      :table-filter="{ department_id: todoData.department_id}"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

    <todo-execution-type-explorer-modal
      ref="todoExecutionTypeExplorerAddModal"
      todo-execution-type-explorer-id="todoExecutionTypeExplorerAddModal"
      :table-filter="{ department_id: todoData.department_id}"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

    <users-explorer-modal
      ref="usersCreateExplorerUpdateModal"
      :choices-users="todoData.assigned_user"
      :is-edit-auth="false"
      user-explorer-id="usersCreateExplorerUpdateModal"
      :is-hiden-emit="false"
      :use-callback-data="true"
      @call-back-data="callbackUsersExplorer"
    />

    <user-customer-explorer-modal
      ref="userCustomerExplorerCreateModal"
      user-explorer-id="userCustomerExplorerCreateModal"
      :admin-brand-options="adminBrandOptions"
      @call-back-data="callbackCustomerUpdate"
      @refresh-customer-list="resetCustomerListData"
    />
    <!-- :is-edit-auth="false"
    :is-hiden-emit="false"
    :use-callback-data="true"
    @call-back-data="callbackUsersExplorer" -->

    <b-modal
      id="todoCreateAssignedUserUpdate"
      no-close-on-backdrop
      centered
    >
      <template #modal-title>
        <h4 class="m-0">
          協助說明 - {{ assignedUserData.user_info.name }}
        </h4>
      </template>

      <div v-if="assignedUserData">
        <div>
          <b-form-textarea
            v-model="assignedUserData.description"
            placeholder="請輸入協助說明"
            :state="assignedUserData.description.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="assignedUserData.description.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ assignedUserData.description.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            @click="confirmEditAssignedUser"
          >
            確認
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import {
  BForm, BFormGroup, BFormInput, VBToggle, VBTooltip, BImg, BButton, BSpinner, BBadge, BAvatar, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ToggleButton } from 'vue-js-toggle-button'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// Component
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import DepartmentExplorerModal from '@/layouts/components/Modal/department-modal/departmentModal.vue'
import AdminBrandExplorerModal from '@/layouts/components/Modal/admin-brand-modal/brandModal.vue'
import TodoTypeExplorerModal from '@/layouts/components/Modal/todo-type-modal/todoTypeModal.vue'
import TodoExecutionTypeExplorerModal from '@/layouts/components/Modal/todo-execution-type-modal/todoExecutionTypeModal.vue'
import userCustomerExplorerModal from './UserCustomerModal.vue'
import usersExplorerModal from './UsersModal.vue'
import {
  useTodoList, useTodoSetting, useTodoView, useQuickList,
} from '../useTodo'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import store from '@/store'

export default {
  components: {
    BForm,
    BBadge,
    BImg,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
    BAvatar,
    BFormTextarea,

    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    ToggleButton,
    AppCollapse,
    AppCollapseItem,
    DepartmentExplorerModal,
    AdminBrandExplorerModal,
    TodoTypeExplorerModal,
    TodoExecutionTypeExplorerModal,
    usersExplorerModal,
    userCustomerExplorerModal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    shallOpenTodoAddSidebarState: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      missingFields: [],
      showMoreSetting: false,
      groupedUsers: {},
      assignedUserData: null,
      // assignedUsers: [], => todoData.assigned_user
      assignedUser: {
        type: null,
        index: null,
      },
      configId: null,
      maxChar: 255,
    }
  },
  computed: {
    filteredCustomerList() {
      const selectedBrandId = this.todoData.admin_brand_id

      // 使用 computed 属性来计算筛选后的客户列表
      return this.customerListOptions.filter(customer => customer.admin_brand_id === selectedBrandId)
    },
  },
  watch: {
    shallOpenTodoAddSidebarState(newValue) {
      if (newValue) this.getData()
    },
  },
  methods: {
    // (查詢)使用者
    fetchCustomerOptions(search, loading) {
      if (search.length) {
        loading(true)
        this.getCustomerListData({
          search,
          limit: 100,
          page: 1,
          _: Date.now(),
        })
          .then(response => {
            const { data } = response.data.data
            this.customerListOptions = data.map(item => this.syncObjectDeep(this.blankCustomerData, item))
            loading(false)
          })
      } else {
        this.getCustomerListData({
          limit: 100,
          page: 1,
          _: Date.now(),
        })
          .then(response => {
            const { data } = response.data.data
            this.customerListOptions = data.map(item => this.syncObjectDeep(this.blankCustomerData, item))
          })
      }
    },

    // (觸發)新增客戶彈窗
    submitCustomerUpdateModal(customer) {
      setTimeout(() => { this.$refs.userCustomerExplorerCreateModal.getData(customer) }, 200)
    },

    // (觸發)跳轉
    submitCustomerLinkUser(customer) {
      if (!customer.id) return
      const routeUrl = `${window.location.origin}/admin/customer/${customer.id}`
      window.open(routeUrl, '_blank')
    },

    // (回傳)
    callbackCustomerUpdate(customer) {
      this.todoData.customer = this.syncObject(this.blankCustomerData, customer)
    },
    // --------------------------------------------------------------------------------------------

    // (計算)時間
    calculateDueDate(timeString) {
      const timeRegex = /^(\d+)([hd])$/
      const match = timeString.match(timeRegex)

      if (!match) return new Date()

      const value = parseInt(match[1], 10)
      const unit = match[2]

      const timeInterval = unit === 'h' ? value * 60 * 60 * 1000 : value * 24 * 60 * 60 * 1000

      const currentDate = new Date()
      const dueDate = new Date(currentDate.getTime() + timeInterval)

      return dueDate
    },

    // (選擇)截止時間
    choiceDeadlineTime(value) {
      const dueDate = this.formatDate(this.calculateDueDate(value))
      this.todoData.deadline_at = dueDate
    },

    // (選擇)排定時間
    choiceScheduledTime(value) {
      const dueDate = this.formatDate(this.calculateDueDate(value))
      this.todoData.scheduled_at = dueDate
    },

    // (觸發)作業分類彈窗
    submitTodoTypeExplorerModal() {
      if (!this.todoData.department_id) {
        this.useSwalAlertCenter(false, '尚未選擇部門', '請先選擇待辦部門')
        return
      }
      this.$refs.todoTypeExplorerAddModal.getData(null)
    },

    // (觸發)項目設定彈窗
    submitTodoExecutionTypeExplorerModal() {
      if (!this.todoData.department_id) {
        this.useSwalAlertCenter(false, '尚未選擇部門', '請先選擇待辦部門')
        return
      }
      this.$refs.todoExecutionTypeExplorerAddModal.getData(null)
    },

    // (過濾)作業分類
    filterTodoTypeOptions(departmentId) {
      const findTypeGroup = this.typeGroupOptions.find(el => el.department_id === departmentId)
      if (findTypeGroup === undefined) {
        this.todoData.type_id = null
        this.typeOptions = []
        return
      }

      this.typeOptions = findTypeGroup.data

      const findType = this.typeOptions.find(el => el.id === this.todoData.type_id)

      if (findType === undefined) {
        this.todoData.type_id = null
      }
    },

    // (過濾)項目設定
    filterTodoExecutionTypeOptions(departmentId) {
      const findexEcutionTypeGroup = this.executionTypeGroupOptions.find(el => el.department_id === departmentId)
      if (findexEcutionTypeGroup === undefined) {
        this.todoData.execution_type_id = null
        this.executionTypeOptions = []
        return
      }

      this.executionTypeOptions = findexEcutionTypeGroup.data

      const findexEcutionType = this.executionTypeOptions.find(el => el.id === this.todoData.execution_type_id)

      if (findexEcutionType === undefined) {
        this.todoData.execution_type_id = null
      }
    },

    // 按下彈窗關閉
    handleHide() {
      // bvModalEvt.preventDefault()
      // this.resetTodoTypeData()
      // this.isBusy = false
      // this.$nextTick(() => {
      //   this.$bvModal.hide('update-modal')
      // })
    },

    // 按下彈窗確認
    handleOk() {
      this.isBusy = true
      this.setTodoCreate({
        ...this.todoData,
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.$emit('refetch-data')
          this.resetTodoTypeData()
          this.groupedUsers = {}
          this.assignedUserData = null
          this.assignedUser = {
            type: null,
            index: null,
          }
          this.configId = null
          this.$nextTick(() => {
            this.$emit('close-sidebar', response.data.data)
            const firstList = {
              id: response.data.data.id,
              total: 0,
            }
            store.commit('admin-todo/UPDATE_FIRST_LIST_INFO', firstList)
          })
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },
    // --------------------------------------------------------------------------------------------
    // (觸發)編輯執行人員
    submitEditAssignedUsers() {
      const groupedUserArray = this.todoData.assigned_user.reduce((grouped, user) => {
        const roleListItem = this.roleOptions.find(roleItem => roleItem.value === user.role)

        const groupedArr = grouped

        if (roleListItem) {
          const groupName = roleListItem.value

          if (!grouped[groupName]) {
            groupedArr[groupName] = []
          }

          groupedArr[groupName].push(user)
        }

        return groupedArr
      }, {})
      this.groupedUsers = groupedUserArray
      this.todoData.assigned_user = JSON.parse(JSON.stringify(this.todoData.assigned_user))
    },

    // (觸發)編輯單一執行人員
    submitEditAssignedUser(users, type) {
      this.assignedUser.type = type
      setTimeout(() => { this.$refs.usersCreateExplorerUpdateModal.getData(users) }, 200)
      // usersExplorerUpdateModal => usersCreateExplorerUpdateModal
    },

    // (觸發)編輯單一執行人員
    submitDescriptionAssignedUser(users, type, index) {
      if (this.isReview) return
      this.assignedUser.type = type
      this.assignedUser.index = index
      this.assignedUserData = JSON.parse(JSON.stringify(users))
      this.$bvModal.show('todoCreateAssignedUserUpdate')
    },

    // (觸發)移除單一執行人員
    submitRemoveAssignedUser(user, type, index) {
      this.useSwalAlertWarning('移除人員', `你確定要移除人員 ${user.user_info.name} 嗎?`)
        .then(result => {
          if (result.value) {
            if (this.groupedUsers[type] && this.groupedUsers[type].length > index) {
              this.groupedUsers[type].splice(index, 1)
            }
            this.todoData.assigned_user = this.todoData.assigned_user.filter(item => item.user_id !== user.user_id)
          }
        })
    },

    // (回調)選擇的使用者
    callbackUsersExplorer(users) {
      if (!Object.prototype.hasOwnProperty.call(this.groupedUsers, this.assignedUser.type)) {
        this.$set(this.groupedUsers, this.assignedUser.type, this.groupedUsers[this.assignedUser.type] || [])
      }

      users.forEach(user => {
        const resolveUser = {
          ...this.syncObject(this.blankAssignedData, { ...user, role: this.assignedUser.type }),
          user_info: this.syncObject(this.blankSimpleInfo, user.user_info),
          department_info: this.syncObject(this.blankSimpleInfo, user.department_info),
        }
        this.groupedUsers[this.assignedUser.type].push(resolveUser)
        this.todoData.assigned_user.push(resolveUser)
      })
    },

    // (編輯)單一執行人員
    confirmEditAssignedUser() {
      if (this.assignedUserData.description && this.assignedUserData.description.length > this.maxChar) {
        this.useAlertToast(false, '字數超過上限')
        return
      }

      this.groupedUsers[this.assignedUser.type].splice(this.assignedUser.index, 1, this.assignedUserData)

      const resolveUsers = this.todoData.assigned_user.map(item => {
        if (item.user_id === this.assignedUserData.user_id) {
          const resolve = {
            ...item, description: this.assignedUserData.description,
          }
          return resolve
        }
        return item
      })
      this.todoData.assigned_user = resolveUsers
      this.$bvModal.hide('todoCreateAssignedUserUpdate')
    },
    // --------------------------------------------------------------------------------------------
    // (設定)單一待辦
    setTodoConfigData(config) {
      const resolveData = this.syncObject(this.blankConfigData, !config ? {} : config)
      resolveData.content = this.syncObject({ data: {}, setting: {} }, resolveData.content)
      resolveData.content.data = this.syncObject(this.blankContentData, resolveData.content.data)
      resolveData.content.setting = this.syncObject(this.blankContentSetting, resolveData.content.setting)

      this.configData = { ...resolveData }
      const [groupOption] = this.groupOptions

      this.configId = !resolveData.id ? null : resolveData
      if (this.configId === null) {
        this.resetTodoTypeData()
        return
      }

      const resolveTodoData = this.syncObject(this.blankTodoData, config.content.data)
      const contentSetting = resolveData.content.setting
      if (!resolveTodoData.group_id) resolveTodoData.group_id = groupOption.id

      if (this.configData.id) {
        if (!contentSetting.show_deadline_at) {
          const dueDate = this.formatDate(this.calculateDueDate(contentSetting.set_deadline_at))
          resolveTodoData.deadline_at = dueDate
        }

        if (!contentSetting.show_scheduled_at) {
          const dueDate = this.formatDate(this.calculateDueDate(contentSetting.set_scheduled_at))
          resolveTodoData.scheduled_at = dueDate
        }
      }
      this.todoData = { ...this.todoData, ...resolveTodoData }

      // 判斷是否有在陣列中
      const findDepartment = this.departmentsOptions.find(el => el.id === this.todoData.department_id)

      if (findDepartment === undefined) {
        this.todoData.department_id = null
      } else {
        this.filterTodoTypeOptions(this.todoData.department_id)
        this.filterTodoExecutionTypeOptions(this.todoData.department_id)
      }
    },

    // (重新)獲取Meta
    resetMetaList() {
      this.getMetaListData()
        .then(response => {
          // 陣列更新中
          const { departments, priority } = response.data.data
          this.departmentsOptions = departments
          this.priorityOptions = priority

          const adminBrands = response.data.data.admin_brands
          this.adminBrandOptions = adminBrands

          const todoTypes = response.data.data.todo_types
          this.typeGroupOptions = todoTypes

          const executionTypes = response.data.data.execution_types
          this.executionTypeGroupOptions = executionTypes

          const todoStatus = response.data.data.todo_status
          this.todoStatusOptions = todoStatus

          const todoRole = response.data.data.todo_role
          this.roleOptions = todoRole
          this.submitEditAssignedUsers()

          // 判斷是否有在陣列中
          const findDepartment = departments.find(el => el.id === this.todoData.department_id)

          if (findDepartment === undefined) {
            this.todoData.department_id = null
          } else {
            this.filterTodoTypeOptions(this.todoData.department_id)
            this.filterTodoExecutionTypeOptions(this.todoData.department_id)
          }

          const findAdminBrand = adminBrands.find(el => el.id === this.todoData.admin_brand_id)

          if (findAdminBrand === undefined) {
            this.todoData.admin_brand_id = null
          }
        })

      this.resetCustomerListData()
    },

    // (重新)獲取客戶列表
    resetCustomerListData() {
      this.getCustomerListData({
        limit: 100,
        page: 1,
        _: Date.now(),
      })
        .then(response => {
          const { data } = response.data.data
          this.customerListOptions = data.map(item => this.syncObjectDeep(this.blankCustomerData, item))
        })
    },

    // (獲取)資料
    getData() {
      this.getMetaListData()
        .then(response => {
          // 陣列更新中
          const { departments, priority, groups } = response.data.data
          this.departmentsOptions = departments
          this.priorityOptions = priority
          this.groupOptions = groups
          if (groups.length > 0) this.todoData.group_id = groups[0].id

          const adminBrands = response.data.data.admin_brands
          this.adminBrandOptions = adminBrands

          const todoTypes = response.data.data.todo_types
          this.typeGroupOptions = todoTypes

          const todoExecutionTypes = response.data.data.execution_types
          this.executionTypeGroupOptions = todoExecutionTypes

          const todoStatus = response.data.data.todo_status
          this.todoStatusOptions = todoStatus

          const todoRole = response.data.data.todo_role
          this.roleOptions = todoRole
          this.submitEditAssignedUsers()

          // 資料同步
          // const resolveData = {
          //   ...this.syncObject(this.blankTodoData, {}),
          // }

          // 判斷是否有在陣列中
          // const findDepartment = departments.find(el => el.id === this.todoDataItem.department_id)

          // if (findDepartment === undefined) {
          //   resolveData.department_id = null
          // } else {
          //   this.filterTodoTypeOptions(resolveData.department_id)
          //   this.filterTodoExecutionTypeOptions(resolveData.department_id)
          // }

          // this.todoData = resolveData
          // this.$bvModal.show('update-modal')
          this.$refs.titleInput.focus()
        })
        .catch(() => {
          const resolveData = {
            ...this.syncObject(this.blankTodoData, {}),
          }
          this.todoData = resolveData
          this.$refs.titleInput.focus()
          // this.$bvModal.show('update-modal')
        })

      this.resetCustomerListData()
    },
  },
  setup() {
    localize('tw')

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      blankContentSetting,
      blankContentData,
      blankConfigData,
    } = useQuickList()

    const {
      isBusy,
      setTodoCreate,
      groupOptions,
    } = useTodoList()

    const {
      blankAssignedData,
    } = useTodoView()

    const {
      blankSimpleInfo,
      blankCustomerData,
      roleLists,
      roleOptions,
      getMetaListData,
      departmentsOptions,
      adminBrandOptions,
      executionTypeOptions,
      typeGroupOptions,
      executionTypeGroupOptions,
      priorityOptions,
      todoStatusOptions,
      typeOptions,
      syncObject,
      syncObjectDeep,

      customerListOptions,
      getCustomerListData,
    } = useTodoSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const formatDate = date => {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')

      return `${year}-${month}-${day} ${hours}:${minutes}`
    }

    const blankTodoData = {
      group_id: null,
      admin_brand_id: null,
      department_id: null,
      customer_id: null,
      customer: null,
      title: null,
      type_id: null,
      execution_type_id: null,
      status: 'not-completed',
      assigned_user: [],
      priority: 50,
      client_name: null,
      display_status: true,
      scheduled_at: null, // formatDate(new Date()),
      deadline_at: formatDate(new Date(new Date().getTime() + (60 * 60 * 1000))),
      // new Date(),
    }

    const timeOptions = [
      { label: '1H', value: '1h' },
      { label: '2H', value: '2h' },
      { label: '3H', value: '3h' },
      { label: '5H', value: '5h' },
      { label: '一天', value: '1d' },
      { label: '一周', value: '7d' },
    ]

    const todoData = ref(JSON.parse(JSON.stringify(blankTodoData)))

    const resetTodoTypeData = () => {
      todoData.value = JSON.parse(JSON.stringify(blankTodoData))
      if (groupOptions.value.length > 0) todoData.value.group_id = groupOptions.value[0].id
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetTodoTypeData)

    const dateConfig = {
      locale: language.zh_tw,
      dateFormat: 'Y-m-d H:i',
      allowInput: true,
      static: true,
      enableTime: true,
      // inline: true,
    }

    return {
      perfectScrollbarSettings,

      isBusy,
      dateConfig,

      blankContentSetting,
      blankContentData,
      blankConfigData,
      blankCustomerData,

      todoData,
      blankSimpleInfo,
      blankAssignedData,
      roleLists,
      roleOptions,
      departmentsOptions,
      adminBrandOptions,
      executionTypeOptions,
      typeOptions,
      typeGroupOptions,
      executionTypeGroupOptions,
      priorityOptions,
      todoStatusOptions,
      groupOptions,
      blankTodoData,
      setTodoCreate,
      getMetaListData,

      resetTodoTypeData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
      syncObjectDeep,
      timeOptions,
      formatDate,

      customerListOptions,
      getCustomerListData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
// .profile-sidebar-area {
//   height: 90% !important;
// }
.show-more-arrow {
  transition: transform 0.3s ease-in-out;
  .active {
    transition: transform 0.3s ease-in-out;
    transform: rotate(90deg);
  }
  // .show {
  //   transform: translateX(100%);
  // }
  // span {
  //   display: inline-block;
  //   transition: transform 1s ease-in-out;
  // }
}
.badge-btn:hover {
  background-color: rgba(117, 174, 227, 0.363);
}

.scroll-area {
  height: calc(100% - 130px);
  overflow-y: scroll;
}

.dividing-line {
  box-shadow: 0 0 10px rgba(141, 141, 141, 0.5);
}

.image-icon {
  height: 16px;
}

.text-truncate {
  max-width: 220px;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
}

</style>
