<template>
  <div>
    <div ref="uploadCardContent">
      <div
        ref="uploadCard"
        no-body
        class="image-explorer-contain upload-card"
        @dragenter="dragEnter"
        @dragover="preventDefault"
        @dragleave="dragLeave"
        @drop="dropFile"
      >
        <div
          ref="dropArea"
          class="dropArea hidden"
        >
          <div class="upload-label">
            <span class="label-title">
              把檔案拖放到這裡
            </span>
            <b-img
              :src="require('@/assets/images/icons/image-processing.png')"
              fluid
            />
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="quickBaseModal"
      no-close-on-backdrop
      centered
      header-bg-variant="info"
      body-class="p-0"
      :size="previewFile.length <= 0 ? 'md' : 'lg'"
      @hidden="handleHideSiteModal"
      @close="handleHideSiteModal"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          <div v-if="previewFile.length <= 1">
            快速新增
          </div>

          <div v-else>
            已選擇{{ previewFile.length }}個圖片
          </div>
        </h4>
      </template>

      <div v-if="configData">
        <b-row>
          <b-col
            cols="12"
            :lg="previewFile.length <= 0 ? 12 : 6"
          >
            <div
              class="site-modal px-1"
              :class="previewFile.length <= 0 ? 'py-0' : 'py-1'"
            >
              <div>
                <b-row v-if="previewFile.length >=2">
                  <b-col
                    v-for="(image, index) in previewFile"
                    :key="index"
                    cols="12"
                    lg="6"
                    class="mb-1"
                  >
                    <div class="site-modal-images">
                      <div class="site-modal-tool">
                        <feather-icon
                          icon="RefreshCcwIcon"
                          size="16"
                          class="site-modal-tool-icon mr-1"
                          @click="handleChangeSite(index)"
                        />

                        <feather-icon
                          icon="Trash2Icon"
                          size="16"
                          class="site-modal-tool-icon"
                          @click="submitDeleteAppend(index)"
                        />
                      </div>

                      <b-img
                        :src="image.src"
                        fluid
                        class="site-modal-image"
                      />
                    </div>
                  </b-col>
                </b-row>

                <b-row v-if="previewFile.length ===1">
                  <b-col
                    v-for="(image, index) in previewFile"
                    :key="index"
                    cols="12"
                    lg="12"
                    class="mb-1"
                  >
                    <div class="site-modal-images">
                      <div class="site-modal-tool">
                        <feather-icon
                          icon="RefreshCcwIcon"
                          size="16"
                          class="site-modal-tool-icon mr-1"
                        />

                        <feather-icon
                          icon="Trash2Icon"
                          size="16"
                          class="site-modal-tool-icon"
                          @click="submitDeleteAppend(index)"
                        />
                      </div>

                      <b-img
                        :src="image.src"
                        fluid
                        class="site-modal-image"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>

              <input
                ref="fileChange"
                type="file"
                hidden
                @input="changeFile($event)"
              >
            </div>
          </b-col>

          <b-col
            cols="12"
            :lg="previewFile.length <= 0 ? 12 : 6"
          >
            <app-collapse
              accordion
              class="mb-1 box-shadow-none"
            >
              <app-collapse-item
                title="快速設定"
                :is-visible="isSettingOpen('includes')"
                class="box-shadow-none"
              >
                <!-- 自訂義模板 -->
                <b-form-group label-for="config">
                  <label>自訂義模板</label>

                  <v-select
                    v-model="configId"
                    :options="this.$store.state['admin-todo'].quickOptions"
                    label="name"
                    placeholder="請選擇自訂義模板"
                    @input="setTodoConfigData"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <!-- 待辦標題 -->
                <b-form-group
                  v-if="configData.content.setting.show_title"
                  label-for="title"
                >
                  <template #label>
                    <label class="mb-0 pb-0">待辦標題</label>
                  </template>
                  <b-form-input
                    id="title"
                    ref="titleInput"
                    v-model="todoData.title"
                    trim
                    placeholder="請輸入待辦標題"
                  />
                </b-form-group>

                <!-- 委託客戶 -->
                <b-form-group
                  v-if="configData.content.setting.show_client_name"
                  label-for="client_name"
                >
                  <template #label>
                    <div class="d-flex align-items-center">
                      <label class="mb-0">委託客戶(手動)</label>

                      <span>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="可辨識名稱即可"
                          class="d-flex align-items-center"
                          style="padding: 0 5px;"
                        >
                          <b-img
                            src="/admin/images/common/info.svg"
                            class="actions-link-btn-image cursor-pointer"
                            rounded
                            style=" width: 14px; height: 14px;"
                          />
                        </div>
                      </span>
                    </div>
                  </template>
                  <b-form-input
                    id="client_name"
                    v-model="todoData.client_name"
                    trim
                    placeholder="請填寫委託客戶"
                  />
                </b-form-group>

                <!-- 委託客戶 -->
                <b-form-group
                  v-if="configData.content.setting.show_client_name"
                  label-for="customer_id"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">委託客戶(資料庫)</label>

                      <span>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="新增客戶"
                          class="d-flex align-items-center actions-link-btn-sm"
                          @click="submitCustomerUpdateModal(null)"
                        >
                          <b-img
                            src="/admin/images/table/plus.svg"
                            class="actions-link-btn-image"
                            rounded
                          />
                        </div>
                      </span>
                    </div>
                  </template>

                  <div>
                    <div class="w-100">
                      <v-select
                        v-model="todoData.customer"
                        :options="customerListOptions"
                        label="name"
                        placeholder="請選擇委託客戶"
                        :filterable="false"
                        @search="fetchCustomerOptions"
                        @input="() => todoData.customer_id = todoData.customer ? todoData.customer.id : null"
                      >
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            沒有<em>{{ search }}</em> 的相關結果
                            <span
                              class="text-primary cursor-pointer"
                              @click="submitCustomerUpdateModal({name: search})"
                            >
                              立即新增
                            </span>
                          </template>

                          <div v-else>
                            <small>暫無資料</small>
                          </div>
                        </template>

                        <template v-slot:option="option">
                          <div class="d-flex align-items-center">
                            <div>
                              <b-avatar
                                variant="secondary"
                                :src="option.image"
                              />
                            </div>

                            <div class="ml-1">
                              <div class="font-weight-bold d-block text-nowrap mb-0">
                                {{ option.name }}
                              </div>
                              <small class="text-muted">{{ option.email }}</small>
                            </div>
                          </div>
                        </template>

                        <template #selected-option="option">
                          <div class="text-nowrap mb-0">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>

                      <div
                        v-if="todoData.customer"
                        class="p-1 border mt-50"
                      >
                        <div
                          class="d-flex justify-content-between align-items-center auth-users"
                        >
                          <div class="d-flex justify-content-start align-items-center">
                            <div class="mr-1">
                              <b-avatar
                                size="40"
                                variant="light-primary"
                                :src="todoData.customer.image"
                              />
                            </div>

                            <div class="profile-user-info">
                              <h6 class="mb-0">
                                {{ todoData.customer.name }}
                              </h6>
                              <small
                                class="text-muted"
                                style="word-break: break-all"
                              >
                                {{ todoData.customer.email }}
                              </small>
                            </div>
                          </div>

                          <div>
                            <b-button
                              v-b-tooltip.hover.focus.v-secondary
                              title="編輯"
                              variant="flat-secondary"
                              size="sm"
                              class="text-nowrap p-50 btn-icon"
                              @click="submitCustomerUpdateModal(todoData.customer)"
                            >
                              <feather-icon icon="EditIcon" />
                            </b-button>

                            <b-button
                              v-b-tooltip.hover.focus.v-secondary
                              title="詳情"
                              variant="flat-secondary"
                              size="sm"
                              class="text-nowrap p-50 btn-icon"
                              @click="submitCustomerLinkUser(todoData.customer)"
                            >
                              <feather-icon icon="ExternalLinkIcon" />
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form-group>

                <!-- 群組 -->
                <b-form-group
                  v-if="configData.content.setting.show_group_id"
                  label-for="group"
                >
                  <template #label>
                    <label class="mb-0">
                      群組
                    </label>
                  </template>

                  <v-select
                    v-model="todoData.group_id"
                    :options="$store.state['admin-todo'].groupOptions"
                    :clearable="false"
                    :reduce="option => option.value"
                    placeholder="請選擇群組"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <!-- 優先程度 -->
                <b-form-group
                  v-if="configData.content.setting.show_priority"
                  label-for="priority"
                >
                  <template #label>
                    <label class="mb-0">
                      優先程度
                    </label>
                  </template>

                  <v-select
                    v-model="todoData.priority"
                    :options="$store.state['admin-todo'].priorityOptions"
                    :clearable="false"
                    label="title"
                    :reduce="option => option.value"
                    placeholder="請選擇狀態"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>

                    <template v-slot:option="option">
                      <div class="d-flex align-items-center">
                        <span
                          class="bullet mr-1 border filter-dot"
                          :style="{'background-color': option.color}"
                        />
                        {{ option.title }} ( {{ option.sign }} )
                      </div>
                    </template>

                    <template #selected-option="option">
                      <div class="d-flex align-items-center">
                        <span
                          class="bullet mr-1 border filter-dot"
                          :style="{'background-color': option.color}"
                        />
                        {{ option.title }} ( {{ option.sign }} )
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </app-collapse-item>

              <app-collapse-item
                v-if="!isSettingOpen('every')"
                title="基本設定"
                class="box-shadow-none"
              >
                <b-form-group
                  v-if="!configData.content.setting.show_title"
                  label-for="title"
                >
                  <template #label>
                    <label class="mb-0 pb-0">待辦標題</label>
                  </template>
                  <b-form-input
                    id="title"
                    ref="titleInput"
                    v-model="todoData.title"
                    trim
                    placeholder="請輸入待辦標題"
                  />
                </b-form-group>

                <!-- 委託客戶 -->
                <b-form-group
                  v-if="!configData.content.setting.show_client_name"
                  label-for="client_name"
                >
                  <template #label>
                    <div class="d-flex align-items-center">
                      <label class="mb-0">委託客戶(手動)</label>

                      <span>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="可辨識名稱即可"
                          class="d-flex align-items-center"
                          style="padding: 0 5px;"
                        >
                          <b-img
                            src="/admin/images/common/info.svg"
                            class="actions-link-btn-image cursor-pointer"
                            rounded
                            style=" width: 14px; height: 14px;"
                          />
                        </div>
                      </span>
                    </div>
                  </template>
                  <b-form-input
                    id="client_name"
                    v-model="todoData.client_name"
                    trim
                    placeholder="請填寫委託客戶"
                  />
                </b-form-group>

                <b-form-group
                  v-if="!configData.content.setting.show_client_name"
                  label-for="customer_id"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">委託客戶(資料庫)</label>

                      <span>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="新增客戶"
                          class="d-flex align-items-center actions-link-btn-sm"
                          @click="submitCustomerUpdateModal(null)"
                        >
                          <b-img
                            src="/admin/images/table/plus.svg"
                            class="actions-link-btn-image"
                            rounded
                          />
                        </div>
                      </span>
                    </div>
                  </template>

                  <div>
                    <div class="w-100">
                      <v-select
                        v-model="todoData.customer"
                        :options="customerListOptions"
                        label="name"
                        placeholder="請選擇委託客戶"
                        :filterable="false"
                        @search="fetchCustomerOptions"
                        @input="() => todoData.customer_id = todoData.customer ? todoData.customer.id : null"
                      >
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            沒有<em>{{ search }}</em> 的相關結果
                            <span
                              class="text-primary cursor-pointer"
                              @click="submitCustomerUpdateModal({name: search})"
                            >
                              立即新增
                            </span>
                          </template>

                          <div v-else>
                            <small>暫無資料</small>
                          </div>
                        </template>

                        <template v-slot:option="option">
                          <div class="d-flex align-items-center">
                            <div>
                              <b-avatar
                                variant="secondary"
                                :src="option.image"
                              />
                            </div>

                            <div class="ml-1">
                              <div class="font-weight-bold d-block text-nowrap mb-0 text-truncate">
                                {{ option.name }}
                              </div>
                              <small class="text-muted">{{ option.email }}</small>
                            </div>
                          </div>
                        </template>

                        <template #selected-option="option">
                          <div class="text-nowrap mb-0 text-truncate">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>

                      <div
                        v-if="todoData.customer"
                        class="p-1 border mt-50"
                      >
                        <div
                          class="d-flex justify-content-between align-items-center auth-users"
                        >
                          <div class="d-flex justify-content-start align-items-center">
                            <div class="mr-1">
                              <b-avatar
                                size="40"
                                variant="light-primary"
                                :src="todoData.customer.image"
                              />
                            </div>

                            <div class="profile-user-info">
                              <h6 class="mb-0">
                                <div class="text-truncate">
                                  {{ todoData.customer.name }}
                                </div>
                              </h6>
                              <small
                                class="text-muted text-truncate"
                                style="word-break: break-all"
                              >
                                {{ todoData.customer.email }}
                              </small>
                            </div>
                          </div>

                          <div class="text-nowrap">
                            <b-button
                              v-b-tooltip.hover.focus.v-secondary
                              title="編輯"
                              variant="flat-secondary"
                              size="sm"
                              class="text-nowrap p-50 btn-icon"
                              @click="submitCustomerUpdateModal(todoData.customer)"
                            >
                              <feather-icon icon="EditIcon" />
                            </b-button>

                            <b-button
                              v-b-tooltip.hover.focus.v-secondary
                              title="詳情"
                              variant="flat-secondary"
                              size="sm"
                              class="text-nowrap p-50 btn-icon"
                              @click="submitCustomerLinkUser(todoData.customer)"
                            >
                              <feather-icon icon="ExternalLinkIcon" />
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form-group>

                <!-- 群組 -->
                <b-form-group
                  v-if="!configData.content.setting.show_group_id"
                  label-for="group"
                >
                  <template #label>
                    <label class="mb-0">
                      群組
                    </label>
                  </template>

                  <v-select
                    v-model="todoData.group_id"
                    :options="$store.state['admin-todo'].groupOptions"
                    :clearable="false"
                    :reduce="option => option.value"
                    placeholder="請選擇群組"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <!-- 優先程度 -->
                <b-form-group
                  v-if="!configData.content.setting.show_priority"
                  label-for="priority"
                >
                  <template #label>
                    <label class="mb-0">
                      優先程度
                    </label>
                  </template>

                  <v-select
                    v-model="todoData.priority"
                    :options="$store.state['admin-todo'].priorityOptions"
                    :clearable="false"
                    label="title"
                    :reduce="option => option.value"
                    placeholder="請選擇狀態"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>

                    <template v-slot:option="option">
                      <div class="d-flex align-items-center">
                        <span
                          class="bullet mr-1 border filter-dot"
                          :style="{'background-color': option.color}"
                        />
                        {{ option.title }} ( {{ option.sign }} )
                      </div>
                    </template>

                    <template #selected-option="option">
                      <div class="d-flex align-items-center">
                        <span
                          class="bullet mr-1 border filter-dot"
                          :style="{'background-color': option.color}"
                        />
                        {{ option.title }} ( {{ option.sign }} )
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </app-collapse-item>

              <app-collapse-item
                title="進階設定"
                class="box-shadow-none"
              >
                <!-- 狀態 -->
                <b-form-group label-for="status">
                  <template #label>
                    <label class="mb-0">
                      狀態
                    </label>
                  </template>

                  <v-select
                    v-model="todoData.status"
                    :options="$store.state['admin-todo'].statusOptions"
                    :clearable="false"
                    label="title"
                    :reduce="option => option.value"
                    placeholder="請選擇狀態"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>

                    <template v-slot:option="option">
                      <div class="d-flex align-items-center">
                        <span
                          class="bullet mr-1 border filter-dot"
                          :style="{'background-color': option.color}"
                        />
                        {{ option.title }} ( {{ option.value }} )
                      </div>
                    </template>

                    <template #selected-option="option">
                      <div class="d-flex align-items-center">
                        <span
                          class="bullet mr-1 border filter-dot"
                          :style="{'background-color': option.color}"
                        />
                        {{ option.title }} ( {{ option.value }} )
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <!-- 負責品牌 -->
                <b-form-group label-for="admin_brand_id">
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">負責品牌</label>

                      <span>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="公司品牌設定"
                          class="d-flex align-items-center actions-link-btn-sm"
                          @click="() => { $refs.adminBrandExplorerBaseModal.getData(null) }"
                        >
                          <b-img
                            src="/admin/images/table/settings.svg"
                            class="actions-link-btn-image"
                            rounded
                          />
                        </div>
                      </span>
                    </div>
                  </template>

                  <v-select
                    v-model="todoData.admin_brand_id"
                    :options="adminBrandOptions"
                    :clearable="false"
                    label="name"
                    :reduce="option => option.id"
                    placeholder="請選擇品牌"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <!-- 負責部門 -->
                <b-form-group label-for="department_id">
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">負責部門</label>

                      <span>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="部門設定"
                          class="d-flex align-items-center actions-link-btn-sm"
                          @click="() => { $refs.departmentExplorerBaseModal.getData(null) }"
                        >
                          <b-img
                            src="/admin/images/table/settings.svg"
                            class="actions-link-btn-image"
                            rounded
                          />
                        </div>
                      </span>
                    </div>
                  </template>

                  <v-select
                    v-model="todoData.department_id"
                    :options="departmentsOptions"
                    :clearable="false"
                    label="name"
                    :reduce="option => option.id"
                    placeholder="請選擇部門"
                    @input="() => {
                      filterTodoTypeOptions(todoData.department_id)
                      filterTodoExecutionTypeOptions(todoData.department_id)
                    }"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <div>
                  <!-- 作業分類 -->
                  <b-form-group label-for="type_id">
                    <template #label>
                      <div class="d-flex justify-content-between align-items-center">
                        <label class="mb-0">
                          作業分類
                        </label>

                        <span>
                          <div
                            v-b-tooltip.hover.v-secondary
                            title="作業分類設定"
                            class="d-flex align-items-center actions-link-btn-sm"
                            @click="submitTodoTypeExplorerModal"
                          >
                            <b-img
                              src="/admin/images/table/settings.svg"
                              class="actions-link-btn-image"
                              rounded
                            />
                          </div>
                        </span>
                      </div>
                    </template>

                    <v-select
                      v-model="todoData.type_id"
                      :options="typeOptions"
                      label="name"
                      :reduce="option => option.id"
                      placeholder="請選擇作業分類"
                      :disabled="!todoData.department_id"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          沒有<em>{{ search }}</em> 的相關結果
                        </template>

                        <div v-else>
                          <small>暫無資料</small>
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>

                  <!-- 項目設定 -->
                  <b-form-group label-for="execution_type_id">
                    <template #label>
                      <div class="d-flex justify-content-between align-items-center">
                        <label class="mb-0">項目設定</label>

                        <span>
                          <div
                            v-b-tooltip.hover.v-secondary
                            title="項目設定設定"
                            class="d-flex align-items-center actions-link-btn-sm"
                            @click="submitTodoExecutionTypeExplorerModal"
                          >
                            <b-img
                              src="/admin/images/table/settings.svg"
                              class="actions-link-btn-image"
                              rounded
                            />
                          </div>
                        </span>
                      </div>
                    </template>

                    <v-select
                      v-model="todoData.execution_type_id"
                      :options="executionTypeOptions"
                      label="name"
                      :reduce="option => option.id"
                      placeholder="請選擇項目設定"
                      :disabled="!todoData.department_id"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          沒有<em>{{ search }}</em> 的相關結果
                        </template>

                        <div v-else>
                          <small>暫無資料</small>
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>
                </div>
              </app-collapse-item>

              <app-collapse-item
                title="排程設定"
              >
                <!-- 顯示狀態 -->
                <b-form-group label-for="display_status">
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <label class="mb-0">顯示狀態</label>

                        <span>
                          <div
                            v-b-tooltip.hover.v-secondary
                            title="設定為關閉，會在排定時間自動開啟"
                            class="d-flex align-items-center"
                            style="padding: 0 5px;"
                          >
                            <b-img
                              src="/admin/images/common/info.svg"
                              class="actions-link-btn-image cursor-pointer"
                              rounded
                              style=" width: 14px; height: 14px;"
                            />
                          </div>
                        </span>
                      </div>

                      <toggle-button
                        v-model="todoData.display_status"
                        :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                        :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                        :width="60"
                        :height="24"
                        class="m-25 mb-50"
                        :labels="{checked: '開啟', unchecked: '關閉'}"
                        :sync="true"
                      />
                    </div>
                  </template>
                </b-form-group>

                <!-- 截止時間 -->
                <b-form-group label-for="deadline_at">
                  <template #label>
                    <label class="mb-0">截止時間</label>
                  </template>

                  <flat-pickr
                    v-model="todoData.deadline_at"
                    class="date-form-control form-control"
                    :config="dateConfig"
                    placeholder="YYYY-MM-DD"
                  />

                  <div class="mt-25">
                    <b-badge
                      v-for="btn in timeOptions"
                      :key="btn.value"
                      variant="light-primary"
                      class="mr-50 mt-25 cursor-pointer badge-btn"
                      @click="choiceDeadlineTime(btn.value)"
                    >
                      {{ btn.label }}
                    </b-badge>
                  </div>
                </b-form-group>

                <!-- 排定時間 -->
                <b-form-group label-for="scheduled_at">
                  <template #label>
                    <div class="d-flex align-items-center">
                      <label class="mb-0">排定時間</label>

                      <span>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="指定時間此待辦才會顯示出來"
                          class="d-flex align-items-center"
                          style="padding: 0 5px;"
                        >
                          <b-img
                            src="/admin/images/common/info.svg"
                            class="actions-link-btn-image cursor-pointer"
                            rounded
                            style=" width: 14px; height: 14px;"
                          />
                        </div>
                      </span>
                    </div>
                  </template>

                  <flat-pickr
                    v-model="todoData.scheduled_at"
                    class="date-form-control form-control"
                    :config="{
                      ...dateConfig,
                      minDate: new Date(),
                    }"
                    placeholder="YYYY-MM-DD"
                  />

                  <div class="mt-25">
                    <b-badge
                      v-for="btn in timeOptions"
                      :key="btn.value"
                      variant="light-primary"
                      class="mr-50 mt-25 cursor-pointer badge-btn"
                      @click="choiceScheduledTime(btn.value)"
                    >
                      {{ btn.label }}
                    </b-badge>
                  </div>
                </b-form-group>
              </app-collapse-item>

              <app-collapse-item
                title="執行人員設定"
                class="box-shadow-none"
              >
                <div
                  v-for="role in roleOptions"
                  :key="role.value"
                  class="mb-1"
                >
                  <div>
                    <div class="d-flex justify-content-between align-items-center mb-75">
                      <h5 class="mb-0">
                        {{ role.title }} ({{ role.value }})
                      </h5>

                      <span>
                        <b-img
                          v-b-tooltip.hover.bottom.focus
                          :title="role.description"
                          src="/admin/images/common/info.svg"
                          class="cursor-pointer"
                          style="height: 17px;"
                          rounded
                        />

                        <b-img
                          v-b-tooltip.hover.bottom.focus
                          :title="`新增${role.title}`"
                          src="/admin/images/table/plus.svg"
                          class="image-icon cursor-pointer ml-50"
                          rounded
                          @click="submitEditAssignedUser(groupedUsers[role.value] ? groupedUsers[role.value] : [], role.value)"
                        />
                      </span>
                    </div>

                    <div
                      v-if="groupedUsers[role.value]"
                      class="ml-1"
                    >
                      <div
                        v-for="(user, index) in groupedUsers[role.value]"
                        :key="`auth-${index}`"
                        class="mb-2"
                      >
                        <div class="d-flex justify-content-between align-items-center auth-users">
                          <div
                            class="d-flex justify-content-start align-items-center"
                            @click="submitDescriptionAssignedUser(user, role.value, index)"
                          >
                            <div class="mr-1">
                              <b-avatar
                                size="40"
                                variant="light-primary"
                              />
                            </div>

                            <div class="profile-user-info cursor-pointer">
                              <h6
                                class="mb-0"
                              >
                                <span class="text-primary">{{ user.user_info.name }}</span>
                                <small
                                  v-if="user.department_info.id"
                                  style="word-break: break-all"
                                >
                                  ( {{ user.department_info.name }} )
                                </small>
                              </h6>

                              <small
                                style="word-break: break-all"
                                class="text-muted"
                              >{{ user.description }}</small>
                            </div>
                          </div>

                          <div>
                            <feather-icon
                              v-b-tooltip.hover.bottom.focus
                              title="移除"
                              icon="XIcon"
                              size="20"
                              class="cursor-pointer text-muted"
                              @click="submitRemoveAssignedUser(user, role.value, index)"
                            />
                          </div>
                        </div>
                      </div>

                    </div>

                    <div
                      v-if="!groupedUsers[role.value] || groupedUsers[role.value].length === 0"
                      class="cursor-pointer mb-2 ml-1"
                    >
                      <div
                        class="d-flex justify-content-start align-items-center"
                        @click="submitEditAssignedUser(groupedUsers[role.value] ? groupedUsers[role.value] : [], role.value)"
                      >
                        <div class="mr-1">
                          <b-avatar
                            size="40"
                            variant="light-primary"
                          >
                            <feather-icon
                              size="21"
                              icon="PlusIcon"
                            />
                          </b-avatar>
                        </div>

                        <div class="profile-user-info">
                          新增{{ role.title }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>

        <div class="px-1 mb-1">
          <label>說明文字</label>

          <div class="textarea-section">
            <textarea
              ref="resizeTextarea"
              v-model="siteInput"
              class="w-100 site-input-area form-control"
              autofocus
              :style="{ 'color': !isDark ? '#000' : '#d0d2d6'}"
              @paste="handlePasteSiteModal"
              @input="adjustTextareaHeight"
              @keydown="handleKeyDownSiteModal"
            />
            <!-- @keydown.enter.prevent="handleConfirmSiteModal" -->

            <div class="text-right d-flex justify-content-end align-items-center emoji-area">
              <my-emoji-component @select-emoji="selectEmojiIconSiteModal">
                <label
                  slot="button-content"
                  class="cursor-pointer"
                >
                  <feather-icon
                    icon="SmileIcon"
                    size="20"
                    class="emoji-icon"
                  />
                </label>
              </my-emoji-component>
            </div>
          </div>
        </div>

      </div>

      <!-- {{ todoData }} -->

      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <div>
            <b-button
              variant="flat-primary"
              :disabled="isRemarkBusy"
              @click="$refs.fileInput.click()"
            >
              新增
            </b-button>

            <input
              ref="fileInput"
              type="file"
              multiple
              hidden
              @input="handleAddSiteModal($event)"
            >
          </div>

          <div>
            <b-button
              variant="flat-primary"
              :disabled="isRemarkBusy"
              @click="handleHideSiteModal"
            >
              取消
            </b-button>

            <b-button
              variant="flat-primary"
              :disabled="isRemarkBusy"
              @click="handleConfirmSiteModal"
            >
              確認
            </b-button>
          </div>
        </div>
      </template>

    </b-modal>

    <department-explorer-modal
      v-if="configData"
      ref="departmentExplorerBaseModal"
      department-explorer-id="departmentExplorerBaseModal"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

    <admin-brand-explorer-modal
      v-if="configData"
      ref="adminBrandExplorerBaseModal"
      brand-explorer-id="adminBrandExplorerBaseModal"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

    <todo-type-explorer-modal
      v-if="configData"
      ref="todoTypeExplorerBaseModal"
      todo-type-explorer-id="todoTypeExplorerBaseModal"
      :table-filter="{ department_id: todoData.department_id}"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

    <todo-execution-type-explorer-modal
      v-if="configData"
      ref="todoExecutionTypeExplorerBaseModal"
      todo-execution-type-explorer-id="todoExecutionTypeExplorerBaseModal"
      :table-filter="{ department_id: todoData.department_id}"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

    <users-explorer-modal
      ref="usersQuickExplorerUpdateModal"
      :choices-users="todoData.assigned_user"
      :is-edit-auth="false"
      user-explorer-id="usersQuickExplorerUpdateModal"
      :is-hiden-emit="false"
      :use-callback-data="true"
      @call-back-data="callbackUsersExplorer"
    />

    <user-customer-explorer-modal
      ref="userCustomerExplorerQuickBaseModal"
      user-explorer-id="userCustomerExplorerQuickBaseModal"
      :admin-brand-options="adminBrandOptions"
      @call-back-data="callbackCustomerUpdate"
      @refresh-customer-list="resetCustomerListData"
    />

    <b-modal
      id="todoQuickAssignedUserUpdate"
      no-close-on-backdrop
      centered
    >
      <template #modal-title>
        <h4 class="m-0">
          協助說明 - {{ assignedUserData.user_info.name }}
        </h4>
      </template>

      <div v-if="assignedUserData">
        <div>
          <b-form-textarea
            v-model="assignedUserData.description"
            placeholder="請輸入協助說明"
            :state="assignedUserData.description.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="assignedUserData.description.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ assignedUserData.description.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            @click="confirmEditAssignedUser"
          >
            確認
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { ref, computed } from '@vue/composition-api'
import {
  BImg, BModal, BButton, BRow, BCol, VBTooltip, BFormGroup, BFormInput, BBadge, BAvatar, BFormTextarea,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import flatPickr from 'vue-flatpickr-component'
import { ToggleButton } from 'vue-js-toggle-button'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  useTodoSetting, useRemarkList, useTodoList, useQuickList, useTodoView,
} from '../useTodo'
import { useAlert, useSwalToast } from '@/libs/mixins/index'
import DepartmentExplorerModal from '@/layouts/components/Modal/department-modal/departmentModal.vue'
import AdminBrandExplorerModal from '@/layouts/components/Modal/admin-brand-modal/brandModal.vue'
import TodoTypeExplorerModal from '@/layouts/components/Modal/todo-type-modal/todoTypeModal.vue'
import TodoExecutionTypeExplorerModal from '@/layouts/components/Modal/todo-execution-type-modal/todoExecutionTypeModal.vue'
import usersExplorerModal from './UsersModal.vue'
import userCustomerExplorerModal from './UserCustomerModal.vue'
import MyEmojiComponent from '@/layouts/components/EmojiPicker/EmojiPicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BBadge,
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormTextarea,

    AppCollapse,
    AppCollapseItem,

    vSelect,
    flatPickr,
    ToggleButton,
    MyEmojiComponent,
    DepartmentExplorerModal,
    AdminBrandExplorerModal,
    TodoTypeExplorerModal,
    TodoExecutionTypeExplorerModal,
    usersExplorerModal,
    userCustomerExplorerModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
  },
  data() {
    return {
      siteFile: [],
      siteInput: '',
      siteIndex: null,
      siteSize: 15728640,
      previewFile: [],

      groupedUsers: {},
      assignedUserData: null,
      // assignedUsers: [], => todoData.assigned_user
      assignedUser: {
        type: null,
        index: null,
      },
      configId: null,
      maxChar: 255,
    }
  },
  methods: {
    moment,
    // (查詢)使用者
    fetchCustomerOptions(search, loading) {
      if (search.length) {
        loading(true)
        this.getCustomerListData({
          search,
          limit: 100,
          page: 1,
          _: Date.now(),
        })
          .then(response => {
            const { data } = response.data.data
            this.customerListOptions = data.map(item => this.syncObjectDeep(this.blankCustomerData, item))
            loading(false)
          })
      } else {
        this.resetCustomerListData()
      }
    },

    // (查詢)使用者
    fetchCustomerData(searchId) {
      this.getCustomerData({
        customer_id: searchId,
      })
        .then(response => {
          const { data } = response.data
          this.todoData.customer = this.syncObjectDeep(this.blankCustomerData, data)
        })
    },

    // (觸發)新增客戶彈窗
    submitCustomerUpdateModal(customer) {
      setTimeout(() => { this.$refs.userCustomerExplorerQuickBaseModal.getData(customer) }, 200)
    },

    // (觸發)跳轉
    submitCustomerLinkUser(customer) {
      if (!customer.id) return
      const routeUrl = `${window.location.origin}/admin/customer/${customer.id}`
      window.open(routeUrl, '_blank')
    },

    // (回傳)
    callbackCustomerUpdate(customer) {
      this.todoData.customer = this.syncObject(this.blankCustomerData, customer)
    },

    // (重新)獲取客戶列表
    resetCustomerListData() {
      this.getCustomerListData({
        limit: 100,
        page: 1,
        _: Date.now(),
      })
        .then(response => {
          const { data } = response.data.data
          this.customerListOptions = data.map(item => this.syncObjectDeep(this.blankCustomerData, item))
        })
    },
    // --------------------------------------------------------------------------------------------
    preventDefault(e) {
      e.stopPropagation() // 終止事件傳導
      e.preventDefault() // 終止預設行為
    },

    dragEnter(e) {
      this.preventDefault(e)
      this.elementNode = e.target
      const { types } = e.dataTransfer
      if (types.includes('Files')) {
        this.$refs.dropArea.classList.remove('hidden')
      }
    },

    dragLeave(e) {
      this.preventDefault(e)
      if (this.elementNode === e.target) this.$refs.dropArea.classList.add('hidden')
    },

    dropFile(e) {
      this.preventDefault(e)
      const { files, types } = e.dataTransfer
      this.siteFile = Object.values(files)

      if (!types.includes('Files')) return
      if (this.siteFile === 0) return

      files.forEach(file => {
        const previewList = {
          file,
          src: null,
        }
        if (this.judgeTypeOption.includes(file.type) && file.size <= this.siteSize) {
          const objectURL = window.URL.createObjectURL(file)
          previewList.src = objectURL
          this.previewFile.push(previewList)
        }
      })
      this.handleShowSiteModal()
    },

    // (匯入)檔案(V)
    importPasteFile(e) {
      const { files } = e
      this.siteFile = Object.values(files)
      files.forEach(file => {
        const previewList = {
          file,
          src: null,
        }
        if (this.judgeTypeOption.includes(file.type) && file.size <= this.siteSize) {
          const objectURL = window.URL.createObjectURL(file)
          previewList.src = objectURL
          this.previewFile.push(previewList)
        }
      })
    },

    // (變更)檔案(V)
    changeFile(e) {
      const { files } = e.target
      const [file] = files
      const previewList = {
        file,
        src: null,
      }
      if (this.judgeTypeOption.includes(file.type) && file.size <= this.siteSize) {
        const objectURL = window.URL.createObjectURL(file)
        previewList.src = objectURL
        this.previewFile.splice(this.siteIndex, 1, previewList)
      }
    },

    // (觸發)變更檔案(V)
    handleChangeSite(index) {
      if (index === null) return
      this.$refs.fileChange.click()
      this.siteIndex = index
    },

    // (新增)檔案(V)
    handleAddSiteModal(e) {
      const { files } = e.target
      this.siteFile.push(Object.values(files))
      files.forEach(file => {
        const previewList = {
          file,
          src: null,
        }

        if (this.judgeTypeOption.includes(file.type) && file.size <= this.siteSize) {
          const objectURL = window.URL.createObjectURL(file)
          previewList.src = objectURL
          this.previewFile.push(previewList)
        }
      })
    },

    // (觸發)刪除附件
    submitDeleteAppend(index) {
      this.previewFile.splice(index, 1)
      if (this.previewFile.length === 0) this.handleHideSiteModal()
    },

    // ------------------------------------------------------------------------------------
    // (轉成)Base64
    readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => resolve(e.target.result)
        reader.onerror = error => reject(error)
        reader.readAsDataURL(file)
      })
    },

    // (計算)時間
    calculateDueDate(timeString) {
      const timeRegex = /^(\d+)([hd])$/
      const match = timeString.match(timeRegex)

      if (!match) return new Date()

      const value = parseInt(match[1], 10)
      const unit = match[2]

      const timeInterval = unit === 'h' ? value * 60 * 60 * 1000 : value * 24 * 60 * 60 * 1000

      const currentDate = new Date()
      const dueDate = new Date(currentDate.getTime() + timeInterval)

      return dueDate
    },

    // (選擇)截止時間
    choiceDeadlineTime(value) {
      const dueDate = this.formatDate(this.calculateDueDate(value))
      this.todoData.deadline_at = dueDate
    },

    // (選擇)排定時間
    choiceScheduledTime(value) {
      const dueDate = this.formatDate(this.calculateDueDate(value))
      this.todoData.scheduled_at = dueDate
    },

    // (是否)開啟設定顯示
    isSettingOpen(type) {
      const { setting } = this.configData.content
      const myArray = [setting.show_title, setting.show_client_name, setting.show_group_id, setting.show_priority]
      if (type === 'includes') return myArray.includes(true)
      return myArray.every(value => value === true)
    },

    // (設定)單一待辦
    setTodoConfigData(config) {
      const resolveData = this.syncObject(this.blankConfigData, !config ? {} : config)
      resolveData.content = this.syncObject({ data: {}, setting: {} }, resolveData.content)
      resolveData.content.data = this.syncObject(this.blankContentData, resolveData.content.data)
      resolveData.content.setting = this.syncObject(this.blankContentSetting, resolveData.content.setting)

      if (!resolveData.name) resolveData.name = '快速新增'

      this.configData = { ...resolveData }
      const [groupOption] = this.$store.state['admin-todo'].groupOptions

      this.configId = !resolveData.id ? null : resolveData

      if (!config) return

      const resolveTodoData = this.syncObject(this.blankTodoData, config.content.data)
      const contentSetting = resolveData.content.setting
      if (!resolveTodoData.group_id) resolveTodoData.group_id = groupOption.value

      if (this.configData.id) {
        if (resolveTodoData.customer_id) this.fetchCustomerData(resolveTodoData.customer_id)

        if (!contentSetting.show_deadline_at) {
          const dueDate = this.formatDate(this.calculateDueDate(contentSetting.set_deadline_at))
          resolveTodoData.deadline_at = dueDate
        }

        if (!contentSetting.show_scheduled_at) {
          const dueDate = this.formatDate(this.calculateDueDate(contentSetting.set_scheduled_at))
          resolveTodoData.scheduled_at = dueDate
        }
      }

      resolveTodoData.assigned_user = this.todoData.assigned_user

      this.todoData = resolveTodoData

      // 判斷是否有在陣列中
      const findDepartment = this.departmentsOptions.find(el => el.id === this.todoData.department_id)

      if (findDepartment === undefined) {
        this.todoData.department_id = null
      } else {
        this.filterTodoTypeOptions(this.todoData.department_id)
        this.filterTodoExecutionTypeOptions(this.todoData.department_id)
      }
    },
    // ------------------------------------編輯快速編輯-----------------------------------------
    // (顯示)彈窗
    handleShowSiteModal(quick) {
      this.$bvModal.show('quickBaseModal')
      this.$refs.dropArea.classList.add('hidden')
      this.resetMetaList()

      this.setTodoConfigData(quick)

      setTimeout(() => {
        this.$refs.resizeTextarea.focus()
      }, 100)
    },

    // (關閉)彈窗
    handleHideSiteModal() {
      if (this.isRemarkBusy) return
      this.$bvModal.hide('quickBaseModal')
      this.siteInput = ''
      this.siteFile = []
      this.previewFile = []
    },

    // (調整)備註檔案輸入欄位
    adjustTextareaHeight() {
      this.$nextTick(() => {
        const textarea = this.$refs.resizeTextarea
        textarea.style.height = '30px'
        if (textarea.scrollHeight < 100) {
          textarea.style.height = `${textarea.scrollHeight}px`
        } else {
          textarea.style.height = '100px'
        }
      })
    },

    // (觸發)輸入欄位按鈕
    handleKeyDownSiteModal(event) {
      const textareaRef = this.$refs.resizeTextarea
      const cursorPosition = textareaRef.selectionStart
      if (event.shiftKey && event.key === 'Enter') {
        this.siteInput = `${this.siteInput.slice(0, cursorPosition)}\n${this.siteInput.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        setTimeout(() => {
          textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
          this.adjustTextareaHeight()
        }, 100)
      }
      if (event.ctrlKey && event.key === 'Enter') {
        this.siteInput = `${this.siteInput.slice(0, cursorPosition)}\n${this.siteInput.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        setTimeout(() => {
          textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
          this.adjustTextareaHeight()
        }, 100)
      }
      if (!event.shiftKey && event.key === 'Enter') {
        event.preventDefault()
        this.handleConfirmSiteModal()
      }
    },

    // (觸發)貼上
    handlePasteSiteModal() {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      navigator.clipboard.read()
        .then(clipboardItems => {
          clipboardItems.forEach(clipboardItem => {
            const { types } = clipboardItem
            if (types.length === 1) {
              const type = types[0]
              if (type === 'image/png' || type === 'image/jpeg') {
                clipboardItem.getType(type).then(blob => {
                  const file = new File([blob], 'pasted_image.png', { type: blob.type })
                  this.importPasteFile({ files: [file] })
                })
              }
            }
            if (types.length === 2) {
              if (types.includes('text/html') && types.includes('image/png')) {
                const type = types[1]
                clipboardItem.getType(type).then(blob => {
                  const file = new File([blob], 'pasted_html_image.png', { type: blob.type })
                  this.importPasteFile({ files: [file] })
                })
              }
            }
          })
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (新增)EMOJI
    selectEmojiIconSiteModal(emoji) {
      const textareaRef = this.$refs.resizeTextarea
      const cursorPosition = textareaRef.selectionStart
      this.siteInput = `${this.siteInput.slice(0, cursorPosition)}${emoji}${this.siteInput.slice(cursorPosition)}`
      const newCursorPosition = cursorPosition + emoji.length
      setTimeout(() => {
        textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
      }, 100)
    },

    // (確認)彈窗
    async handleConfirmSiteModal() {
      if (this.isRemarkBusy) return
      this.isRemarkBusy = true

      // 圖片轉檔
      const sendImageInput = {
        message: this.siteInput,
        images: [],
        ...this.todoData,
        config_id: this.configData.id,
      }

      try {
        const base64Array = await Promise.all(
          this.previewFile.map(file => this.readFileAsBase64(file.file)),
          // Object.values(this.siteFile)
        )

        sendImageInput.images = base64Array
      } catch (error) {
        // console.error(error)
      }

      this.setTodoCreateQuick({
        ...sendImageInput,
      })
        .then(() => {
          // const { data } = response.data
          // const resolveResponse = this.resolveRemarkInfoData({ ...data })
          // this.$emit('open-chat-contact', data.id)
          this.$emit('refresh-todo-list')
          this.isRemarkBusy = false
          this.handleHideSiteModal()
        })
        .catch(() => {
          // this.$emit('delete-remark-data', this.randomKey)
          this.isRemarkBusy = false
        })
    },

    // ------------------------------------------------------------------------------------------------
    // (觸發)作業分類彈窗
    submitTodoTypeExplorerModal() {
      if (!this.todoData.department_id) {
        this.useSwalAlertCenter(false, '尚未選擇部門', '請先選擇待辦部門')
        return
      }
      this.$refs.todoTypeExplorerBaseModal.getData(null)
    },

    // (觸發)項目設定彈窗
    submitTodoExecutionTypeExplorerModal() {
      if (!this.todoData.department_id) {
        this.useSwalAlertCenter(false, '尚未選擇部門', '請先選擇待辦部門')
        return
      }
      this.$refs.todoExecutionTypeExplorerBaseModal.getData(null)
    },

    // (過濾)作業分類
    filterTodoTypeOptions(departmentId) {
      const findTypeGroup = this.typeGroupOptions.find(el => el.department_id === departmentId)
      if (findTypeGroup === undefined) {
        this.todoData.type_id = null
        this.typeOptions = []
        return
      }

      this.typeOptions = findTypeGroup.data

      const findType = this.typeOptions.find(el => el.id === this.todoData.type_id)

      if (findType === undefined) {
        this.todoData.type_id = null
      }
    },

    // (過濾)項目設定
    filterTodoExecutionTypeOptions(departmentId) {
      const findexEcutionTypeGroup = this.executionTypeGroupOptions.find(el => el.department_id === departmentId)
      if (findexEcutionTypeGroup === undefined) {
        this.todoData.execution_type_id = null
        this.executionTypeOptions = []
        return
      }

      this.executionTypeOptions = findexEcutionTypeGroup.data

      const findexEcutionType = this.executionTypeOptions.find(el => el.id === this.todoData.execution_type_id)

      if (findexEcutionType === undefined) {
        this.todoData.execution_type_id = null
      }
    },
    // --------------------------------------------------------------------------------------------
    // (觸發)編輯執行人員
    submitEditAssignedUsers() {
      const groupedUserArray = this.todoData.assigned_user.reduce((grouped, user) => {
        const roleListItem = this.roleOptions.find(roleItem => roleItem.value === user.role)

        const groupedArr = grouped

        if (roleListItem) {
          const groupName = roleListItem.value

          if (!grouped[groupName]) {
            groupedArr[groupName] = []
          }

          groupedArr[groupName].push(user)
        }

        return groupedArr
      }, {})
      this.groupedUsers = groupedUserArray
      this.todoData.assigned_user = JSON.parse(JSON.stringify(this.todoData.assigned_user))
    },

    // (觸發)編輯單一執行人員
    submitEditAssignedUser(users, type) {
      this.assignedUser.type = type
      setTimeout(() => { this.$refs.usersQuickExplorerUpdateModal.getData(users) }, 200)
      // usersExplorerUpdateModal => usersQuickExplorerUpdateModal
    },

    // (觸發)編輯單一執行人員
    submitDescriptionAssignedUser(users, type, index) {
      if (this.isReview) return
      this.assignedUser.type = type
      this.assignedUser.index = index
      this.assignedUserData = JSON.parse(JSON.stringify(users))
      this.$bvModal.show('todoQuickAssignedUserUpdate')
    },

    // (觸發)移除單一執行人員
    submitRemoveAssignedUser(user, type, index) {
      this.useSwalAlertWarning('移除人員', `你確定要移除人員 ${user.user_info.name} 嗎?`)
        .then(result => {
          if (result.value) {
            if (this.groupedUsers[type] && this.groupedUsers[type].length > index) {
              this.groupedUsers[type].splice(index, 1)
            }
            this.todoData.assigned_user = this.todoData.assigned_user.filter(item => item.user_id !== user.user_id)
          }
        })
    },

    // (回調)選擇的使用者
    callbackUsersExplorer(users) {
      if (!Object.prototype.hasOwnProperty.call(this.groupedUsers, this.assignedUser.type)) {
        this.$set(this.groupedUsers, this.assignedUser.type, this.groupedUsers[this.assignedUser.type] || [])
      }

      users.forEach(user => {
        const resolveUser = {
          ...this.syncObject(this.blankAssignedData, { ...user, role: this.assignedUser.type }),
          user_info: this.syncObject(this.blankSimpleInfo, user.user_info),
          department_info: this.syncObject(this.blankSimpleInfo, user.department_info),
        }
        this.groupedUsers[this.assignedUser.type].push(resolveUser)
        this.todoData.assigned_user.push(resolveUser)
      })
    },

    // (編輯)單一執行人員
    confirmEditAssignedUser() {
      if (this.assignedUserData.description && this.assignedUserData.description.length > this.maxChar) {
        this.useAlertToast(false, '字數超過上限')
        return
      }

      this.groupedUsers[this.assignedUser.type].splice(this.assignedUser.index, 1, this.assignedUserData)

      const resolveUsers = this.todoData.assigned_user.map(item => {
        if (item.user_id === this.assignedUserData.user_id) {
          const resolve = {
            ...item, description: this.assignedUserData.description,
          }
          return resolve
        }
        return item
      })
      this.todoData.assigned_user = resolveUsers
      this.$bvModal.hide('todoQuickAssignedUserUpdate')
    },
    // ------------------------------------------------------------------------------------------------
    // (重新)獲取Meta
    resetMetaList() {
      this.getMetaListData()
        .then(response => {
          // 陣列更新中
          const { departments } = response.data.data
          this.departmentsOptions = departments

          const adminBrands = response.data.data.admin_brands
          this.adminBrandOptions = adminBrands

          const todoTypes = response.data.data.todo_types
          this.typeGroupOptions = todoTypes

          const executionTypes = response.data.data.execution_types
          this.executionTypeGroupOptions = executionTypes

          // const todoStatus = response.data.data.todo_status
          // this.todoStatusOptions = todoStatus

          const todoRole = response.data.data.todo_role
          this.roleOptions = todoRole
          this.submitEditAssignedUsers()

          // 判斷是否有在陣列中
          const findDepartment = departments.find(el => el.id === this.todoData.department_id)

          if (findDepartment === undefined) {
            this.todoData.department_id = null
          } else {
            this.filterTodoTypeOptions(this.todoData.department_id)
            this.filterTodoExecutionTypeOptions(this.todoData.department_id)
          }

          const findAdminBrand = adminBrands.find(el => el.id === this.todoData.admin_brand_id)

          if (findAdminBrand === undefined) {
            this.todoData.admin_brand_id = null
          }
        })

      this.resetCustomerListData()
    },
  },
  setup() {
    moment.locale('zh-tw')
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const {
      syncObject,
      syncObjectDeep,
      blankSimpleInfo,
      blankCustomerData,
      roleLists,
      roleOptions,
      getMetaListData,
      departmentsOptions,
      adminBrandOptions,
      executionTypeOptions,
      typeGroupOptions,
      executionTypeGroupOptions,
      typeOptions,
      customerListOptions,
      getCustomerData,
      getCustomerListData,
    } = useTodoSetting()

    const {
      blankContentSetting,
      blankContentData,
      blankConfigData,
    } = useQuickList()

    const {
      setTodoCreateQuick,
    } = useTodoList()

    const {
      blankAssignedData,
    } = useTodoView()

    const {
      isRemarkBusy,
      blankImageData,
      judgeTypeOption,
      resolveRemarkInfoData,
    } = useRemarkList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankTodoData = {
      group_id: null,
      admin_brand_id: null,
      department_id: null,
      customer_id: null,
      customer: null,
      title: null,
      type_id: null,
      execution_type_id: null,
      status: 'not-completed',
      assigned_user: [],
      priority: 50,
      client_name: null,
      display_status: true,
      scheduled_at: null, // formatDate(new Date()),
      deadline_at: null,
      // new Date(),
    }

    const configId = ref(null)
    const todoData = ref(JSON.parse(JSON.stringify(blankTodoData)))
    const configData = ref(null)

    const formatDate = date => {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')

      return `${year}-${month}-${day} ${hours}:${minutes}`
    }

    const timeOptions = [
      { label: '1H', value: '1h' },
      { label: '2H', value: '2h' },
      { label: '3H', value: '3h' },
      { label: '5H', value: '5h' },
      { label: '一天', value: '1d' },
      { label: '一周', value: '7d' },
    ]

    const dateConfig = {
      locale: language.zh_tw,
      dateFormat: 'Y-m-d H:i',
      allowInput: true,
      static: true,
      enableTime: true,
      // inline: true,
    }

    return {
      isDark,
      configId,
      blankSimpleInfo,
      blankAssignedData,
      blankCustomerData,
      roleLists,
      roleOptions,
      todoData,
      configData,
      blankTodoData,
      getMetaListData,
      departmentsOptions,
      adminBrandOptions,
      executionTypeOptions,
      typeGroupOptions,
      executionTypeGroupOptions,
      typeOptions,
      syncObject,
      syncObjectDeep,
      formatDate,

      judgeTypeOption,
      isRemarkBusy,
      blankImageData,
      timeOptions,
      dateConfig,
      blankContentSetting,
      blankContentData,
      blankConfigData,

      resolveRemarkInfoData,

      useAlertToast,
      useHttpCodeAlert,
      setTodoCreateQuick,

      customerListOptions,
      getCustomerData,
      getCustomerListData,
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-context.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.upload-card {
  // background-color: rgb(40, 240, 63);
  position: relative;
  min-height: 70vh;
  .dropArea {
    min-height: 50%;
    position: absolute;
    z-index: 1000;
    padding: 30px;
    top: 0; // 60%
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #282828;
    min-height: 90vh;
    opacity: .9;
    // backdrop-filter: blur(10px);
    text-align: center;
    align-self: center;
    color: #fff;
    // text-shadow: 0em 0.01em #333, 0em 0.02em #333, 0em 0.02em 0.03em #333;
    &.hidden {
      display: none;
    }
  }
  .upload-label {
    position: sticky;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    border-radius: 10px;
    height: 40vh;
    border: #898989 dashed 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .label-title {
      display: block;
      font-size: 2rem;
      font-weight: 900;
      padding: 1rem 0 0.5rem;
    }
    .label-subtitle {
      font-size: 1rem;
    }
  }

  .upload-label-text {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 900;
    padding: 1rem 0 0.5rem;
  }
}

.site-input-area {
  resize: none;
}

.site-modal {
  .site-modal-images {
    // position: relative;
    // background-color: red;
    // padding: 10px;
    .site-modal-tool {
      position: absolute;
      padding: 5px 10px;
      background-color: rgba(51, 51, 51, 0.59);
      border-radius: 5px;
      color: white;
      top: 5%;
      right: 8%;
      // display: flex;
      .site-modal-tool-icon {
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .site-modal-files {
    .site-modal-tool {
      .site-modal-tool-icon {
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

.textarea-section {
  position: relative;
  .emoji-area {
    position: absolute;
    bottom: 0;
    right: 8px;
    margin-top: 20px;
  }
}

.show-more-arrow {
  transition: transform 0.3s ease-in-out;
  .active {
    transition: transform 0.3s ease-in-out;
    transform: rotate(90deg);
  }
}

.model-header {
  color: white;
  padding: 3px 0;
}

.image-icon {
  height: 16px;
}

.text-truncate {
  max-width: 180px;
}

</style>
