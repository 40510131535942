import { render, staticRenderFns } from "./TodoContact.vue?vue&type=template&id=21e921c8&scoped=true&"
import script from "./TodoContact.vue?vue&type=script&lang=js&"
export * from "./TodoContact.vue?vue&type=script&lang=js&"
import style0 from "./TodoContact.vue?vue&type=style&index=0&id=21e921c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e921c8",
  null
  
)

export default component.exports