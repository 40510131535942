<template>
  <div
    class="d-flex align-items-center justify-content-center cursor-pointer search-icon-div"
    @click="$emit('submit-search-content', searchData)"
  >
    <!-- @contextmenu.prevent="$emit('submit-search-context')" -->
    <b-avatar
      :id="searchData.serial"
      size="40"
      :src="searchData.image"
      class="badge-minimal p-25 border search-icon"
      :variant="isBrighten ? 'info' : 'light-primary'"
      :text="avatarText(searchData.name)"
    >
      <feather-icon
        v-if="!searchData.image && !searchData.name"
        size="21"
        icon="MessageSquareIcon"
      />
    </b-avatar>

    <b-tooltip
      v-if="searchData.serial"
      :target="searchData.serial"
      :delay="delay"
      boundary-padding="10"
      boundary="window"
      placement="right"
    >
      {{ searchData.name }}
    </b-tooltip>

    <!-- v-b-tooltip.hover.bottom.v-secondary
    :title="searchData.name" -->
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import {
  VBTooltip, BAvatar, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { useTodoSetting, useSearchList } from '../useTodo'

export default {
  components: {
    BAvatar,
    BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    searchSetting: {
      type: Object,
      required: true,
    },
    isBrighten: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      delay: { show: 500 },
    }
  },
  methods: {
  },
  setup(props) { // props
    const {
      syncObject,
    } = useTodoSetting()

    const {
      blankSearchData,
    } = useSearchList()

    const blankSearch = {
      serial: null,
      ...blankSearchData,
    }

    const searchData = computed(() => {
      const resolveData = syncObject(blankSearch, props.searchSetting)

      return resolveData
    })

    return {
      blankSearch,
      searchData,
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.search-icon-div {
  transition: transform 0.5s ease-in-out;
  .search-icon:hover {
    // background-color: red;
    transform: scale(1.2);
  }
}
</style>
