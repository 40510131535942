<template>
  <div>
    <b-modal
      :id="userExplorerId"
      no-close-on-backdrop
      centered
      :size="useCallbackData ? 'lg' : 'md'"
      header-bg-variant="primary"
      body-class="p-0"
      @hidden="handleHide"
      @close="handleHide"
    >
      <!-- @change="chgModalState" -->
      <template #modal-title>
        <h4 class="m-0 model-header">
          執行人員
        </h4>
      </template>

      <div>
        <div class="m-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="text-nowrap d-flex justify-content-end align-items-center mt-50">
                <div
                  v-if="isEditAuth"
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn"
                  @click="onSubmitUpdate(null)"
                >
                  <b-img
                    src="/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="篩選器"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/admin/images/table/filter.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form style="min-width: 300px">
                      <b-form-group>
                        <label class="mb-50">
                          搜尋
                        </label>

                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="SearchIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            v-model="searchQuery"
                            debounce="500"
                            class="d-inline-block"
                            placeholder="搜尋..."
                          />
                          <b-input-group-append
                            v-if="searchQuery"
                            is-text
                          >
                            <feather-icon
                              icon="XIcon"
                              class="cursor-pointer text-muted"
                              @click="searchQuery = null"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="tableData"
          :fields="tableColumnsFilter"
          responsive
          striped
          hover
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          sort-by.sync="id"
          :busy="isTableBusy"
          class="m-0 position-relative"
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <!-- sticky-header -->
          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumns.length"
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div style="min-width: 40px">
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 名稱 -->
          <template #cell(name)="data">
            <div class="table-title">
              <b-link
                class="font-weight-bold d-block text-nowrap mb-0"
                @click="onSubmitUpdate(data.item)"
              >
                <div class="item-description">
                  {{ data.item.name }}
                </div>
              </b-link>
              <small class="text-muted">{{ data.item.account }}</small>
            </div>
          </template>

          <!-- 欄位: 部門 -->
          <template #cell(department)="data">
            <div class="table-title">
              {{ data.item.department_id && data.item.department_info ? data.item.department_info.name : '---' }}
            </div>
          </template>

          <!-- 欄位: 目前設定 -->
          <template #cell(use)="data">
            <!-- {{ choicesUsers.every(item => item.user_id !== data.item.id) }} -->
            <div style="min-width: 50px">
              <div v-if="choicesUsers.every(item => item.user_id !== data.item.id)">
                <feather-icon
                  v-if="selectUsers.some(item => item.user_id === data.item.id)"
                  icon="CheckCircleIcon"
                  size="20"
                  class="align-middle text-success font-weight-bolder"
                  @click="removeUser(data.item)"
                />

                <feather-icon
                  v-else
                  icon="CircleIcon"
                  size="20"
                  class="align-middle text-body text-muted"
                  style="opacity: 0.5;"
                  @click="addUser(data.item)"
                />
              </div>

              <div v-else>
                已設定
              </div>
            </div>
          </template>

          <!-- 欄位: 動作 -->
          <template #cell(actions)="data">
            <div class="d-flex mb-50">
              <div
                class="actions-link-btn-sm mr-25"
                @click="onSubmitUpdate(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.v-secondary
                  title="編輯"
                  src="/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                class="actions-link-btn-sm mr-25"
                @click="onSubmitDelete(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.v-secondary
                  title="刪除"
                  src="/admin/images/table/delete.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="mx-2 my-1">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            v-if="useCallbackData"
            variant="primary"
            :disabled="isTableBusy"
            @click="handleOk"
          >
            <!--  || (botInfo.id === null && selectBotData.id === null) -->
            <span v-if="!isTableBusy">確認</span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </template>
    </b-modal>

    <user-modal-update
      v-if="selected"
      :ref="`${userExplorerId}Update`"
      :update-modal-id="`${userExplorerId}Update`"
      :selected-item="selected"
      @reset-table="refreshUserData"
    />
  </div>
</template>

<script>
// import { onUnmounted } from '@vue/composition-api'
import {
  BModal, VBTooltip, BTable, BButton, BSkeletonTable, BSpinner, BRow, BCol, BLink, BPagination, BImg,
  BDropdown, BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

// import router from '@/router'
// import store from '@/store'
import { useUserModal, useTodoSetting } from '../useTodo'
// import UserModalUpdate from './userModalUpdates.vue'
import { useSwalToast } from '@/libs/mixins/index' // , useAlert
// import useStoreModule from '@/views/admin/user/useStoreModule'

export default {
  components: {
    BModal,
    BImg,
    BRow,
    BCol,
    BLink,
    BTable,
    BButton,
    BPagination,
    BSkeletonTable,
    BSpinner,
    BDropdown,
    BDropdownForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    isEditAuth: {
      type: Boolean,
      default: false,
    },
    isHidenEmit: {
      type: Boolean,
      default: false,
    },
    useCallbackData: {
      type: Boolean,
      default: false,
    },
    userExplorerId: {
      type: String,
      default: 'user-explorer-modal',
    },
    choicesUsers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
      selectUsers: [],
    }
  },
  computed: {
    tableColumnsFilter() {
      if (!this.isEditAuth) {
        if (!this.useCallbackData) {
          return this.tableColumns
            .filter(f => f.key !== 'use' && f.key !== 'actions')
        }
        return this.tableColumns
          .filter(f => f.key !== 'actions')
      }
      if (!this.useCallbackData) {
        return this.tableColumns
          .filter(f => f.key !== 'use')
      }
      return this.tableColumns
    },
  },
  methods: {
    // (選取)使用者
    addUser(user) {
      this.selectUsers.push({
        user_id: user.id,
        user_info: {
          id: user.id,
          name: user.name,
        },
        department_info: user.department_info,
      })
    },

    // (取消選取)使用者
    removeUser(user) {
      this.selectUsers = this.selectUsers.filter(item => item.user_id !== user.id)
    },

    // (觸發)新增/編輯管理員
    onSubmitUpdate(item) {
      if (!this.isEditAuth) return
      if (!item) {
        this.selected = {}
        setTimeout(() => { this.$refs[`${this.userExplorerId}Update`].getData() }, 200)
        return
      }
      this.selected = item
      setTimeout(() => { this.$refs[`${this.userExplorerId}Update`].getData() }, 200)
    },

    // (重設)使用者
    refreshUserData(user) {
      if (user.id === this.selectUsers.id) this.selectUsers.name = user.name
      this.getUserListData()
    },

    // (刪除)分類
    onSubmitDelete(item) {
      if (!this.isEditAuth) return
      this.useSwalAlertWarning('刪除使用者', `你確定要永久刪除使用者 ${item.name} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setUserDelete({
              user_id: item.id,
            }).then(() => {
              this.getUserListData()
              this.useSwalAlertCenter(true, '刪除成功!', '使用者已移除')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },

    // 按下彈窗選擇
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isTableBusy) return

      const filterUser = this.selectUsers.filter(selectUser => !this.choicesUsers.some(choiceUser => choiceUser.user_id === selectUser.user_id))

      if (filterUser.length === 0) {
        this.$nextTick(() => {
          this.$bvModal.hide(this.userExplorerId)
          if (this.isHidenEmit) this.$emit('emit-hidden-function')
        })
        return
      }

      const userMessage = filterUser.length > 1
        ? `你確定要設定所選 ${filterUser.length} 個人員嗎?`
        : `你確定要設定執行人員為 ${filterUser[0].user_info.name} 嗎?`

      this.useSwalAlertInfo('協助設定', userMessage)
        .then(result => {
          if (result.value) {
            this.$emit('call-back-data', filterUser)
            this.$nextTick(() => {
              this.$bvModal.hide(this.userExplorerId)
              if (this.isHidenEmit) this.$emit('emit-hidden-function')
            })
          }
        })
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return
      this.$nextTick(() => {
        this.$bvModal.hide(this.userExplorerId)
        if (this.isHidenEmit) this.$emit('emit-hidden-function')
      })
    },

    // (獲取資料)
    getData(users) {
      const resolveData = users.map(item => {
        const resolve = {
          user_id: item.user_id,
          user_info: this.syncObject(this.blankData, item.user_info),
          department_info: this.syncObject(this.blankData, item.department_info),
        }
        return resolve
      })
      this.selectUsers = resolveData
      this.isTableBusy = true
      this.$bvModal.show(this.userExplorerId)
      this.getUserListData()
    },
  },
  setup() {
    const blankData = {
      id: null,
      name: null,
    }

    const {
      tableData,
      isTableBusy,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchTable,

      setUserDelete,
      setUserCreate,
      setUserUpdate,
      getUserListData,
      useHttpCodeAlert,
    } = useUserModal()

    const {
      syncObject,
      getMetaListData,
    } = useTodoSetting()

    return {
      blankData,
      tableData,
      isTableBusy,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchTable,

      setUserDelete,
      setUserCreate,
      setUserUpdate,
      getUserListData,
      getMetaListData,
      syncObject,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}
</style>
