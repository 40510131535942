<template>
  <div :class="isDark ? 'editor-dark' : 'editor'">
    <editor
      v-model="html"
      class="editor-preview"
      :default-config="editorConfig"
      @onCreated="onCreated"
    />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { Editor } from '@wangeditor/editor-for-vue'
// import { i18nAddResources, i18nChangeLanguage } from '@wangeditor/editor'
import '@wangeditor/editor/dist/css/style.css'

export default {
  components: {
    Editor,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editor: null,
      html: this.content,
    }
  },
  beforeDestroy() {
    const editorStill = this.editor
    if (editorStill == null) return
    this.editor.destroy()
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor)
      // this.editor.disable()
    },

    getEditorContent() {
      return this.html
    },
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const editorConfig = {
      placeholder: '尚未設定內容',
      readOnly: true,
    }

    return {
      isDark,
      editorConfig,
    }
  },
}
</script>

<style lang="scss">
.editor-preview {
  .table-container {
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    table th {
      border-right-width: 1px !important;
      cursor: auto !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.editor {
  --w-e-textarea-bg-color: #fff; //#fff
  --w-e-textarea-color: #333;
  --w-e-textarea-border-color: #ccc;
  --w-e-textarea-slight-border-color: #e8e8e8;
  --w-e-textarea-slight-color: #d4d4d4;
  --w-e-textarea-slight-bg-color: #f5f2f0;
  --w-e-textarea-selected-border-color: #B4D5FF;
  --w-e-textarea-handler-bg-color: #4290f7;
  --w-e-toolbar-color: #595959;
  --w-e-toolbar-bg-color: #fff; //#fff
  --w-e-toolbar-active-color: #333;
  --w-e-toolbar-active-bg-color: #f1f1f1;
  --w-e-toolbar-disabled-color: #999;
  --w-e-toolbar-border-color: #e8e8e8;
  --w-e-modal-button-bg-color: #fafafa;
  --w-e-modal-button-border-color: #d9d9d9;
  border: none;
}

.editor-dark {
  --w-e-textarea-bg-color: #344464;
  --w-e-textarea-color: #d0d2d6;
  --w-e-textarea-border-color: #b4b7bd;
  --w-e-textarea-slight-border-color: #ffc800;
  --w-e-textarea-slight-color: #b0b1b5aa ;
  --w-e-textarea-slight-bg-color: #283856;
  --w-e-textarea-selected-border-color: #24c9ff;
  --w-e-textarea-handler-bg-color: #24c9ff;
  --w-e-toolbar-color: #d0d2d6;
  --w-e-toolbar-bg-color: #344464;
  --w-e-toolbar-active-color: #d0d2d6;
  --w-e-toolbar-active-bg-color: #161d31;
  --w-e-toolbar-disabled-color: #b0b1b5aa;
  --w-e-toolbar-border-color: #596987;
  --w-e-modal-button-bg-color: #24c9ff;
  --w-e-modal-button-border-color: #596987;
  border: none;
}
</style>
