<template>
  <div>
    <b-modal
      :id="userExplorerId"
      no-close-on-backdrop
      centered
      header-bg-variant="primary"
      @hidden="handleHide"
      @close="handleHide"
    >
      <!-- @change="chgModalState" -->
      <template #modal-title>
        <h4 class="m-0 model-header">
          {{ customerData.id ? '編輯' : '新增' }}委託客戶
        </h4>
      </template>

      <div>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- 名稱 -->
            <validation-provider
              #default="{ errors }"
              name="客戶名稱"
              rules="required"
            >
              <b-form-group label-for="name">
                <template #label>
                  <label class="mb-0">
                    客戶名稱
                    <span class="text-danger">*</span>
                  </label>
                </template>

                <b-form-input
                  id="name"
                  v-model="customerData.name"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                  trim
                  placeholder="請輸入委託客戶名稱"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- 信箱 -->
            <validation-provider
              #default="{ errors }"
              name="信箱"
              rules="required|email"
            >
              <b-form-group label-for="email">
                <template #label>
                  <label class="mb-0">
                    信箱
                    <span class="text-danger">*</span>
                  </label>
                </template>

                <b-form-input
                  id="name"
                  v-model="customerData.email"
                  :state="errors.length > 0 ? false : null"
                  trim
                  placeholder="請輸入委託客戶信箱"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- 公司品牌 -->
            <div>
              <b-form-group label-for="admin_brand">
                <template #label>
                  <label class="mb-0">
                    公司品牌
                  </label>
                </template>

                <v-select
                  v-model="customerData.admin_brand_id"
                  :options="adminBrandOptions"
                  label="name"
                  :reduce="option => option.id"
                  placeholder="請選擇公司品牌"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </div>
          </b-form>
        </validation-observer>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            :disabled="isBusy"
            @click="handleOk"
          >
            <span v-if="!isBusy">確認</span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BModal, VBTooltip, BButton, BSpinner, BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useTodoSetting } from '../useTodo'
import { useSwalToast, useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    isEditAuth: {
      type: Boolean,
      default: false,
    },
    isHidenEmit: {
      type: Boolean,
      default: false,
    },
    useCallbackData: {
      type: Boolean,
      default: false,
    },
    userExplorerId: {
      type: String,
      default: 'user-customer-explorer-modal',
    },
    adminBrandOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      email,
      missingFields: [],
    }
  },
  methods: {
    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: ' 客戶名稱',
        email: '客戶信箱',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.customerData[Object.keys(requiredFields)[i]] === null || this.customerData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      // setTodoCustomerCreate,
      // setTodoCustomerUpdate,

      this.isBusy = true

      const resolvedSendData = this.syncObject({
        id: null,
        name: null,
        email: null,
        admin_brand_id: null,
      }, this.customerData)

      if (this.customerData.id) {
        this.setTodoCustomerUpdate({
          customer_id: this.customerData.id,
          data: {
            ...resolvedSendData,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('call-back-data', this.customerData)
            this.$nextTick(() => {
              this.$bvModal.hide(this.userExplorerId)
              this.$emit('refresh-customer-list')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      } else {
        this.setTodoCustomerCreate({
          ...resolvedSendData,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$nextTick(() => {
              this.$bvModal.hide(this.userExplorerId)
              this.$emit('refresh-customer-list')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return
      this.$nextTick(() => {
        this.$bvModal.hide(this.userExplorerId)
        if (this.isHidenEmit) this.$emit('emit-hidden-function')
      })
    },

    // (獲取資料)
    getData(customer) {
      const resolveData = this.syncObject(this.blankData, customer ? { ...customer } : {})
      this.customerData = resolveData
      this.$bvModal.show(this.userExplorerId)
    },
  },
  setup() {
    localize('tw')

    const blankData = {
      id: null,
      name: null,
      image: null,
      email: null,
      admin_brand_id: null,
    }

    const {
      syncObject,
      setTodoCustomerCreate,
      setTodoCustomerUpdate,
    } = useTodoSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const customerData = ref(JSON.parse(JSON.stringify(blankData)))
    const isBusy = ref(false)

    const resetCustomerData = () => {
      customerData.value = JSON.parse(JSON.stringify(blankData))
      isBusy.value = false
    }

    return {
      isBusy,
      blankData,
      customerData,

      useAlertToast,
      useHttpCodeAlert,
      setTodoCustomerCreate,
      setTodoCustomerUpdate,
      syncObject,
      resetCustomerData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}
</style>
