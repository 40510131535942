<template>
  <div
    class="chat-app"
    style="height: inherit"
  >
    <div
      class="body-content-overlay"
      :class="{'show': shallOpenTodoSettingSidebarState || shallOpenTodoAddSidebarState || shallShowActiveChatUpdateSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowActiveChatUpdateSidebar=shallOpenTodoAddSidebarState=shallOpenTodoSettingSidebarState=false"
    />

    <section class="chat-app-window">

      <todo-quick-list
        ref="todoQuickList"
        @refresh-todo-list="refreshTodoList"
      />

      <!-- 開始使用待辦 -->
      <div
        v-show="!activeInfo.data && !activeChartShow"
        class="start-chat-area"
      >
        <div
          class="quick-create-area"
          @click="openTodoQuickBaseModal(null)"
        >
          <!-- <todo-quick-add
            ref="todoQuickAdd"
            @open-chat="openChatOfContact"
          /> -->

          <!-- <todo-quick-base
            ref="todoQuickBase"
            @open-chat-contact="openChatOfContact"
            @refresh-todo-list="refreshTodoList"
          /> -->
        </div>

        <div class="start-chat-label">
          <div
            class="mb-1 start-chat-icon"
            @click="startConversation"
          >
            <feather-icon
              icon="MessageSquareIcon"
              size="56"
            />
          </div>

          <div class="quick-area mb-25">
            <h4
              class="sidebar-toggle start-chat-text d-lg-block d-none mx-50"
              @click="openTodoQuickBaseModal(null)"
            >
              快速新增
            </h4>

            <h4
              class="sidebar-toggle start-chat-text d-lg-none d-block mx-50"
              @click="startConversation"
            >
              開始使用待辦
              <!-- {{ activeInfoBusy }} / {{ activeRemarkBusy }} -->
            </h4>
          </div>

          <div
            v-if="$store.state['admin-todo'].useQuickState"
            class="quick-area animate__animated animate__fadeIn"
          >
            <!-- d-lg-block d-none -->
            <!-- {{ $store.state['admin-todo'].quickOptions }} -->
            <div
              v-for="quick in $store.state['admin-todo'].quickOptions"
              :key="quick.id"
              class="mx-50 d-lg-block d-none"
              @click="openTodoQuickBaseModal(quick)"
            >
              <h4 class="sidebar-toggle start-chat-text">
                {{ quick.name }}
              </h4>
            </div>

            <div
              class="actions-link-btn-sm mr-25 d-lg-block d-none"
              @click="openTodoQuickListModal"
            >
              <h4 class="sidebar-toggle start-chat-text">
                <b-img
                  v-b-tooltip.hover.v-secondary
                  title="編輯"
                  src="/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </h4>
            </div>
          </div>
        </div>

        <!-- <div class="quick-create-icon">
          <div
            v-b-tooltip.hover.v-secondary
            title="自訂義設定"
            class="d-flex align-items-center icon-link-btn"
            @click="openTodoQuickListModal"
          >
            <b-img
              src="/admin/images/table/settings.svg"
              class="icon-link-btn-image"
              rounded
            />
          </div>
        </div> -->
      </div>

      <!-- 待辦備註列表 -->
      <div
        v-if="activeInfo.data"
        class="active-chat animate__animated animate__fadeIn"
      >
        <div class="chat-navbar">
          <div v-if="!searchMode">
            <header
              v-if="selectedReamrk.length === 0"
              class="chat-header"
            >

              <div class="d-flex align-items-center">

                <div class="sidebar-toggle d-block d-lg-none mr-1">
                  <feather-icon
                    icon="MenuIcon"
                    class="cursor-pointer"
                    size="21"
                    @click="mqShallShowLeftSidebar = true"
                  />
                </div>

                <div class="go-back mr-1 d-none d-lg-block">
                  <feather-icon
                    :icon="'ChevronLeftIcon'"
                    size="20"
                    class="align-bottom cursor-pointer"
                    @click="() => {
                      activeInfo = {}
                      $router.push({ name: 'admin-todo' })
                    }"
                  />
                </div>

                <h6
                  class="mb-0 email-subject cursor-pointer"
                  @click="shallShowActiveChatContactSidebar = true"
                >
                  {{ activeInfo.data.title ? activeInfo.data.title : '未命名標題' }}
                  <!-- / {{ $store.state['admin-todo'].firstListInfo }} -->
                </h6>
              </div>

              <div
                v-if="activeInfo.state"
                class="d-flex align-items-center"
              >
                <div
                  v-if="!isReview"
                  class="dropdown mr-50 mt-25"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <b-badge
                        class="border todo-tag-area-badge"
                        :style="{
                          'background-color': activeInfo.priorityLists[activeInfo.data.priority].color,
                          'color': activeInfo.priorityLists[activeInfo.data.priority].font_color,
                        }"
                      >
                        {{ activeInfo.priorityLists[activeInfo.data.priority].title }}
                      </b-badge>
                    </template>
                    <b-dropdown-item
                      v-for="(priority, index) in activeInfo.priorityOptions"
                      :key="`priority-${index + 1}`"
                      v-b-tooltip.hover.right.v-secondary
                      :title="priority.description"
                      class="filter-item mb-25 animate__animated animate__fadeIn"
                      @click="updateTodoData('priority', { priority: priority.value }, priority.value == activeInfo.data.priority)"
                    >
                      <!-- <span class="mr-50 bullet bullet-success bullet-sm" /> -->
                      <span
                        class="bullet mr-1 border filter-dot"
                        :style="{'background-color': priority.color}"
                      />
                      <span class="align-text-bottom line-height-1">{{ priority.title }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <div
                  v-if="!isReview"
                  class="dropdown mr-50 mt-25"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <b-badge
                        class="border todo-tag-area-badge"
                        :style="{
                          'background-color': activeInfo.statusLists[activeInfo.data.status].color,
                          'color': activeInfo.statusLists[activeInfo.data.status].font_color,
                        }"
                      >
                        {{ activeInfo.statusLists[activeInfo.data.status].title }}
                      </b-badge>
                    </template>
                    <b-dropdown-item
                      v-for="(status, index) in activeInfo.statusOptions"
                      :key="`status-${index + 1}`"
                      v-b-tooltip.hover.right.v-secondary
                      :title="status.description"
                      class="filter-item mb-25 animate__animated animate__fadeIn"
                      @click="updateTodoData('status', { status: status.value }, status.value == activeInfo.data.status)"
                    >
                      <!-- <span class="mr-50 bullet bullet-success bullet-sm" /> -->
                      <span
                        class="bullet mr-1 border filter-dot"
                        :style="{'background-color': status.color}"
                      />
                      <span class="align-text-bottom line-height-1">{{ status.title }}</span>
                      <!-- / {{ status.value }} -->
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <div
                  v-b-tooltip.hover.v-secondary
                  title="搜尋"
                  class="actions-link-btn-sm"
                  @click="openInputSearch"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="17"
                    class="align-middle text-body"
                  />
                </div>

                <div class="dropdown">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <div
                        v-b-tooltip.hover.v-secondary
                        title="更多"
                        class="actions-link-btn-sm"
                      >
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="17"
                          class="align-middle text-body"
                        />
                      </div>
                    </template>

                    <b-dropdown-item
                      v-if="!activeInfo.data.has_view"
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click.stop="updateTodoViewData('unread')"
                    >
                      <b-img
                        src="/admin/images/todo/action/hide.svg"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>標示為未讀</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="activeInfo.data.has_view"
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click.stop="updateTodoViewData('read')"
                    >
                      <b-img
                        src="/admin/images/todo/action/eye.svg"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>標示為已讀</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="shallShowActiveChatContactSidebar = true"
                    >
                      <b-img
                        src="/admin/images/todo/action/id-card.svg"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>待辦詳情</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="!isReview"
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="submitUpdateSidebar"
                    >
                      <b-img
                        src="/admin/images/todo/action/pen.svg"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>編輯</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="submitReviewTodo([activeInfo.data.id])"
                    >
                      <b-img
                        src="/admin/images/todo/action/correct.svg"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>複查</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="submitDeleteTodo([activeInfo.data.id])"
                    >
                      <b-img
                        src="/admin/images/todo/action/trash-bin.svg"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>刪除</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>

              <div
                class="chat-header-gotop"
                @click="scrollToTopInChatLog"
              >
                返回頂部
              </div>
            </header>

            <header
              v-if="selectedReamrk.length > 0"
              class="chat-header"
            >
              <div class="d-flex align-items-center">
                <b-button
                  variant="primary"
                  type="submit"
                  class="text-nowrap"
                  @click="$bvModal.show('remarkDeleteModal')"
                >
                  刪除 {{ selectedReamrk.length }}
                </b-button>
              </div>

              <div class="d-flex align-items-center">
                <b-button
                  variant="flat-primary"
                  type="submit"
                  class="text-nowrap"
                  @click="() => {
                    selectedReamrk = []
                  }"
                >
                  取消
                </b-button>
              </div>
            </header>
          </div>

          <div v-else>
            <header class="chat-header">

              <div class="d-flex align-items-center w-100">
                <b-input-group class="input-group-merge w-100">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    ref="inputSearch"
                    v-model="searchQuery"
                    placeholder="搜尋..."
                    debounce="500"
                    @input="inputRemarkSearch"
                  />
                  <b-input-group-append is-text>
                    <small class="text-muted">{{ searchIndex }} / {{ searchResults.length }}</small>
                  </b-input-group-append>
                </b-input-group>
              </div>

              <div class="text-nowrap d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <div
                    v-b-tooltip.hover.v-secondary
                    title="下一個結果"
                    class="actions-link-btn-sm ml-50"
                    @click="searchNextReult('next')"
                  >
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="24"
                      class="actions-link-btn-image"
                    />
                  </div>

                  <div
                    v-b-tooltip.hover.v-secondary
                    title="上一個結果"
                    class="actions-link-btn-sm ml-50"
                    @click="searchNextReult('pre')"
                  >
                    <feather-icon
                      icon="ChevronUpIcon"
                      size="24"
                      class="actions-link-btn-image"
                    />
                  </div>

                  <div
                    v-b-tooltip.hover.v-secondary
                    title="取消"
                    class="actions-link-btn-sm ml-50"
                    @click="cancelSearchReult"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="24"
                      class="actions-link-btn-image"
                    />
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>

        <!-- 備註 -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats"
          @ps-scroll-y="scrollHandle"
        >
          <todo-log
            ref="refChatLog"
            :todo-info="activeInfo"
            :todo-remark="activeRemark"
            :todo-input="chatInputMessage"
            :search-point="searchPoint"
            :reply-id="replyId"
            :selected-remark-list="selectedReamrk"
            :random-key="randomKey"
            :profile-user-data="profileUserData"
            :is-info-loading="activeInfoBusy"
            :is-remark-loading="activeRemarkBusy"
            :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
            @selected-remark-index="selectedRemarkIndex"
            @remove-remark-index="removeRemarkIndex"
            @create-remark-data="createMessage"
            @reply-remark-data="replyMessage"
            @update-remark-data="updateMessage"
            @update-remark-message="updateMessageText"
            @update-remark-append="updateMessageAppend"
            @delete-remark-data="deleteMessage"
            @emit-refresh-todo="openChatOfContact"
            @emit-update-sidebar="submitUpdateSidebar"
            @emit-update-busy="updateTodoBusy"
            @emit-update-todo-view="updateTodoViewData"
            @emit-scroll-remark-data="scrollToElement"
          />
          <!-- {{ activeRemark }} -->
        </vue-perfect-scrollbar>

        <div
          class="go-bottom"
          :class="{ 'go-bottom-show' : !isAtBottom}"
        >
          <div
            class="go-bottom-btn"
            @click="scrollToBottomInChatLog"
          >
            <feather-icon
              icon="ArrowDownIcon"
              size="24"
              class="gobottom-btn-icon"
            />
          </div>
        </div>

        <b-form
          class="chat-app-form"
          @submit.prevent
        >
          <div
            v-if="replyId && replyData"
            class="chat-app-form-reply"
          >
            <div class="d-flex align-items-center">
              <div class="mr-1">
                <b-img
                  src="/admin/images/todo/action/reply1.svg"
                  width="28"
                  rounded
                />
              </div>
              <div>
                <div>
                  <small class="text-primary">回覆 {{ replyData.user_info.name }}</small>
                </div>
                <div>{{ replyData.message }}</div>
              </div>
            </div>

            <div>
              <div
                v-b-tooltip.hover.v-secondary
                title="取消"
                class="actions-link-btn-sm ml-50"
                @click="() => replyId = null"
              >
                <feather-icon
                  icon="XIcon"
                  size="24"
                  class="actions-link-btn-image"
                />
              </div>
            </div>
          </div>
          <!-- randomIdArray: {{ randomIdArray }} -->

          <div class="chat-app-form-action">
            <label
              class="chat-app-form-icon mb-1"
            >
              <feather-icon
                icon="LinkIcon"
                size="20"
                class="append-icon"
              />
              <input
                ref="fileInput"
                type="file"
                multiple
                hidden
                @input="submitUploadFile($event)"
              >
            </label>

            <div class="chat-app-form-icon mb-75">
              <my-emoji-component
                @select-emoji="selectEmojiIcon"
              >
                <label
                  slot="button-content"
                  class="cursor-pointer"
                >
                  <feather-icon
                    icon="SmileIcon"
                    size="20"
                    class="emoji-icon"
                  />
                </label>
              </my-emoji-component>
            </div>

            <div class="w-100 mr-1">
              <textarea
                ref="chatInputMessageTextarea"
                v-model="chatInputMessage"
                class="w-100 site-input-area"
                :style="{
                  'color': !isDark ? '#000000' : '#d0d2d6',
                  'border-color': !isDark ? '#d0d2d6' : '#4b5c7c',
                }"
                :disabled="isRemarkBusy"
                placeholder="輸入備註..."
                @paste="handlePaste"
                @input="delayedFunction"
                @keydown="handleKeyDown"
              />
            </div>

            <div
              class="chat-app-form-icon mb-1"
              @click="sendMessage"
            >
              <feather-icon
                icon="SendIcon"
                size="20"
                class="send-icon text-primary"
              />
            </div>
          </div>
        </b-form>
      </div>

      <!-- 折線圖 -->
      <div
        v-if="!activeInfo.data && activeChartShow"
        class="line-chart-area"
      >
        <todo-line-chart />
      </div>
    </section>

    <todo-quick-base
      ref="todoQuickBase"
      @open-chat-contact="openChatOfContact"
      @refresh-todo-list="refreshTodoList"
    />

    <todo-active-content-details-sidedbar
      v-if="activeInfo.data"
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeInfo || {}"
      @submit-update-todo="() => {
        shallShowActiveChatContactSidebar = false
        submitUpdateSidebar()
      }"
      @update-refresh="updateRefresh"
      @emit-review-todo="submitReviewTodo"
    />

    <todo-active-update-sidedbar
      v-if="activeInfo.data"
      ref="updateSidedbar"
      :shall-show-active-chat-update-sidebar.sync="shallShowActiveChatUpdateSidebar"
      :contact="activeInfo || {}"
      @update-refresh="updateRefresh"
    />

    <portal to="content-renderer-sidebar-left">
      <todo-left-sidebar
        ref="todoLeftSidebar"
        :active-chat-contact-id="activeInfo.data ? activeInfo.data.id : null"
        :shall-todo-chart-show-state.sync="activeChartShow"
        :shall-open-todo-add-sidebar-state.sync="shallOpenTodoAddSidebarState"
        :shall-open-todo-setting-sidebar-state.sync="shallOpenTodoSettingSidebarState"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @change-active-chart-show="changeActiveChartShow"
        @open-quick-list="openTodoQuickListModal"
        @clear-active-info-data="clearActiveInfoData"
        @refresh-todo-info="refreshTodoInfo"
        @open-todo-add-sidebar="openTodoAddSidebar"
        @open-todo-setting-sidebar="openTodoSettingSidebar"
        @open-chat="openChatOfContact"
        @emit-review-todo="submitReviewTodo"
      />
    </portal>

    <!-- 刪除提示彈窗 -->
    <b-modal
      id="remarkDeleteModal"
      no-close-on-backdrop
      centered
      hide-header
    >
      <div class="p-1">
        <h4>你想要刪除 {{ selectedReamrk.length }} 則備註嗎?</h4>
      </div>

      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="flat-primary"
            @click="closeDeleteModal"
          >
            取消
          </b-button>

          <b-button
            variant="flat-primary"
            :disabled="isRemarkBusy"
            @click="confirmDeleteModal"
          >
            刪除
          </b-button>
        </div>
      </template>

    </b-modal>

    <audio
      ref="audio"
      src="/admin/audio/audio-2.mp3"
    />
  </div>
</template>

<script>
import {
  ref, onUnmounted, nextTick, computed,
} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BDropdown, BDropdownItem, BForm, BBadge, VBTooltip, BButton, BModal, BImg, BInputGroup, BFormInput,
  BInputGroupPrepend, BInputGroupAppend,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import TodoLeftSidebar from './TodoLeftSidebar.vue'
import useStoreModule from '../useStoreModule'
import {
  useTodoList, useTodoView, useRemarkList, useQuickList,
} from '../useTodo'
import TodoActiveContentDetailsSidedbar from './TodoActiveContentDetailsSidedbar.vue'
import TodoActiveUpdateSidedbar from './TodoUpdateSidebar.vue'
import TodoQuickBase from './TodoQuickBase.vue'
import TodoQuickList from './TodoQuickList.vue'
import TodoLog from './TodoLog.vue'
import TodoLineChart from './TodoLineChart.vue'
import store from '@/store'
import router from '@/router'
import { useAlert, useSwalToast } from '@/libs/mixins/index'
import { blankSocketData } from '@/libs/socket-client'
import MyEmojiComponent from '@/layouts/components/EmojiPicker/EmojiPicker.vue'

export default {
  components: {
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BForm,
    BModal,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BImg,

    VuePerfectScrollbar,

    TodoLeftSidebar,
    TodoActiveContentDetailsSidedbar,
    TodoActiveUpdateSidedbar,
    TodoLog,
    TodoQuickBase,
    TodoQuickList,
    TodoLineChart,
    MyEmojiComponent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      selectedReamrk: [],
      audioLock: false,
      searchMode: false,
      searchIndex: 0,
      searchResults: [],
      searchPoint: null,
      isAtBottom: false,
      isFirstClick: false,
    }
  },
  computed: {
    isSocketInit() {
      return this.$store.state.app.isSocketInitialized
    },

    isFirstListChg() {
      return this.$store.state['admin-todo'].firstListInfo
    },

    isFirstClickOpen() {
      return this.$store.state['admin-todo'].useAudioState
    },

    isReview() {
      return this.activeInfo.data && this.activeInfo.data.review_user_id
    },

    replyData() {
      if (this.activeRemark.length <= 0) return null
      const foundRemark = this.activeRemark.find(remark => remark.id === this.replyId)
      if (foundRemark) {
        const resolve = {
          id: foundRemark.id,
          message: this.resolveMegType(foundRemark.message, foundRemark.appends.length, foundRemark.body.images.length),
          user_id: foundRemark.user_id,
          user_info: foundRemark.user_info,
        }
        return resolve
      }

      return null
    },
  },
  watch: {
    isSocketInit(newValue) {
      if (newValue) {
        this.resetSocketConnect()
      }
    },

    isFirstListChg(newValue, oldValue) {
      if (!this.audioLock) {
        return
      }
      if (newValue.total === 0) {
        return
      }
      if (!oldValue.id) {
        return
      }
      if (this.activeInfo.data && this.activeInfo.data.id === newValue.id) {
        this.setTodoViewUpdate({
          type: 'read',
          data: {
            todo_id: [this.activeInfo.data.id],
          },
        })
        return
      }
      if ((newValue.id === oldValue.id) && (newValue.total <= oldValue.total)) {
        return
      }

      if (this.$store.state['admin-todo'].useAudioState) {
        this.$refs.audio.play()
          .catch(() => {
            this.useAlertToast(true, '您有一則新備註')
          })
      } else this.useAlertToast(true, '您有一則新備註')

      // this.$refs.audio.play()
      //   .catch(() => {
      //     // 显示某种形式的UI元素来让用户手动点击播放
      //     // console.error('播放失败，需要用户交互！', error)
      //     this.useAlertToast(true, '您有一則新備註')
      //   })
    },

    isFirstClickOpen(newValue) {
      if (newValue === null) return
      if (this.isFirstClick) return
      if (newValue) {
        this.$swal({
          title: '開始使用',
          text: '歡迎使用後台待辦系統提示音效',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-secondary ml-1',
          },
          buttonsStyling: false,
        })
      }
      this.isFirstClick = true
    },
  },
  mounted() {
    this.initSocketFunction()
    if (this.currentId) {
      this.activeInfoBusy = true
      this.getTodoInfoData({
        todo_id: this.currentId,
      })
        .then(async response => {
          this.activeInfo = {
            ...await this.resolveTodoInfoData(response),
            state: true,
          }
          if (!this.chatInputMessage) this.chatInputMessage = this.activeInfo.data.remark_message
          setTimeout(() => this.adjustTextareaHeight(), 200)

          this.activeInfoBusy = false
          if (this.activeRemarkBusy) return
          this.activeRemarkBusy = true
          this.selectedReamrk = []

          this.$store.dispatch('app/isSocketFunctionExist', 'joinTodoRoom')
            .then(state => {
              if (state) window.socket.joinTodoRoom(this.currentId)
            })

          if (this.activeInfo.data) setTimeout(() => { this.$refs.chatInputMessageTextarea.focus() }, 200)
          this.refreshRemarkList(this.currentId)
        })
        .catch(() => {
          // console.error('An error occurred:')
          this.activeInfo = {
            state: true,
          }
          this.activeInfoBusy = false
          this.$router.push({ name: 'admin-todo' })
        })
      this.mqShallShowLeftSidebar = false
    }
  },
  beforeDestroy() {
    const { blankTodo } = blankSocketData()
    window.web.todo = { ...blankTodo }
  },
  methods: {
    // (顯示)檔案字數
    resolveFileNameLong(text, length) {
      if (text.length > length * 2) {
        const start = text.slice(0, length)
        const end = text.slice(-length)
        return `${start}...${end}`
      }
      return text
    },

    // (判斷顯示模式)
    resolveMegType(msg, appends, images) {
      if (msg) {
        return this.resolveFileNameLong(msg, 20)
      }

      if (!msg) {
        if (images) return '回覆一則圖片備註'
        if (appends) return '回覆一則檔案備註'
      }
      return null
    },
    // -------------------------------------------------------------------------------------

    // (打開)輸入搜尋
    openInputSearch() {
      this.searchMode = true
      setTimeout(() => { this.$refs.inputSearch.focus() }, 200)
    },

    // (輸入)搜尋結果
    inputRemarkSearch() {
      clearTimeout(this.timeSearch)
      this.timeSearch = setTimeout(() => {
        if (!this.searchQuery) {
          this.searchIndex = 0
          this.searchResults = []
          this.scrollToBottomInChatLog()
          return
        }
        this.getTodoRemarkSearch({
          todo_id: this.activeInfo.data.id,
          queryParams: {
            search: this.searchQuery,
          },
        })
          .then(response => {
            const { data } = response.data.data
            if (data.length > 0) {
              this.searchResults = data.reverse()
              this.searchIndex = data.length
              this.searchPoint = data[data.length - 1].id
              this.scrollToElement(data[data.length - 1].id)
            } else {
              this.searchIndex = 0
              this.searchResults = []
            }
          })
      }, 1000)
    },

    // (搜尋)下一個結果
    searchNextReult(type) {
      const resultsNum = this.searchResults.length
      if (resultsNum === 0) return

      let newIndex = this.searchIndex

      // 计算新的索引位置
      if (type === 'next') {
        newIndex = (this.searchIndex + 1) % (resultsNum + 1)
      } else if (type === 'pre') {
        newIndex = ((this.searchIndex - 2 + resultsNum) % resultsNum) + 1
      }
      this.searchIndex = newIndex <= 0 ? 1 : newIndex

      const newData = parseInt(JSON.parse(JSON.stringify(this.searchIndex)), 10) - 1
      const nextId = this.searchResults[newData].id
      this.searchPoint = nextId
      this.scrollToElement(nextId)
    },

    // (移動至)搜尋結果
    scrollToElement(id) {
      const container = document.querySelector('.user-chats')
      const child = document.getElementById(`remark-${id}`)

      if (container && child) {
        container.scrollTop = child.offsetTop - container.offsetTop
        // child.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    },

    // (取消)搜尋結果
    cancelSearchReult() {
      this.searchIndex = 0
      this.searchQuery = null
      this.searchMode = false
      this.searchResults = []
      this.searchPoint = null
      if (this.activeInfo.data) setTimeout(() => { this.$refs.chatInputMessageTextarea.focus() }, 200)
    },

    // (滾軸)滾動監測
    scrollHandle() {
      if (this.$refs.refChatLogPS && this.$refs.refChatLog) {
        if (this.$refs.refChatLog.closeAction()) this.$refs.refChatLog.closeAction()
        const scrollEl = this.$refs.refChatLogPS.$el
        const { scrollTop, clientHeight, scrollHeight } = scrollEl
        this.isAtBottom = scrollTop + clientHeight + 1 >= scrollHeight
        // if (this.isAtBottom) {
        //   this.setTodoViewUpdate({
        //     type: 'read',
        //     data: {
        //       todo_id: [this.activeInfo.data.id],
        //     },
        //   })
        // }
      }
    },

    // -------------------------------------------------------------------------------------
    // (調整)備註檔案輸入欄位
    adjustTextareaHeight() {
      if (!this.$refs.chatInputMessageTextarea) return
      this.$nextTick(() => {
        const textarea = this.$refs.chatInputMessageTextarea
        textarea.style.height = '40px'
        if (textarea.scrollHeight < 100) {
          textarea.style.height = `${textarea.scrollHeight}px`
        } else {
          textarea.style.height = '100px'
        }
      })
    },

    // (選擇)備註
    selectedRemarkIndex(index) {
      this.cancelSearchReult()
      const indexInSelectedRemark = this.selectedReamrk.indexOf(index)
      if (indexInSelectedRemark !== -1) {
        this.selectedReamrk.splice(indexInSelectedRemark, 1)
      } else {
        this.selectedReamrk.push(index)
      }
    },

    // (移除)備註
    removeRemarkIndex(index) {
      this.activeInfo.data.remarks.splice(index, 1)
      this.scrollToBottomInChatLog()
    },

    // (觸發)關閉備註
    closeDeleteModal() {
      this.$bvModal.hide('remarkDeleteModal')
    },

    // (觸發)刪除備註
    confirmDeleteModal() {
      this.isRemarkBusy = true
      // if (this.rightClick.user_id !== this.profileUserData.id) return
      this.setTodoRemarkDelete({
        todo_id: this.activeInfo.data.id,
        type: 'muti',
        data: {
          remark_id: this.selectedReamrk,
        },
      })
        .then(() => {
          this.selectedReamrk = []
          this.isRemarkBusy = false
          this.closeDeleteModal()
          this.refreshRemarkList(this.activeInfo.data.id)
        })
        .catch(error => {
          this.isRemarkBusy = false
          this.useHttpCodeAlert(error.response)
        })
    },

    // (觸發)檔案上傳
    submitUploadFile(event) {
      if (event.target.files.length === 0) return
      this.$refs.refChatLog.importFile(event.target)
      this.$refs.fileInput.value = null
    },

    // (觸發)輸入欄位按鈕
    handleKeyDown(event) {
      if (this.chatInputMessage === '' && (event.key === 'ArrowUp' || event.keyCode === 38)) {
        if (!this.profileUserData.id) return
        const filteredRemarks = this.activeRemark.filter(user => user.user_id === this.profileUserData.id)
        if (filteredRemarks.length <= 0) return
        const lastRemark = filteredRemarks.pop()
        this.$refs.refChatLog.submitEditRemark(lastRemark)
      }
      if (event.shiftKey && event.key === 'Enter') {
        const cursorPosition = event.target.selectionStart
        this.chatInputMessage = `${this.chatInputMessage.slice(0, cursorPosition)}\n${this.chatInputMessage.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        this.$nextTick(() => {
          this.$refs.chatInputMessageTextarea.setSelectionRange(newCursorPosition, newCursorPosition)
        })
      }
      if (event.ctrlKey && event.key === 'Enter') {
        const cursorPosition = event.target.selectionStart
        this.chatInputMessage = `${this.chatInputMessage.slice(0, cursorPosition)}\n${this.chatInputMessage.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        this.$nextTick(() => {
          this.$refs.chatInputMessageTextarea.setSelectionRange(newCursorPosition, newCursorPosition)
        })
      }
      if (!event.shiftKey && event.key === 'Enter') {
        event.preventDefault()
        this.sendMessage()
      }
    },

    // (按下)Ctrl + V
    handlePaste() {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      navigator.clipboard.read()
        .then(clipboardItems => {
          clipboardItems.forEach(clipboardItem => {
            const { types } = clipboardItem
            if (types.length === 1) {
              const type = types[0]
              if (type === 'image/png' || type === 'image/jpeg') {
                clipboardItem.getType(type).then(blob => {
                  const file = new File([blob], 'pasted_image.png', { type: blob.type })
                  this.$refs.refChatLog.importFile({ files: [file] })
                })
              }
            }
            if (types.length === 2) {
              if (types.includes('text/html') && types.includes('image/png')) {
                const type = types[1]
                clipboardItem.getType(type).then(blob => {
                  const file = new File([blob], 'pasted_html_image.png', { type: blob.type })
                  this.$refs.refChatLog.importFile({ files: [file] })
                })
              }
            }
          })
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (新增)EMOJI
    selectEmojiIcon(emoji) {
      const textareaRef = this.$refs.chatInputMessageTextarea
      const cursorPosition = textareaRef.selectionStart
      this.chatInputMessage = `${this.chatInputMessage.slice(0, cursorPosition)}${emoji}${this.chatInputMessage.slice(cursorPosition)}`
      const newCursorPosition = cursorPosition + emoji.length
      this.$nextTick(() => {
        textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
      })
    },

    // (延遲)輸入快取
    delayedFunction() {
      this.adjustTextareaHeight()
      clearTimeout(this.timeCache)
      this.timeCache = setTimeout(() => this.handleSaveInputCache(this.chatInputMessage), 2000)
    },

    // -------------------------------------------------------------------------------------
    // (開啟)快速新增
    // openTodoQuickAddModal() {
    //   // this.$refs.todoQuickAdd.handleShowSiteModal()

    //   const blankContentData = {
    //     group_id: null,
    //     admin_brand_id: null,
    //     department_id: null,
    //     title: null,
    //     type_id: null,
    //     execution_type_id: null,
    //     status: 'not-completed',
    //     priority: 50,
    //     client_name: null,
    //     display_status: true,
    //     scheduled_at: null, // formatDate(new Date()),
    //     deadline_at: null,
    //   }

    //   const blankContentSetting = {
    //     show_title: false,
    //     show_client_name: false,
    //     show_priority: false,
    //     show_group_id: false,
    //     show_scheduled_at: false,
    //     set_scheduled_at: '1h',
    //     show_deadline_at: false,
    //     set_deadline_at: '1h',
    //   }

    //   const emptyData = {
    //     id: null,
    //     name: '快速新增',
    //     order: 0,
    //     content: {
    //       data: {
    //         ...blankContentData,
    //       },
    //       setting: {
    //         ...blankContentSetting,
    //       },
    //     },
    //   }
    //   this.$refs.todoQuickBase.handleShowSiteModal(emptyData)
    // },
    openTodoQuickBaseModal(quick) {
      if (quick) this.$refs.todoQuickBase.handleShowSiteModal(quick)
      else {
        const emptyData = {
          id: null,
          name: '快速新增',
          order: 0,
          content: {
            data: {
              ...this.blankContentData,
            },
            setting: {
              ...this.blankContentSetting,
            },
          },
        }
        this.$refs.todoQuickBase.handleShowSiteModal(emptyData)
      }
    },

    // (觸發)更新側欄
    submitUpdateSidebar() {
      this.shallShowActiveChatUpdateSidebar = true
      this.$refs.updateSidedbar.getData()
    },

    // (更新)忙碌狀態
    updateTodoBusy(state) {
      this.activeInfoBusy = state
    },
    // activeInfo

    // (更新)待辦狀態/優先序
    updateTodoData(type, todoData, state) {
      if (state) return
      // this.activeInfoBusy = true
      this.setTodoUpdate({
        todo_id: this.activeInfo.data.id,
        data: {
          ...todoData,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.updateRefresh(this.activeInfo.data.id)
          if (type === 'status' && todoData[type] === 'completed') {
            this.setTodoComplete({
              data: {
                todo_id: [this.activeInfo.data.id],
              },
            })
          }
          // this.refreshTodoList()
          // this.activeInfoBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          // this.activeInfoBusy = false
        })
    },

    // (更新)標示為未讀
    updateTodoViewData(type) {
      this.setTodoViewUpdate({
        type,
        data: {
          todo_id: [this.activeInfo.data.id],
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.activeInfo.data.has_view = !this.activeInfo.data.has_view
          // this.openChatOfContact(this.activeInfo.data.id)
          this.refreshTodoList()
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
        })
    },

    // (觸發)複查
    submitReviewTodo(todoIdArr) {
      if (todoIdArr.length <= 0) return

      const [todoId] = todoIdArr

      this.useSwalAlertInfo('複查待辦', todoIdArr.length > 1 || !this.activeInfo.data ? `你確定要複查所選 ${todoIdArr.length}個待辦 嗎?` : `你確定要複查待辦 ${this.activeInfo.data.title ? this.activeInfo.data.title : '未命名標題'} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setTodoReview({
              data: {
                todo_id: todoIdArr,
              },
            })
              .then(() => {
                if (todoIdArr.length === 1) {
                  if (todoId === this.activeInfo.data.id) this.refreshTodoInfo(todoId)
                }
                this.useSwalAlertCenter(true, '複查成功!', '待辦已複查')
                this.refreshTodoList()
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '複查失敗!', error.response.data.message)
              })
          }
        })
    },

    // (刪除)待辦
    submitDeleteTodo(todoIdArr) {
      if (todoIdArr.length <= 0) return

      const [todoId] = todoIdArr

      this.useSwalAlertWarning('刪除待辦', todoIdArr.length > 1 ? `你確定要永久刪除所選 ${todoIdArr.length}個待辦 嗎?` : `你確定要永久刪除待辦 ${this.activeInfo.data.title ? this.activeInfo.data.title : '未命名標題'} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setTodoDelete({
              data: {
                todo_id: todoIdArr,
              },
            })
              .then(() => {
                if (todoIdArr.length === 1) {
                  if (todoId === this.activeInfo.data.id) this.clearActiveInfoData()
                }
                this.refreshTodoList()
                this.useSwalAlertCenter(true, '刪除成功!', '待辦已移除')
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },

    // (清除)當前
    clearActiveInfoData() {
      this.activeInfo = {
        state: true,
      }
      this.activeInfoBusy = false
    },

    // -------------------------------------------------------------------------------------
    // (開啟)快速新增列表
    openTodoQuickListModal() {
      this.$refs.todoQuickList.handleShowListModal()
    },

    // (切換)排行榜
    changeActiveChartShow() {
      if (this.activeInfo.data) {
        this.activeInfo = {}
        this.$router.push({ name: 'admin-todo' })
        this.activeChartShow = true
      } else {
        this.activeChartShow = !this.activeChartShow
      }
    },

    // --------------------------------------重整------------------------------------------
    // 獲取待辦詳情
    openChatOfContact(todoId, todo) { // , toDown
      if (this.activeInfo.data !== undefined) {
        if (this.activeInfo.data.id === todoId) {
          this.$refs.todoLeftSidebar.submitUpdateTodoView('read', [todoId])
          this.mqShallShowLeftSidebar = false
          return
        }
        // window.socket.leaveTodoRoom(todoId)
      }

      if (todo === undefined && this.activeInfo.state !== undefined && this.activeInfo.state === false) return
      // !todo && (

      if (todo) {
        const responseData = {
          ...todo,
          assigned_user: [],
          appends: [],
          display_status: true,
          remark_message: '',
          serialNumber: '',
          show_user: { array: [], number: 0 },
        }
        this.activeInfo.data = responseData
        this.activeInfo = {
          data: {
            ...responseData,
          },
          state: false,
        }
        if (this.$refs.refChatLog) this.$refs.refChatLog.editCol = ''
      }

      this.chatInputMessage = ''

      this.refreshTodoInfo(todoId)
      if (this.activeRemarkBusy) return
      this.activeRemarkBusy = true
      this.selectedReamrk = []
      this.cancelSearchReult()
      this.refreshRemarkList(todoId)
      this.activeChartShow = false

      this.$store.dispatch('app/isSocketFunctionExist', 'joinTodoRoom')
        .then(state => {
          if (state) window.socket.joinTodoRoom(todoId) // resolveResponse.id
        })
      if (this.activeInfo.data) setTimeout(() => { this.$refs.chatInputMessageTextarea.focus() }, 200)
    },

    // (重整)更新待辦
    updateRefresh(todoId) {
      this.refreshTodoList()
      this.refreshTodoInfo(todoId)
    },

    // (重整)待辦列表
    refreshTodoList(lock) {
      this.audioLock = lock || false
      this.$refs.todoLeftSidebar.refreshTodoListPageData()
    },

    // (重整)待辦詳情
    refreshTodoInfo(todoId) {
      if (this.activeInfoBusy) return
      // if (!this.activeInfo.data) return
      this.activeInfoBusy = true
      this.getTodoInfoData({
        todo_id: todoId,
      })
        .then(async response => {
          this.activeInfo = {
            ...await this.resolveTodoInfoData(response),
            state: true,
          }
          if (!this.chatInputMessage) this.chatInputMessage = this.activeInfo.data.remark_message
          setTimeout(() => this.adjustTextareaHeight(), 200)
          this.activeInfoBusy = false
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            this.activeInfo = {
              state: true,
            }
            this.activeInfoBusy = false
            return
          }
          this.useHttpCodeAlert(error.response)
          this.activeInfo = {}
          this.activeInfoBusy = false
        })
      this.mqShallShowLeftSidebar = false
    },

    // (重整)備註列表
    refreshRemarkList(todoId) {
      // if (this.activeRemarkBusy) return
      // this.activeRemarkBusy = true

      this.getTodoRemarkList({
        todo_id: todoId,
      })
        .then(response => {
          this.activeRemark = this.resolveRemarkListData(response)
          const userData = response.data.data.user_data
          this.profileUserData = {
            id: userData.id,
            name: userData.name,
            image: null,
          }
          // this.chatInputMessage = this.activeInfo.data.remark_message
          this.activeRemarkBusy = false

          // if (toDown)
          nextTick(() => {
            this.scrollToBottomInChatLog()
            // setTimeout(() => { this.activeRemarkBusy = false }, 1000)
          })
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            this.activeRemark = []
            this.activeRemarkBusy = false
            return
          }
          this.useHttpCodeAlert(error.response)
        })
      // const resolveData = { remarks: [] }
    },

    // (新增)備註
    createRemarkData(remark) {
      const resolveData = this.resolveRemarkInfoData({
        rid: null,
        ...remark,
      })
      const hasId = this.activeRemark.some(item => item.id === resolveData.id)
      if (!hasId) this.activeRemark.push(resolveData)
    },

    // (初始化)Socket
    initSocketFunction() {
      const windowFunction = window.web
      windowFunction.todo = {
        refreshRemarkList: id => {
          if (this.activeInfo.data && this.activeInfo.data.id === id) this.refreshRemarkList(id)
        },
        refreshTodoInfo: id => {
          if (this.activeInfo.data && this.activeInfo.data.id === id) this.refreshTodoInfo(id)
        },
        refreshTodoList: audio => {
          this.refreshTodoList(audio)
        },
        createRemark: remark => {
          if (!remark) return
          if (this.activeInfo.data && this.activeInfo.data.id === remark.todo_id) this.createRemarkData(remark.remark_data)
        },
      }
      if (this.isSocketInit) store.commit('app/SET_SOCKET_INIT', false)
      this.$store.dispatch('app/isSocketFunctionExist', 'joinTodo')
        .then(state => {
          if (state) window.socket.joinTodo()
        })
    },

    // (重建)Socket連線
    resetSocketConnect() {
      if (this.activeInfo.data) {
        this.$store.dispatch('app/isSocketFunctionExist', 'joinTodoRoom')
          .then(state => {
            if (state) window.socket.joinTodoRoom(this.activeInfo.data.id)
          })
      }
      store.commit('app/SET_SOCKET_INIT', false)
    },
  },
  setup() {
    // 註冊模組
    const TODO_ADMIN_STORE_MODULE_NAME = 'admin-todo'

    if (!store.hasModule(TODO_ADMIN_STORE_MODULE_NAME)) store.registerModule(TODO_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(TODO_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(TODO_ADMIN_STORE_MODULE_NAME)
    })

    const currentId = router.currentRoute.params.id
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const refChatLog = ref(null)
    const chatInputMessageTextarea = ref(null)
    const todoLeftSidebar = ref(null)
    const searchQuery = ref(null)
    const replyId = ref(null)

    const activeInfo = ref({})
    const activeRemark = ref([])
    const activeChartShow = ref(false)
    const activeInfoBusy = ref(false)
    const activeRemarkBusy = ref(false)
    const profileUserData = ref({})

    const chatInputMessage = ref('')

    const {
      blankContentSetting,
      blankContentData,
      blankConfigData,
    } = useQuickList()

    const {
      setTodoUpdate,
      setTodoDelete,
      setTodoViewUpdate,
      setTodoCache,
      setTodoComplete,
      setTodoReview,
    } = useTodoList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      getTodoInfoData,
      resolveTodoInfoData,
      resolveTodoViewData,
    } = useTodoView()

    const {
      randomKey,
      randomIdArray,
      isRemarkBusy,
      getTodoRemarkList,
      getTodoRemarkSearch,
      resolveRemarkListData,
      resolveRemarkInfoData,
      generateRandomString,
      removeRandomIdArray,

      setTodoRemarkDelete,
    } = useRemarkList()

    const scrollToBottomInChatLog = () => {
      if (!activeInfo.value.data) return
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
      if (scrollEl) {
        const options = {
          behavior: 'smooth',
          block: 'end',
          inline: 'end',
        }
        scrollEl.scrollIntoView(options)
      }
    }

    const scrollToTopInChatLog = () => {
      if (!activeInfo.value.data) return
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = 0
      if (scrollEl) {
        const options = {
          behavior: 'smooth',
          block: 'end',
          inline: 'end',
        }
        scrollEl.scrollIntoView(options)
      }
    }

    // (暫存)輸入快取(V)
    const handleSaveInputCache = message => {
      setTodoCache({
        todo_id: activeInfo.value.data.id,
        data: {
          content: message, // this.chatInputMessage,
        },
      })
    }

    // (送出)文字預覽(V)
    const sendMessage = () => {
      if (!chatInputMessage.value) return
      if (isRemarkBusy.value) return
      const rId = generateRandomString()
      const resolveData = resolveRemarkInfoData({
        rid: rId,
        message: chatInputMessage.value,
        user_id: profileUserData.value.id ? profileUserData.value.id : null,
        user_info: profileUserData.value,
        created_at: new Date(),
      })
      activeRemark.value.push(resolveData)
      const payload = {
        todo_id: activeInfo.value.data.id,
        data: {
          message: chatInputMessage.value,
          reply_id: replyId.value,
        },
      }
      isRemarkBusy.value = true
      store.dispatch('admin-todo/setTodoRemarkCreate', payload)
        .then(response => {
          const { data } = response.data
          const resolveResponse = resolveRemarkInfoData({ ...data })
          resolveResponse.user_info.name = profileUserData.value.name
          // resolveResponse.rid = null
          const index = activeRemark.value.findIndex(item => item.rid === rId)

          if (index !== -1) {
            // activeRemark.value[index] = resolveResponse
            activeRemark.value.splice(index, 1, resolveResponse)
            removeRandomIdArray(rId)

            store.dispatch('app/isSocketFunctionExist', 'createRemarkSelf')
              .then(state => {
                if (state) {
                  window.socket.createRemarkSelf({
                    todo_id: activeInfo.value.data.id,
                    remark_data: {
                      ...resolveResponse,
                    },
                  })
                }
              })
            // resolveResponse.id
            // randomIdArray.value = randomIdArray.value.filter(item => item !== rId)
          }

          isRemarkBusy.value = false
          nextTick(() => {
            scrollToBottomInChatLog()
            chatInputMessage.value = ''
            replyId.value = null
            handleSaveInputCache(null)
            chatInputMessageTextarea.value.focus()
            chatInputMessageTextarea.value.style.height = '40px'
            todoLeftSidebar.value.refreshTodoListPageData()
          })
        })
        .catch(error => {
          const index = activeRemark.value.findIndex(item => item.rid === rId)
          if (index !== -1) {
            activeRemark.value.splice(index, 1)
            removeRandomIdArray(rId)
          }
          isRemarkBusy.value = false
          useHttpCodeAlert(error.response)
        })
    }

    // (回覆)備註
    const replyMessage = id => {
      if (!id) return
      replyId.value = id
      if (activeInfo.value.data) setTimeout(() => { chatInputMessageTextarea.value.focus() }, 200)
    }

    // (送出)檔案預覽
    const createMessage = upload => {
      const rId = generateRandomString()
      const resolveData = resolveRemarkInfoData({
        rid: rId,
        ...upload,
        created_at: new Date(),
      })
      randomKey.value = rId
      activeRemark.value.push(resolveData)
      nextTick(() => {
        scrollToBottomInChatLog()
      })
    }

    // (更新)檔案預覽
    const updateMessage = (rId, response) => {
      const index = activeRemark.value.findIndex(item => item.rid === rId)
      if (index !== -1) {
        const newArray = [...activeRemark.value]
        newArray[index] = response
        activeRemark.value = newArray
        removeRandomIdArray(rId)
      }
      nextTick(() => {
        scrollToBottomInChatLog()
        chatInputMessage.value = ''
        replyId.value = null
        handleSaveInputCache(null)
        todoLeftSidebar.value.refreshTodoListPageData()
      })
    }

    // (更新)檔案預覽
    const updateMessageAppend = (rId, appendRId, response, remarkData) => { // , response
      const index = activeRemark.value.findIndex(item => item.rid === rId)
      if (index !== -1) {
        const newArray = [...activeRemark.value]
        const newArrayAppends = newArray[index].appends
        const appendIndex = newArrayAppends.findIndex(item => item.rid === appendRId)
        if (appendIndex !== -1) {
          newArrayAppends[appendIndex] = response
          newArrayAppends[appendIndex].rid = null
          newArrayAppends[appendIndex].file = null
          refChatLog.value.removeRandomIdArray(appendRId)
        }
        newArray[index].appends = newArrayAppends
        activeRemark.value = newArray
        if (newArrayAppends.filter(item => item.rid).length === 0) {
          removeRandomIdArray(rId)
          activeRemark.value[index].rid = null
          const resolveRemarkData = {
            ...remarkData,
          }
          resolveRemarkData.user_info.name = profileUserData.value.name

          this.$store.dispatch('app/isSocketFunctionExist', 'createRemarkSelf')
            .then(state => {
              if (state) {
                window.socket.createRemarkSelf({
                  todo_id: activeInfo.value.data.id,
                  remark_data: {
                    ...resolveRemarkData,
                  },
                })
              }
            })
        }
      }
    }

    // (更新)文字預覽((edit, real, error) / remarkId / 回傳資料)(V)
    const updateMessageText = (state, id, response) => {
      const index = activeRemark.value.findIndex(item => item.id === id)
      if (index !== -1) {
        const newArray = [...activeRemark.value]
        if (!state) {
          newArray[index].message = response
          activeRemark.value = newArray
          return
        }

        if (state === 'edit') {
          newArray[index].message = response
          activeRemark.value = newArray
          return
        }

        if (state === 'real') {
          newArray[index] = response
          activeRemark.value = newArray
          return
        }

        if (state === 'error') {
          newArray[index].message = response.message
          activeRemark.value = newArray
          chatInputMessage.value = response.input
          useHttpCodeAlert(response.error)
        }
      }
    }

    // (刪除)檔案預覽
    const deleteMessage = rId => {
      const index = activeRemark.value.findIndex(item => item.rid === rId)
      if (index !== -1) {
        activeRemark.value.splice(index, 1)
      }
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const shallOpenTodoAddSidebarState = ref(false)
    const shallOpenTodoSettingSidebarState = ref(false)

    const openTodoAddSidebar = () => {
      shallOpenTodoAddSidebarState.value = true
    }

    const openTodoSettingSidebar = () => {
      shallOpenTodoSettingSidebarState.value = true
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)
    const shallShowActiveChatUpdateSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }
    // ----------------------------------------------------------------------------------------------

    return {
      isDark,
      blankContentSetting,
      blankContentData,
      blankConfigData,

      currentId,
      refChatLog,
      refChatLogPS,
      isRemarkBusy,
      todoLeftSidebar,
      chatInputMessageTextarea,
      searchQuery,
      replyId,

      activeInfo,
      activeRemark,
      activeChartShow,
      activeInfoBusy,
      activeRemarkBusy,
      profileUserData,
      chatInputMessage,
      handleSaveInputCache,
      sendMessage,
      createMessage,
      replyMessage,
      updateMessage,
      updateMessageAppend,
      updateMessageText,
      deleteMessage,
      getTodoRemarkList,
      getTodoRemarkSearch,
      resolveRemarkListData,
      resolveRemarkInfoData,
      getTodoInfoData,
      resolveTodoInfoData,
      resolveTodoViewData,
      setTodoRemarkDelete,

      shallOpenTodoAddSidebarState,
      shallOpenTodoSettingSidebarState,
      openTodoAddSidebar,
      openTodoSettingSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,
      shallShowActiveChatUpdateSidebar,

      // UI
      perfectScrollbarSettings,
      scrollToBottomInChatLog,
      scrollToTopInChatLog,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,

      // -------------------
      setTodoUpdate,
      setTodoDelete,
      setTodoViewUpdate,
      setTodoCache,
      setTodoComplete,
      setTodoReview,

      useAlertToast,
      useHttpCodeAlert,

      randomIdArray,
      randomKey,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-subject {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.site-input-area {
  border: none;
  background: transparent;
  border-radius: 0;
  resize: none;
  height: 30px;
  margin-top: 15px;
  color: #d0d2d6;
  padding: 10px;
  // margin-bottom: -5px;
  border: 1px #d0d2d6 solid;
  border-radius: 5px;
  &:focus {
    box-shadow: none;
    border-color: #719ECE;
  }
  &:focus-visible {
    outline: none;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
}
.filter-item {
  transition: transform 0.3s ease-in-out;
  .filter-dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    // align-self: center;
    // padding: 2px;
    margin-right: 5px;
  }

  &:hover {
    cursor: pointer;
    .filter-dot {
      transform: scale(1.2);
      background-color: #24c9ff !important;
      box-shadow: 0 0 10px #24c8ffa3;
      border: none !important;
      // filter: hue-rotate(120deg);
    }
  }
}

.chat-app-form-icon {
  // background-color: aqua;
  // align-self: center;
  text-wrap: nowrap;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  .append-icon {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }

  .emoji-icon {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }

  .send-icon {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }
}

.go-bottom {
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease;
  .go-bottom-btn {
    background-color: rgba(80, 80, 80, 0.398);
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    &:hover {
      background-color: rgba(119, 119, 119, 0.633);
    }
    .gobottom-btn-icon {
      color: white;
      font-weight: bolder;
    }
  }
}

.go-bottom-show {
  opacity: 1;
}

.chat-header {
  position: relative;
  .chat-header-gotop {
    position: absolute;
    z-index: 3;
    // bottom: -100%;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba(125, 124, 124, 0.105);
    color: rgb(255, 255, 255);
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s ease;
    padding: 5px;
    &:hover {
      cursor: pointer;
      background-color: rgba(80, 80, 80, 0.398);
      opacity: 1;
    }
  }
}

.start-chat-area {
  position: relative;
  .quick-create-area {
    pointer-events: visiblePainted;
    position: absolute;
    background-color: #24c8ff1c;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .quick-create-icon {
    position: absolute;
    cursor: pointer;
    bottom: 10px;
    right: 10px;
    .icon-link-btn {
      .icon-link-btn-image {
        height: 40px;
        width: 40px;
        &:hover {
          animation: rotateAnimation 3s linear infinite;
        }
      }
    }
  }

  .start-chat-label {
    // background-color: red;
    // pointer-events: visiblePainted;
    position: absolute;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .start-chat-text {
      &:hover {
        background-color: #419fd9 !important;
        color: white !important;
      }
    }
  }
}

.line-chart-area {
  position: relative;
  overflow-y: scroll;
  padding: 20px;
  // background-color: #88c7db;
  // .line-chart-create-area {
  //   // background-color: #24c8ff;
  //   // padding: 20px;
  // }
}

.quick-area {
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  // display: flex;
  // align-items: center;
  .dropdown-item-btn-image {
    width: 14px;
    height: 14px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-todo-dropdown .8s linear infinite;
    }
  }
}

@keyframes btn-breathe-todo-dropdown {
  0%{ transform: scale(0.8); }
  50%{ transform: scale(1.2); }
  100%{ transform: scale(0.8); }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}

// .chat-app {
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   left: 0;
// }

</style>

<style lang="scss">
@import "~@core/scss/base/my/app-chat.scss";
@import "~@core/scss/base/my/app-chat-list.scss";
</style>
