<template>
  <div>
    <b-modal
      id="todoSearchModal"
      no-close-on-backdrop
      centered
      header-bg-variant="primary"
      body-class="p-0"
      @hidden="handleHideListModal"
      @close="handleHideListModal"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          篩選自定義
        </h4>
      </template>

      <div class="list-modal">
        <div class="text-nowrap d-flex justify-content-end align-items-center m-1">
          <div
            v-b-tooltip.hover.focus.v-secondary
            title="新增"
            class="d-flex align-items-center actions-link-btn"
            @click="onSubmitUpdate(null, {})"
          >
            <b-img
              src="/admin/images/table/plus.svg"
              class="actions-link-btn-image"
              rounded
            />
          </div>
        </div>

        <div>
          <b-table
            ref="refDataListTable"
            :items="tableData"
            :fields="tableColumns"
            responsive
            striped
            hover
            primary-key="id"
            show-empty
            :busy="isTableBusy"
            class="m-0 position-relative"
            :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <!-- 忙碌中 -->
            <template #table-busy>
              <b-skeleton-table
                :rows="5"
                :columns="tableColumns.length"
                :table-props="{ bordered: true, striped: true }"
              />
            </template>

            <!-- 查無資料 -->
            <template #empty>
              <div class="text-center my-5 animate__animated animate__fadeIn">
                <b-img
                  :src="$store.state.app.notFoundImg"
                  fluid
                  width="480"
                  alt="查無資料"
                />
              </div>
            </template>

            <!-- 欄位: 編號 -->
            <template #cell(id)="data">
              <div style="min-width: 40px">
                <span>{{ data.index + 1 }}</span>
              </div>
            </template>

            <!-- 欄位: 名稱 -->
            <template #cell(name)="data">
              <div class="table-title">
                <b-link
                  class="font-weight-bold d-block text-nowrap mb-0"
                  @click="onSubmitUpdate(data.index, data.item)"
                >
                  <div class="item-description">
                    {{ data.item.name }}
                  </div>
                </b-link>
                <small class="text-muted">{{ data.item.account }}</small>
              </div>
            </template>

            <!-- 欄位: 動作 -->
            <template #cell(actions)="data">
              <div class="d-flex mb-50">
                <div
                  class="actions-link-btn-sm mr-25"
                  @click="onSubmitUpdate(data.index, data.item)"
                >
                  <b-img
                    v-b-tooltip.hover.v-secondary
                    title="編輯"
                    src="/admin/images/table/edit.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  class="actions-link-btn-sm mr-25"
                  @click="onSubmitDelete(data.index, data.item)"
                >
                  <b-img
                    v-b-tooltip.hover.v-secondary
                    title="刪除"
                    src="/admin/images/table/delete.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            @click="handleHideListModal"
          >
            確認
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="todoSearchUpdateModal"
      no-close-on-backdrop
      centered
      size="lg"
      @hidden="handleHideSearchUpdateModal"
      @close="handleHideSearchUpdateModal"
    >
      <template #modal-title>
        <h4 class="m-0">
          自定義功能
        </h4>
      </template>

      <div>
        <div v-if="!isLoadingBusy && configData">
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <!-- 自定義名稱 -->
              <b-form-group label-for="name">
                <template #label>
                  <label class="mb-0 pb-0">自定義名稱</label>
                </template>
                <b-form-input
                  id="name"
                  v-model="configData.name"
                  trim
                  placeholder="請輸入自定義名稱"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <!-- 圖片 -->
              <b-form-group label-for="image">
                <template #label>
                  <label class="mb-0 pb-0">圖片</label>
                </template>
                <b-form-input
                  id="image"
                  v-model="configData.image"
                  trim
                  placeholder="請輸入圖片網址"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <div>
            <h5 class="text-body mb-0">
              篩選設定
            </h5>
          </div>

          <div>
            <b-row>
              <b-col
                cols="12"
                sm="6"
              >
                <!-- 顯示內容 -->
                <b-form-group>
                  <div class="d-flex justify-content-between align-items-center mt-50">
                    <div class="d-flex align-items-center">
                      <label class="mb-0">顯示內容</label>

                      <span>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="設定為當前，不顯示排定代辦"
                          class="d-flex align-items-center"
                          style="padding: 0 5px;"
                        >
                          <b-img
                            src="/admin/images/common/info.svg"
                            class="actions-link-btn-image cursor-pointer"
                            rounded
                            style=" width: 14px; height: 14px;"
                          />
                        </div>
                      </span>
                    </div>

                    <toggle-button
                      v-model="configData.searchDisplay"
                      :color="{checked: '#4683bb', unchecked: '#20c997'}"
                      :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                      :width="60"
                      :height="24"
                      class="m-25"
                      :labels="{checked: '全部', unchecked: '當前'}"
                      :sync="true"
                    />
                  </div>
                </b-form-group>

                <!-- 搜尋內容 -->
                <b-form-group>
                  <label>搜尋內容</label>
                  <b-form-input
                    id="searchQuery"
                    v-model="configData.searchQuery"
                    trim
                    placeholder="客戶名稱、#id、待辦名稱"
                  />
                </b-form-group>

                <!-- 排序內容 -->
                <b-form-group>
                  <label>排序內容</label>
                  <v-select
                    v-model="configData.sortBy"
                    :options="searchOptions"
                    :reduce="option => option.value"
                    :clearable="false"
                    placeholder="請選擇排序內容"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <!-- 狀態 -->
                <b-form-group>
                  <label>狀態</label>

                  <v-select
                    v-model="configData.searchStatus"
                    :options="todoStatusOptions"
                    label="title"
                    multiple
                    :reduce="option => option.value"
                    placeholder="請選擇狀態"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>

                    <template v-slot:option="option">
                      <div class="d-flex align-items-center">
                        <span
                          class="bullet mr-1 border filter-dot"
                          :style="{'background-color': option.color}"
                        />
                        {{ option.title }}
                        <!-- ( {{ option.value }} ) -->
                      </div>
                    </template>

                    <template #selected-option="option">
                      <div class="d-flex align-items-center">
                        <span
                          class="bullet mr-1 border filter-dot"
                          :style="{'background-color': option.color}"
                        />
                        {{ option.title }}
                        <!-- ( {{ option.value }} ) -->
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <!-- 優先程度 -->
                <b-form-group>
                  <label>優先程度</label>

                  <v-select
                    v-model="configData.searchPriority"
                    :options="priorityOptions"
                    label="title"
                    :reduce="option => option.value"
                    placeholder="請選擇優先程度"
                    class="w-100"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>

                    <template v-slot:option="option">
                      <div class="d-flex align-items-center">
                        <span
                          class="bullet mr-1 border filter-dot"
                          :style="{'background-color': option.color}"
                        />
                        {{ option.title }} ( {{ option.sign }} )
                      </div>
                    </template>

                    <template #selected-option="option">
                      <div class="d-flex align-items-center">
                        <span
                          class="bullet mr-1 border filter-dot"
                          :style="{'background-color': option.color}"
                        />
                        {{ option.title }} ( {{ option.sign }} )
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                sm="6"
              >
                <!-- 只顯示自己 -->
                <b-form-group>
                  <div class="d-flex justify-content-between align-items-center mt-50">
                    <label class="mb-0">只顯示自己</label>

                    <toggle-button
                      v-model="configData.searchDisplayself"
                      :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                      :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                      :width="60"
                      :height="24"
                      class="m-25"
                      :labels="{checked: '開啟', unchecked: '關閉'}"
                      :sync="true"
                    />
                  </div>
                </b-form-group>

                <!-- 負責品牌 -->
                <b-form-group>
                  <label>負責品牌</label>

                  <v-select
                    v-model="configData.searchAdminBrand"
                    :options="adminBrandOptions"
                    label="name"
                    :reduce="option => option.id"
                    placeholder="請選擇品牌"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <!-- 負責部門 -->
                <b-form-group>
                  <label>負責部門</label>
                  <v-select
                    v-model="configData.searchDepartment"
                    :options="departmentsOptions"
                    label="name"
                    :reduce="option => option.id"
                    placeholder="請選擇負責部門"
                    @input="() => {
                      filterTodoTypeOptions(configData.searchDepartment)
                      filterTodoExecutionTypeOptions(configData.searchDepartment)
                    }"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <!-- 作業分類 -->
                <b-form-group>
                  <label>作業分類</label>
                  <v-select
                    v-model="configData.searchType"
                    :options="typeOptions"
                    label="name"
                    :reduce="option => option.id"
                    placeholder="請選擇作業分類"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <!-- 項目設定 -->
                <b-form-group>
                  <label>項目設定</label>
                  <v-select
                    v-model="configData.searchExecutionType"
                    :options="executionTypeOptions"
                    label="name"
                    :reduce="option => option.id"
                    placeholder="請選擇項目設定"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>

          </div>

        </div>

        <div
          v-else
          class="loading-area"
        >
          <b-img
            src="/admin/images/common/loading-2.png"
            rounded
            height="60"
            width="60"
          />
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            :disabled="isUpdateBusy"
            @click="handleOk"
          >
            <!--  || (botInfo.id === null && selectBotData.id === null) -->
            <span v-if="!isUpdateBusy">確認</span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BButton, VBTooltip, BImg, BTable, BSkeletonTable, BSpinner, BLink, BFormInput, BFormGroup,
  BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ToggleButton } from 'vue-js-toggle-button'
import { useTodoSetting, useSearchList, useTodoList } from '../useTodo'
import { useAlert, useSwalToast } from '@/libs/mixins/index'
// import DateTimeRangeCustomize from '@/layouts/components/Time/DateTimeRangeCustomize.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BModal,
    BButton,
    BTable,
    BSpinner,
    BSkeletonTable,
    BFormGroup,
    BFormInput,
    BLink,
    vSelect,
    ToggleButton,
    // DateTimeRangeCustomize,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    customSearchData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoadingBusy: true,
      isUpdateBusy: false,
      missingFields: [],
      targetIndex: null,
      // targetMode: null,
    }
  },
  methods: {
    // (顯示)彈窗
    handleShowListModal() {
      this.tableData = Array.isArray(this.customSearchData.customize) ? JSON.parse(JSON.stringify(this.customSearchData.customize)) : []
      this.isTableBusy = false
      this.$bvModal.show('todoSearchModal')
    },

    // (關閉)彈窗
    handleHideListModal() {
      this.$emit('refresh-todo-list')
      this.$bvModal.hide('todoSearchModal')
    },
    // -----------------------------------------------------------------------------------------------
    // (過濾)作業分類
    filterTodoTypeOptions(departmentId) {
      const findTypeGroup = this.typeGroupOptions.find(el => el.department_id === departmentId)
      if (findTypeGroup === undefined) {
        this.configData.searchType = null
        this.typeOptions = []
        return
      }

      this.typeOptions = findTypeGroup.data

      const findType = this.typeOptions.find(el => el.id === this.configData.searchType)

      if (findType === undefined) {
        this.configData.searchType = null
      }
    },

    // (過濾)項目設定
    filterTodoExecutionTypeOptions(departmentId) {
      const findexEcutionTypeGroup = this.executionTypeGroupOptions.find(el => el.department_id === departmentId)
      if (findexEcutionTypeGroup === undefined) {
        this.configData.searchExecutionType = null
        this.executionTypeOptions = []
        return
      }

      this.executionTypeOptions = findexEcutionTypeGroup.data

      const findexEcutionType = this.executionTypeOptions.find(el => el.id === this.configData.searchExecutionType)

      if (findexEcutionType === undefined) {
        this.configData.searchExecutionType = null
      }
    },
    // ------------------------------------------------------------------------------------------------
    // (顯示)新增彈窗
    onSubmitCreate(array) {
      this.isLoadingBusy = true
      // this.targetMode = 'create'
      this.$bvModal.show('todoSearchUpdateModal')
      this.targetIndex = null
      const resolveData = this.syncObject(this.blankSearchData, {})
      this.configData = resolveData
      this.tableData = array

      this.getMetaListData()
        .then(response => {
          // 陣列更新中
          const { departments, priority } = response.data.data
          this.departmentsOptions = departments
          this.priorityOptions = priority

          const adminBrands = response.data.data.admin_brands
          this.adminBrandOptions = adminBrands

          const todoTypes = response.data.data.todo_types
          this.typeGroupOptions = todoTypes

          const todoExecutionTypes = response.data.data.execution_types
          this.executionTypeGroupOptions = todoExecutionTypes

          const todoStatus = response.data.data.todo_status
          this.todoStatusOptions = todoStatus

          // 判斷是否有在陣列中
          const findDepartment = departments.find(el => el.id === this.configData.searchDepartment)

          if (findDepartment === undefined) {
            this.configData.searchDepartment = null
          } else {
            this.filterTodoTypeOptions(this.configData.searchDepartment)
            this.filterTodoExecutionTypeOptions(this.configData.searchDepartment)
          }
          this.isLoadingBusy = false
        })
        .catch(() => {
          this.isLoadingBusy = false
        })
    },

    // (顯示)新增/編輯彈窗
    onSubmitUpdate(index, item) {
      this.isLoadingBusy = true
      // this.targetMode = 'update'
      this.$bvModal.show('todoSearchUpdateModal')
      this.targetIndex = index
      const resolveData = this.syncObject(this.blankSearchData, item)
      this.configData = resolveData

      this.getMetaListData()
        .then(response => {
          // 陣列更新中
          const { departments, priority } = response.data.data
          this.departmentsOptions = departments
          this.priorityOptions = priority

          const adminBrands = response.data.data.admin_brands
          this.adminBrandOptions = adminBrands

          const todoTypes = response.data.data.todo_types
          this.typeGroupOptions = todoTypes

          const todoExecutionTypes = response.data.data.execution_types
          this.executionTypeGroupOptions = todoExecutionTypes

          const todoStatus = response.data.data.todo_status
          this.todoStatusOptions = todoStatus

          // 判斷是否有在陣列中
          const findDepartment = departments.find(el => el.id === this.configData.searchDepartment)

          if (findDepartment === undefined) {
            this.configData.searchDepartment = null
          } else {
            this.filterTodoTypeOptions(this.configData.searchDepartment)
            this.filterTodoExecutionTypeOptions(this.configData.searchDepartment)
          }
          this.isLoadingBusy = false
        })
        .catch(() => {
          this.isLoadingBusy = false
        })
    },

    // (刪除)分類
    onSubmitDelete(index, item) {
      this.useSwalAlertWarning('刪除自定義篩選', `你確定要永久刪除自定義篩選 ${item.name} 嗎?`)
        .then(result => {
          if (result.value) {
            const newData = JSON.parse(JSON.stringify(this.tableData))
            newData.splice(index, 1)
            this.setConfigUpdate({
              todo_list_config: {
                ...this.customSearchData,
                customize: newData,
              },
            })
              .then(() => {
                this.refetchTable()
                this.useSwalAlertCenter(true, '刪除成功!', '自定義篩選已移除')
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },

    // (觸發)儲存
    onSubmitSave(newData) {
      this.setConfigUpdate({
        todo_list_config: {
          ...this.customSearchData,
          customize: newData,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.refetchTable()
          this.$nextTick(() => {
            this.handleHideSearchUpdateModal()
            this.$emit('refresh-todo-list')
          })
          this.isUpdateBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isUpdateBusy = false
        })
    },

    handleOk() {
      // bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '自訂義名稱',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.configData[Object.keys(requiredFields)[i]] === null || this.configData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      this.isUpdateBusy = true

      if (this.targetIndex !== null) {
        const newData = JSON.parse(JSON.stringify(this.tableData))
        newData[this.targetIndex] = this.configData
        this.onSubmitSave(newData)
      } else {
        const newData = JSON.parse(JSON.stringify(this.tableData))
        newData.push(this.configData)
        this.onSubmitSave(newData)
      }
    },

    // (關閉)彈窗
    handleHideSearchUpdateModal() {
      this.$bvModal.hide('todoSearchUpdateModal')
    },
  },
  setup() {
    const {
      getMetaListData,
      departmentsOptions,
      adminBrandOptions,
      executionTypeOptions,
      typeGroupOptions,
      executionTypeGroupOptions,
      priorityOptions,
      todoStatusOptions,
      typeOptions,
      syncObject,
    } = useTodoSetting()

    const {
      setTodoCreateQuick,
    } = useTodoList()

    const {
      tableData,
      isTableBusy,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      searchOptions,
      refetchTable,

      setConfigUpdate,
      getTodoConfigList,

      configData,
      blankSearchData,
    } = useSearchList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    return {
      tableData,
      isTableBusy,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchTable,
      setConfigUpdate,
      getTodoConfigList,
      configData,
      blankSearchData,
      searchOptions,

      useAlertToast,
      useHttpCodeAlert,
      setTodoCreateQuick,

      getMetaListData,
      departmentsOptions,
      adminBrandOptions,
      executionTypeOptions,
      typeGroupOptions,
      executionTypeGroupOptions,
      priorityOptions,
      todoStatusOptions,
      typeOptions,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.show-more-arrow {
  transition: transform 0.3s ease-in-out;
  .active {
    transition: transform 0.3s ease-in-out;
    transform: rotate(90deg);
  }
}
</style>
