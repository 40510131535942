<template>
  <div
    class="todo-sidebar"
    :class="{'show': mqShallShowLeftSidebar}"
  >
    <div class="sidebar-search">

      <!-- <div class="sidebar-search-all d-flex align-items-center justify-content-center">
        <todo-search
          :search-setting="{
            name: searchDisplay ? '全部' : '當前',
            image: searchDisplay ? '/admin/images/todo/action/all.svg' : '/admin/images/todo/action/now.svg',
            searchDisplay,
          }"
          class="mr-25"
          @submit-search-content="submitSearchContent"
        />
      </div> -->

      <!-- 篩選器 -->
      <div
        class="sidebar-search-all d-flex align-items-center justify-content-center cursor-pointer search-icon-div"
        @click="$emit('change-active-chart-show')"
      >
        <!-- @click="$bvModal.show('sidebarFilter')" -->
        <b-avatar
          id="todo-search-chart"
          size="40"
          class="badge-minimal p-25 border search-icon"
          :variant="shallTodoChartShowState ? 'info' : 'light-primary'"
          src="/admin/images/todo/action/ranking.png"
        />

        <b-tooltip
          target="todo-search-chart"
          boundary-padding="10"
          boundary="window"
          placement="right"
        >
          統計資料
        </b-tooltip>
      </div>

      <hr
        class="mt-0 mb-25"
        :style="{ 'border-color': '#a4a9b080'}"
      >

      <div class="sidebar-search-area">

        <div class="sidebar-search-content">
          <div class="mt-25" />
          <!-- {{ $store.state['admin-todo'].statusList }} -->

          <div class="sidebar-search-all d-flex align-items-center justify-content-center">
            <todo-search
              :search-setting="{
                name: searchDisplay ? '全部' : '當前',
                image: searchDisplay ? '/admin/images/todo/action/all.svg' : '/admin/images/todo/action/now.svg',
                searchDisplay,
              }"
              class="mr-25"
              @submit-search-content="submitSearchContent"
            />
          </div>

          <!-- 篩選器 -->
          <div
            class="d-flex align-items-center justify-content-center cursor-pointer search-icon-div mb-1"
            @click="$bvModal.show('sidebarFilter')"
          >
            <b-avatar
              id="todo-search-filter"
              size="40"
              class="badge-minimal p-25 border search-icon"
              :variant="isFilterUse ? 'info' : 'light-primary'"
              src="/admin/images/todo/action/filters.svg"
            />

            <b-tooltip
              target="todo-search-filter"
              boundary-padding="10"
              boundary="window"
              placement="right"
            >
              篩選器
            </b-tooltip>
          </div>

          <!-- 創建日期 -->
          <div
            class="d-flex align-items-center justify-content-center cursor-pointer search-icon-div mb-1"
            @click="openCreatedModel"
          >
            <b-avatar
              id="todo-search-created"
              size="40"
              class="badge-minimal p-25 border search-icon"
              :variant="timeRange ? 'info' : 'light-primary'"
              src="/admin/images/todo/action/schedule.svg"
            />

            <b-tooltip
              target="todo-search-created"
              boundary-padding="10"
              boundary="window"
              placement="right"
            >
              創建日期
            </b-tooltip>
          </div>

          <!-- 即將截止 -->
          <div
            class="d-flex align-items-center justify-content-center cursor-pointer search-icon-div mb-1"
            @click="openDeadlineModel"
          >
            <b-avatar
              id="todo-search-deadline"
              size="40"
              class="badge-minimal p-25 border search-icon"
              :variant="searchTimeDeadline ? 'info' : 'light-primary'"
              src="/admin/images/todo/action/time-management.svg"
            />

            <b-tooltip
              target="todo-search-deadline"
              boundary-padding="10"
              boundary="window"
              placement="right"
            >
              即將截止
            </b-tooltip>
            <!-- schedule.svg -->
          </div>

          <!-- 顯示自己 -->
          <div
            class="d-flex align-items-center justify-content-center cursor-pointer search-icon-div mb-1"
            @click="() => searchDisplayself = !searchDisplayself"
          >
            <b-avatar
              id="todo-search-display-self"
              size="40"
              class="badge-minimal p-25 border search-icon"
              :variant="searchDisplayself ? 'info' : 'light-primary'"
              src="/admin/images/todo/action/personal.svg"
            />

            <b-tooltip
              target="todo-search-display-self"
              boundary-padding="10"
              boundary="window"
              placement="right"
            >
              顯示自己
            </b-tooltip>
          </div>

          <div class="animate__animated animate__fadeIn">
            <div
              v-for="status in $store.state['admin-todo'].statusList"
              :key="status.value"
              class="d-flex align-items-center justify-content-center cursor-pointer search-icon-div mb-1"
              @click="clickFilterStatusBtn(status.value)"
            >
              <b-avatar
                :id="status.value"
                size="40"
                class="badge-minimal p-25 border search-icon"
                :variant="isFilterStatusUse(status.value) ? 'info' : 'light-primary'"
                :src="`/admin/images/todo/items/${status.value}.svg`"
              />

              <b-tooltip
                :target="status.value"
                boundary-padding="10"
                boundary="window"
                placement="right"
              >
                {{ status.title }}
              </b-tooltip>
            </div>
          </div>

          <!-- 自訂搜尋 -->
          <div
            v-if="customSearch"
            class="animate__animated animate__fadeIn"
          >
            <hr :style="{ 'border-color': '#a4a9b080'}">

            <div
              v-for="(custom, index) in customSearch.customize"
              :key="`custom-${index + 1}`"
              @contextmenu.prevent="() => {
                rightClickSearch = custom
                rightClickSearchIndex = index
              }"
            >
              <todo-search
                v-contextmenu:filterMenu
                :search-setting="{
                  serial: `custom-${index + 1}`,
                  ...custom,
                }"
                class="my-75"
                @submit-search-content="submitSearchContent"
              />
            </div>
          </div>

          <!-- {{ customSearch.customize }} -->

          <!-- 新增 -->
          <div
            class="d-flex align-items-center justify-content-center cursor-pointer search-icon-div mb-1"
            @click="$refs.todoSidebarConfig.onSubmitCreate(customSearch.customize)"
          >
            <b-avatar
              id="todo-search-add"
              size="40"
              class="badge-minimal p-25 border search-icon"
              variant="light-primary"
            >
              <feather-icon
                size="21"
                icon="PlusIcon"
              />
            </b-avatar>

            <b-tooltip
              target="todo-search-add"
              boundary-padding="10"
              boundary="window"
              placement="right"
            >
              新增
            </b-tooltip>
          </div>
        </div>

        <div class="sidebar-search-all d-flex align-items-center justify-content-center flex-column sidebar-search-footer">
          <!-- <hr :style="{ 'border-color': '#a4a9b080'}"> 自訂義設定 -->
          <!-- 個人設定 -->
          <div
            id="todo-search-setting"
            class="d-flex align-items-center icon-link-btn cursor-pointer mb-1 mt-2"
            @click="$emit('open-todo-setting-sidebar')"
          >
            <!-- @click="$refs.todoSidebarConfig.handleShowListModal()" -->
            <b-img
              src="/admin/images/table/settings.svg"
              class="icon-link-btn-image"
              rounded
            />
          </div>

          <b-tooltip
            target="todo-search-setting"
            boundary-padding="10"
            boundary="window"
            placement="right"
          >
            個人設定
          </b-tooltip>

          <!-- 滿版模式 -->
          <div
            id="todo-full-screen"
            class="d-flex align-items-center icon-expand-link-btn cursor-pointer"
            @click="submitUpdateFullScreen"
          >
            <!-- $store.commit('app/UPDATE_TODO_FULLSCREEN') -->
            <b-img
              :src="`/admin/images/todo/action/${$store.state.app.todoFullScreen ? 'minimise' : 'expand'}.svg`"
              class="icon-expand-link-btn-image"
              rounded
            />
          </div>

          <b-tooltip
            target="todo-full-screen"
            boundary-padding="10"
            boundary="window"
            placement="right"
          >
            {{ $store.state.app.todoFullScreen ? '縮小' : '全屏顯示' }}
          </b-tooltip>
        </div>
      </div>

      <div v-if="customSearch">
        <todo-sidebar-config
          ref="todoSidebarConfig"
          :custom-search-data="customSearch"
          @refresh-todo-list="refreshTodoListPageData"
        />
      </div>

      <v-contextmenu
        ref="filterMenu"
        :class="$store.state.appConfig.layout.skin === 'dark' ? 'my-contextmenu-dark' : 'my-contextmenu'"
      >
        <div>
          <div class="context-menu-title mb-50">
            {{ rightClickSearch.name }}
          </div>

          <v-contextmenu-item divider />

          <v-contextmenu-item
            class="dropdown-item-area"
            @click="$refs.todoSidebarConfig.onSubmitUpdate(rightClickSearchIndex, rightClickSearch)"
          >
            <b-img
              src="/admin/images/todo/action/pen.svg"
              class="dropdown-item-btn-image mr-50"
              rounded
            />
            <span>編輯</span>
          </v-contextmenu-item>

          <v-contextmenu-item
            class="dropdown-item-area"
            @click="todoSidebarConfigDelete(rightClickSearchIndex, rightClickSearch)"
          >
            <b-img
              src="/admin/images/todo/action/trash-bin.svg"
              class="dropdown-item-btn-image mr-50"
              rounded
            />
            <span>刪除</span>
          </v-contextmenu-item>
        </div>
      </v-contextmenu>
    </div>

    <div class="sidebar-left">
      <div class="sidebar">
        <!-- 新增待辦 -->
        <todo-add-sidebar
          :shall-open-todo-add-sidebar-state="shallOpenTodoAddSidebarState"
          @open-todo-quick-list-data="$emit('open-quick-list')"
          @refetch-data="refetchData"
          @close-sidebar="todo => {
            $emit('update:shall-open-todo-add-sidebar-state', false)
            if (todo) $emit('open-chat', todo.id)
          }"
        />

        <!-- 個人設定 -->
        <todo-setting-sidebar
          v-if="customSearch"
          :custom-search="customSearch"
          :shall-open-todo-setting-sidebar-state="shallOpenTodoSettingSidebarState"
          @open-todo-quick-list-data="$emit('open-quick-list')"
          @open-todo-config-list-data="$refs.todoSidebarConfig.handleShowListModal()"
          @refetch-data="refetchData"
          @close-sidebar="$emit('update:shall-open-todo-setting-sidebar-state', false)"
        />

        <div class="sidebar-content">
          <!-- :class="{'show': mqShallShowLeftSidebar}" -->

          <span class="sidebar-close-icon">
            <feather-icon
              icon="XIcon"
              size="16"
              @click="$emit('update:mq-shall-show-left-sidebar', false)"
            />
          </span>

          <!-- Header -->
          <div class="chat-fixed-search">
            <div
              v-if="selectedList.length <= 0"
              class="d-flex align-items-center w-100"
            >
              <!-- Search -->
              <b-input-group class="input-group-merge w-100 round">
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  placeholder="搜尋..."
                  debounce="500"
                />
                <!-- <b-input-group-append is-text>
                  <div
                    v-b-tooltip.hover.v-secondary
                    title="新增"
                    class="d-flex align-items-center todo-link-btn"
                    @click="$emit('open-todo-add-sidebar')"
                  >
                    <b-img
                      src="/admin/images/table/plus.svg"
                      class="todo-link-btn-image"
                      rounded
                    />
                  </div>
                </b-input-group-append> -->
              </b-input-group>

              <div class="sidebar-profile-toggle ml-50 d-flex">
                <!-- <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refreshTodoListPageData"
                >
                  <b-img
                    src="/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div> -->

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="$emit('open-todo-add-sidebar')"
                >
                  <b-img
                    src="/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <!-- <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="篩選器"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="$bvModal.show('sidebarFilter')"
                >
                  <b-img
                    src="/admin/images/table/filter.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div> -->

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="排序"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  :class="{ 'rotated': isSortDirDesc }"
                  @click="isSortDirDesc = !isSortDirDesc"
                >
                  <b-img
                    src="/admin/images/todo/action/arrows.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

              </div>
            </div>

            <div
              v-if="selectedList.length > 0"
              class="d-flex justify-content-between align-items-center w-100"
            >
              <div>
                <small>已選擇 {{ selectedList.length }} 則待辦</small>
              </div>

              <div class="d-flex text-nowrap">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="完成"
                  class="d-flex align-items-center actions-link-btn mr-25"
                  @click="submitConfirmTodo(selectedList)"
                >
                  <b-img
                    src="/admin/images/todo/items/completed.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="複查"
                  class="d-flex align-items-center actions-link-btn mr-25"
                  @click="$emit('emit-review-todo', selectedList)"
                >
                  <b-img
                    src="/admin/images/todo/action/check.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="刪除"
                  class="d-flex align-items-center actions-link-btn"
                  @click="submitDeleteTodo(selectedList)"
                >
                  <b-img
                    src="/admin/images/todo/action/trash.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="取消"
                  class="d-flex align-items-center actions-link-btn"
                  @click="() => { selectedList = [] }"
                >
                  <b-img
                    src="/admin/images/table/cancel.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- ScrollArea: Chat & Contacts -->
          <vue-perfect-scrollbar
            ref="todoListContent"
            :settings="perfectScrollbarSettings"
            class="chat-user-list-wrapper list-group scroll-area"
          >

            <div class="animate__animated animate__fadeIn">
              <ul
                v-if="todoListData.length > 0"
                class="chat-users-list chat-list media-list"
              >
                <div>
                  <todo-contact
                    v-for="todo in todoListData"
                    :key="todo.id"
                    v-contextmenu:contextmenu
                    :todo-data="todo"
                    tag="li"
                    :class="{'active': activeChatContactId === todo.id}"
                    class="chat-contact"
                    :is-chat-contact="activeChatContactId !== todo.id"
                    @submit-show-content="id => {
                      targetRouteContent(todo)
                      todo.has_view = true
                    }"
                    @contextmenu.prevent="() => { rightClick = todo }"
                  >
                    <!-- submitShowContent(id, todo) .id, id, todo
                      todo.has_view = true -->
                    <div
                      v-if="selectedList.length > 0"
                      slot="checkbox"
                    >
                      <b-form-checkbox
                        v-model="selectedList"
                        :value="todo.id"
                        name="muti-selected"
                      />
                    </div>

                    <div
                      v-if="selectedList.length > 0"
                      slot="mask"
                      class="chat-contact-mask"
                      @click="submitSelectedTodo(todo.id)"
                    />
                  </todo-contact>
                </div>
              </ul>

              <div
                v-if="isBusyLoading || isBusy"
                class="text-center p-2"
              >
                <b-img
                  src="/admin/images/common/loading-2.png"
                  rounded
                  height="30"
                  width="30"
                />
              </div>

              <!-- {{ selectedList }} -->

              <!-- <div
                v-if="!isBusyLoading && !isAll && todoListData.length !== 0"
                class="text-center p-2"
              >
                <span>沒有更多資料了</span>
              </div> -->
            </div>

            <!-- {{ rightClick }} -->

            <!-- <div
              v-else
              class="text-center p-5"
            >
              <b-img
                src="/admin/images/common/loading-2.png"
                rounded
                height="60"
                width="60"
              />
            </div> -->
          </vue-perfect-scrollbar>

          <v-contextmenu
            ref="contextmenu"
            :class="$store.state.appConfig.layout.skin === 'dark' ? 'my-contextmenu-dark' : 'my-contextmenu'"
          >
            <div>
              <div class="context-menu-title mb-50">
                {{ rightClick.title }}
              </div>

              <v-contextmenu-item divider />

              <v-contextmenu-group v-if="selectedList.length <= 0">
                <v-contextmenu-item
                  v-if="!rightClick.has_view"
                  @click="submitUpdateTodoView('read', [rightClick.id])"
                >
                  <!-- v-b-tooltip.hover.focus.v-secondary
                  title="標示為已讀" -->
                  <!--  -->
                  <div class="context-menu-btn">
                    <b-img
                      src="/admin/images/todo/action/eye-2.svg"
                      class="context-menu-btn-image"
                      rounded
                    />
                  </div>
                </v-contextmenu-item>

                <v-contextmenu-item
                  v-if="rightClick.has_view"
                  @click="submitUpdateTodoView('unread', [rightClick.id])"
                >
                  <!-- v-b-tooltip.hover.focus.v-secondary
                    title="標示為未讀" -->
                  <div class="context-menu-btn">
                    <b-img
                      src="/admin/images/todo/action/hide-2.svg"
                      class="context-menu-btn-image"
                      rounded
                    />
                  </div>
                </v-contextmenu-item>

                <v-contextmenu-item @click="submitConfirmTodo([rightClick.id])">
                  <!-- v-if="rightClick.status !== 'completed'" -->
                  <div class="context-menu-btn">
                    <b-img
                      src="/admin/images/todo/items/completed.svg"
                      class="context-menu-btn-image"
                      rounded
                    />
                  </div>
                </v-contextmenu-item>

                <v-contextmenu-item @click="$emit('emit-review-todo', [rightClick.id])">
                  <!-- v-b-tooltip.hover.focus.v-secondary
                  title="複查" -->
                  <div class="context-menu-btn">
                    <b-img
                      src="/admin/images/todo/action/check.svg"
                      class="context-menu-btn-image"
                      rounded
                    />
                  </div>
                </v-contextmenu-item>

                <v-contextmenu-item @click="submitDeleteTodo([rightClick.id])">
                  <!-- v-b-tooltip.hover.focus.v-secondary
                  title="刪除" -->
                  <div class="context-menu-btn">
                    <b-img
                      src="/admin/images/todo/action/trash.svg"
                      class="context-menu-btn-image"
                      rounded
                    />
                  </div>
                </v-contextmenu-item>
              </v-contextmenu-group>

              <!-- <v-contextmenu-item :auto-hide="false">
                {{ $store.state.appConfig.layout.skin }}
              </v-contextmenu-item> -->
              <v-contextmenu-item
                v-if="selectedList.length <= 0"
                divider
              />

              <v-contextmenu-item
                class="dropdown-item-area"
                @click="submitShareTodo"
              >
                <b-img
                  src="/admin/images/todo/action/share.svg"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>分享連結</span>
              </v-contextmenu-item>

              <v-contextmenu-submenu>
                <template #title>
                  <span class="dropdown-item-area">
                    <b-img
                      src="/admin/images/todo/action/clock.svg"
                      class="dropdown-item-btn-image mr-50"
                      rounded
                    />
                    <span>延長截止時間</span>
                  </span>
                </template>
                <v-contextmenu-item :auto-hide="false">
                  <div class="d-flex align-items-center">
                    <feather-icon
                      icon="ClockIcon"
                      size="12"
                    />
                    <span class="ml-50">延長 1小時</span>
                  </div>
                </v-contextmenu-item>

                <v-contextmenu-item :auto-hide="false">
                  <div class="d-flex align-items-center">
                    <feather-icon
                      icon="ClockIcon"
                      size="12"
                    />
                    <span class="ml-50">延長 2小時</span>
                  </div>
                </v-contextmenu-item>

                <v-contextmenu-item :auto-hide="false">
                  <div class="d-flex align-items-center">
                    <feather-icon
                      icon="ClockIcon"
                      size="12"
                    />
                    <span class="ml-50">延長 3小時</span>
                  </div>
                </v-contextmenu-item>
              </v-contextmenu-submenu>

              <v-contextmenu-submenu>
                <template #title>
                  <span class="dropdown-item-area">
                    <b-img
                      src="/admin/images/todo/action/todo.svg"
                      class="dropdown-item-btn-image mr-50"
                      rounded
                    />
                    <span>優先程度</span>
                  </span>
                </template>

                <v-contextmenu-item
                  v-for="priority in $store.state['admin-todo'].priorityOptions"
                  :key="priority.value"
                  :class="{'contextmenu-item-active': priority.value == rightClick.priority }"
                  :auto-hide="false"
                  @click="updateTodoData('priority', { priority: priority.value }, priority.value == rightClick.priority)"
                >
                  <!-- :auto-hide="false" -->
                  <div class="d-flex align-items-center">
                    <span
                      class="bullet mr-1 border filter-dot"
                      :style="{'background-color': priority.color}"
                    />
                    <span>{{ priority.title }}</span>
                  </div>
                </v-contextmenu-item>
              </v-contextmenu-submenu>

              <v-contextmenu-submenu>
                <template #title>
                  <span class="dropdown-item-area">
                    <b-img
                      src="/admin/images/todo/action/tag.svg"
                      class="dropdown-item-btn-image mr-50"
                      rounded
                    />
                    <span>狀態</span>
                  </span>
                </template>

                <v-contextmenu-item
                  v-for="status in $store.state['admin-todo'].statusList"
                  :key="status.value"
                  :class="{'contextmenu-item-active': status.value === rightClick.status }"
                  :auto-hide="false"
                  @click="updateTodoData('status', { status: status.value }, status.value == rightClick.status)"
                >
                  <div class="d-flex align-items-center">
                    <span
                      class="bullet mr-1 border filter-dot"
                      :style="{'background-color': status.color}"
                    />
                    <span>{{ status.title }}</span>
                  </div>
                </v-contextmenu-item>
              </v-contextmenu-submenu>

              <v-contextmenu-item
                class="dropdown-item-area"
                @click="submitSelectedTodo(rightClick.id)"
              >
                <!-- @click="submitSelectedTodo(rightClick.id)" -->
                <b-img
                  src="/admin/images/todo/action/check1.svg"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>{{ isIndexInSelectedTodo(rightClick.id) ? '取消選取' : '選取訊息' }}</span>
              </v-contextmenu-item>
            </div>
          </v-contextmenu>
        </div>

      </div>

      <!-- 篩選器 -->
      <b-modal
        id="sidebarFilter"
        centered
        hide-footer
        size="lg"
        header-bg-variant="primary"
        no-close-on-backdrop
      >
        <!-- no-close-on-backdrop -->
        <template #modal-title>
          <h4 class="m-0 model-header">
            篩選器
          </h4>
        </template>

        <div class="mb-2">
          <b-row>
            <b-col
              cols="12"
              lg="6"
            >
              <!-- 顯示內容 -->
              <b-form-group>
                <div class="d-flex justify-content-between align-items-center mt-75">
                  <div class="d-flex align-items-center">
                    <label class="mb-0">顯示內容</label>

                    <span>
                      <div
                        v-b-tooltip.hover.v-secondary
                        title="設定為當前，不顯示排定代辦"
                        class="d-flex align-items-center"
                        style="padding: 0 5px;"
                      >
                        <b-img
                          src="/admin/images/common/info.svg"
                          class="actions-link-btn-image cursor-pointer"
                          rounded
                          style=" width: 14px; height: 14px;"
                        />
                      </div>
                    </span>
                  </div>

                  <toggle-button
                    v-model="searchDisplay"
                    :color="{checked: '#4683bb', unchecked: '#20c997'}"
                    :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                    :width="60"
                    :height="24"
                    class="m-25"
                    :labels="{checked: '全部', unchecked: '當前'}"
                    :sync="true"
                  />
                </div>
              </b-form-group>

              <!-- 排序內容 -->
              <b-form-group>
                <label>排序內容</label>
                <v-select
                  v-model="sortBy"
                  :options="searchOptions"
                  :reduce="option => option.value"
                  :clearable="false"
                  placeholder="請選擇排序內容"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <!-- 排序方式 -->
              <b-form-group>
                <label>排序方式</label>

                <v-select
                  v-model="isSortDirDesc"
                  :options="[{ label: '降序排列', value: true }, { label: '升序排列', value: false }]"
                  :reduce="option => option.value"
                  :clearable="false"
                  placeholder="排序方式"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <!-- 狀態 -->
              <b-form-group>
                <label>狀態</label>
                <v-select
                  v-model="searchStatus"
                  :options="statusOptions"
                  label="title"
                  multiple
                  :reduce="option => option.value"
                  placeholder="請選擇狀態"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>

                  <template v-slot:option="option">
                    <div class="d-flex align-items-center">
                      <span
                        class="bullet mr-1 border filter-dot"
                        :style="{'background-color': option.color}"
                      />
                      {{ option.title }}
                    </div>
                  </template>

                  <template #selected-option="option">
                    <div class="d-flex align-items-center">
                      <span
                        class="bullet mr-1 border filter-dot"
                        :style="{'background-color': option.color}"
                      />
                      {{ option.title }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <!-- 優先程度 -->
              <b-form-group>
                <label>優先程度</label>
                <v-select
                  v-model="searchPriority"
                  :options="priorityOptions"
                  label="title"
                  :reduce="option => option.value"
                  placeholder="請選擇優先程度"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>

                  <template v-slot:option="option">
                    <div class="d-flex align-items-center">
                      <span
                        class="bullet mr-1 border filter-dot"
                        :style="{'background-color': option.color}"
                      />
                      {{ option.title }}
                    </div>
                  </template>

                  <template #selected-option="option">
                    <div class="d-flex align-items-center">
                      <span
                        class="bullet mr-1 border filter-dot"
                        :style="{'background-color': option.color}"
                      />
                      {{ option.title }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              lg="6"
            >
              <!-- 只顯示自己 -->
              <b-form-group>
                <div class="d-flex justify-content-between align-items-center mt-50">
                  <label class="mb-0">只顯示自己</label>

                  <toggle-button
                    v-model="searchDisplayself"
                    :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                    :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                    :width="60"
                    :height="24"
                    class="m-25 mb-50"
                    :labels="{checked: '開啟', unchecked: '關閉'}"
                    :sync="true"
                  />
                </div>
              </b-form-group>

              <!-- 負責品牌 -->
              <b-form-group>
                <label>負責品牌</label>

                <v-select
                  v-model="searchAdminBrand"
                  :options="adminBrandOptions"
                  label="name"
                  :reduce="option => option.id"
                  placeholder="請選擇品牌"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <!-- 負責部門 -->
              <b-form-group>
                <label>負責部門</label>
                <v-select
                  v-model="searchDepartment"
                  :options="departmentsOptions"
                  label="name"
                  :reduce="option => option.id"
                  placeholder="請選擇負責部門"
                  @input="() => {
                    filterTodoTypeOptions(searchDepartment)
                    filterTodoExecutionTypeOptions(searchDepartment)
                  }"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <!-- 作業分類 -->
              <b-form-group>
                <label>作業分類</label>
                <v-select
                  v-model="searchType"
                  :options="typeOptions"
                  label="name"
                  :reduce="option => option.id"
                  placeholder="請選擇作業分類"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <!-- 項目設定 -->
              <b-form-group>
                <label>項目設定</label>
                <v-select
                  v-model="searchExecutionType"
                  :options="executionTypeOptions"
                  label="name"
                  :reduce="option => option.id"
                  placeholder="請選擇項目設定"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- 即將截止 -->
          <!-- <b-form-group>
            <label>即將截止</label>
            <v-select
              v-model="searchTimeWarning"
              :options="timeWarningOptions"
              :reduce="option => option.value"
              :clearable="false"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-form-group> -->

          <!-- <date-time-range-customize
            @call-back-time="(time) => timeRange = time"
          /> -->
        </div>

      </b-modal>

      <!-- 創建日期 -->
      <b-modal
        id="sidebarFilterCreated"
        centered
        hide-footer
        header-bg-variant="info"
      >
        <!-- no-close-on-backdrop -->
        <template #modal-title>
          <h4 class="m-0 model-header">
            創建日期
          </h4>
        </template>

        <div class="mb-1">
          <date-range-customize
            ref="dateRangeCustomizeCreated"
            date-range-customize-id="dateRangeCustomizeCreated"
            :use-time="true"
            :init-data="timeRange"
            :max-date-time="moment().format('YYYY-MM-DD HH:mm')"
            @call-back-time="(time) => timeRange = time"
          >
            <template slot="quick-time-btn">
              <div class="mb-50">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeCreated.quickTime(0, 'd')"
                >
                  今天
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeCreated.quickTime(-1, 'd')"
                >
                  昨天
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeCreated.quickTime(-7, 'd')"
                >
                  上周
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeCreated.quickTime(-14, 'd')"
                >
                  上兩周
                </b-button>

                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="getThisMonthRange"
                >
                  本月
                </b-button>
              </div>
            </template>
          </date-range-customize>
          <!-- @call-back-time="(time) => timeRange = time" -->
        </div>

      </b-modal>

      <!-- 截止日期 -->
      <b-modal
        id="sidebarFilterDeadline"
        centered
        hide-footer
        header-bg-variant="danger"
      >
        <template #modal-title>
          <h4 class="m-0 model-header">
            截止日期
          </h4>
        </template>

        <div class="mb-1">
          <date-range-customize
            ref="dateRangeCustomizeDeadline"
            date-range-customize-id="dateRangeCustomizeDeadline"
            :use-time="true"
            :init-data="searchTimeDeadline"
            @call-back-time="(time) => searchTimeDeadline = time"
          >
            <template slot="date-label">
              <label>截止日期</label>
            </template>

            <template slot="quick-time-btn">
              <div class="mb-50">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeDeadline.quickTime(6, 'h')"
                >
                  六小時內
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeDeadline.quickTime(12, 'h')"
                >
                  半天
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeDeadline.quickTime(7, 'd')"
                >
                  一周
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeDeadline.quickTime(14, 'd')"
                >
                  兩周
                </b-button>
              </div>
            </template>

            <template slot="time-label">
              <label>截止時間</label>
            </template>
          </date-range-customize>
        </div>

      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupPrepend, BFormInput, VBTooltip, BImg, BFormCheckbox, BModal, BFormGroup, BAvatar,
  BTooltip, BButton, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ToggleButton } from 'vue-js-toggle-button'
import Ripple from 'vue-ripple-directive'
import TodoContact from './TodoContact.vue'
import TodoSearch from './TodoContentSearch.vue'
import TodoAddSidebar from './TodoAddSidebar.vue'
import TodoSettingSidebar from './TodoSettingSidebar.vue'
import TodoSidebarConfig from './TodoLeftSidebarConfig.vue'
import { useTodoList, useSearchList } from '../useTodo'
import { useAlert, useSwalToast } from '@/libs/mixins/index'
// import DateTimeRangeCustomize from '@/layouts/components/Time/DateTimeRangeCustomize.vue'
import DateRangeCustomize from '@/layouts/components/Time/DateRangeCustomize.vue'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BModal,
    BAvatar,
    BTooltip,
    BButton,
    BFormGroup,
    // BDropdown,
    // BDropdownForm,
    BFormCheckbox,
    // BButton,
    BInputGroup,
    // BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,

    // 3rd party
    VuePerfectScrollbar,
    ToggleButton,

    // SFC
    vSelect,
    TodoContact,
    TodoSearch,
    TodoAddSidebar,
    TodoSettingSidebar,
    TodoSidebarConfig,
    // DateRangeCustomize,
    DateRangeCustomize,
    // DateTimeRangeCustomize,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    shallTodoChartShowState: {
      type: Boolean,
      required: true,
    },
    shallOpenTodoAddSidebarState: {
      type: Boolean,
      required: true,
    },
    shallOpenTodoSettingSidebarState: {
      type: Boolean,
      required: true,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      rightClick: {},
      rightClickSearch: {},
      rightClickSearchIndex: null,

      isLoading: false, // true: disable
      dateRangeText: null, // 時間區間 2023-01-01 to 2023-01-02
    }
  },
  computed: {
    isAll() {
      const num = this.currentPage * this.perPage
      if (num > this.totalNum) return false
      return true
    },

    isFilterUse() {
      if (this.searchPriority) {
        return true
      }

      if (this.searchStatus !== null && this.searchStatus.length > 0) {
        return true
      }
      // if (this.searchDisplay) {
      //   return true
      // }

      if (this.searchDepartment) {
        return true
      }

      if (this.searchExecutionType) {
        return true
      }

      if (this.searchType) {
        return true
      }
      // if (this.searchDisplayself) {
      //   return true
      // }

      return false
    },
  },
  watch: {
    todoListData: {
      handler(newValue) {
        this.selectedList = this.selectedList.filter(selectedItem => {
          const matchingItem = newValue.find(todoItem => todoItem.id === selectedItem)
          return matchingItem !== undefined
        })
      },
      deep: true,
    },

    customSearch: {
      handler(newValue) {
        localStorage.setItem('todoFullScreen', JSON.stringify(newValue.useFullScreen))
        this.$store.commit('app/UPDATE_TODO_FULLSCREEN', newValue.useFullScreen)
        this.$store.commit('admin-todo/UPDATE_USE_AUDIO_STATE', newValue.useAudio)
        this.$store.commit('admin-todo/UPDATE_USE_QUICK_STATE', newValue.useQuick)
        this.saveSearchConfig(this.customSearch)
      },
      deep: true,
    },
  },
  mounted() {
    const todoListContentElement = this.$refs.todoListContent.$el
    todoListContentElement.addEventListener('scroll', this.isScrollBottom)
  },
  methods: {
    moment,
    // (獲取)本月
    getThisMonthRange() {
      const today = new Date()
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

      // 设置日期对象
      const date = {
        start: firstDayOfMonth,
        end: lastDayOfMonth,
      }
      this.$refs.dateRangeCustomizeCreated.specifiedTime(date.start, date.end)
    },

    // (顯示)狀態是否選取
    isFilterStatusUse(state) {
      if (!this.searchStatus) return false

      if (typeof this.searchStatus === 'string') {
        return this.searchStatus === state
      }

      if (Array.isArray(this.searchStatus)) {
        return this.searchStatus.includes(state)
      }
      return false
    },

    // (點擊)狀態
    clickFilterStatusBtn(status) {
      const isInArray = this.isFilterStatusUse(status)
      const resolveSearchStatus = JSON.parse(JSON.stringify(this.searchStatus))

      if (!isInArray) {
        if (resolveSearchStatus) {
          if (typeof resolveSearchStatus === 'string') {
            this.searchStatus = [resolveSearchStatus, status]
          } else {
            resolveSearchStatus.push(status)
            this.searchStatus = resolveSearchStatus
          }
        }
        if (!resolveSearchStatus) {
          this.searchStatus = status
        }
      }
      if (isInArray) {
        if (typeof resolveSearchStatus === 'string') {
          if (resolveSearchStatus === status) {
            this.searchStatus = null
          }
        } else {
          const index = resolveSearchStatus.indexOf(status)
          if (index !== -1) {
            resolveSearchStatus.splice(index, 1)
            this.searchStatus = resolveSearchStatus
          }
        }
      }
    },

    targetRouteContent(todo) {
      const targetRoute = { name: 'admin-todo-view', params: { id: todo.id } }
      if (this.$route.name !== targetRoute.name || this.$route.params.id !== targetRoute.params.id) {
        this.$router.push(targetRoute)
        this.submitShowContent(todo.id, todo)
      }
    },

    // (過濾)作業分類
    filterTodoTypeOptions(departmentId) {
      const findTypeGroup = this.typeGroupOptions.find(el => el.department_id === departmentId)
      if (findTypeGroup === undefined) {
        this.searchType = null
        this.typeOptions = []
        return
      }

      this.typeOptions = findTypeGroup.data

      const findType = this.typeOptions.find(el => el.id === this.searchType)

      if (findType === undefined) {
        this.searchType = null
      }
    },

    // (過濾)項目設定
    filterTodoExecutionTypeOptions(departmentId) {
      const findexEcutionTypeGroup = this.executionTypeGroupOptions.find(el => el.department_id === departmentId)
      if (findexEcutionTypeGroup === undefined) {
        this.searchExecutionType = null
        this.executionTypeOptions = []
        return
      }

      this.executionTypeOptions = findexEcutionTypeGroup.data

      const findexEcutionType = this.executionTypeOptions.find(el => el.id === this.searchExecutionType)

      if (findexEcutionType === undefined) {
        this.searchExecutionType = null
      }
    },

    // (更新)待辦狀態/優先序
    updateTodoData(type, todoData, state) {
      if (state) return
      // this.activeInfoBusy = true
      this.setTodoUpdate({
        todo_id: this.rightClick.id,
        data: {
          ...todoData,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          if (type === 'status' && todoData[type] === 'completed') {
            this.setTodoComplete({
              data: {
                todo_id: [this.rightClick.id],
              },
            })
          }
          this.$refs.contextmenu.hide()
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
        })
    },

    // (觸發)顯示待按詳情
    submitShowContent(id, todo) {
      this.$emit('open-chat', id, todo)
    },

    // (觸發)搜尋
    submitSearchContent(search) {
      this.perPage = 15
      const propertiesToCopy = [
        'sortBy',
        'searchPriority',
        'searchStatus',
        'searchQuery',
        'searchDisplay',
        'searchDisplayself',
        'searchDepartment',
        'searchExecutionType',
        'searchType',
        'timeRange',
        'searchAdminBrand',
        'searchTimeWarning',
        'searchTimeDeadline',
      ]
      // this.sortBy = 'priority'
      // const resolveCache = this.syncObject(this.customSearch, search)
      propertiesToCopy.forEach(property => {
        this[property] = search[property]
      })
      // this.saveSearchConfig(resolveCache)
    },

    // (觸發)更新滿版模式
    submitUpdateFullScreen() {
      localStorage.setItem('todoFullScreen', JSON.stringify(!this.$store.state.app.todoFullScreen))
      this.$store.commit('app/UPDATE_TODO_FULLSCREEN', !this.$store.state.app.todoFullScreen)
      this.customSearch.useFullScreen = !this.customSearch.useFullScreen
      // this.saveSearchConfig(this.customSearch)
    },

    // ------------------------------------------------------------------------------------------------
    // (刪除)自訂分類
    todoSidebarConfigDelete() {
      this.useSwalAlertWarning('刪除自定義篩選', `你確定要永久刪除自定義篩選 ${this.rightClickSearch.name} 嗎?`)
        .then(result => {
          if (result.value) {
            const newData = JSON.parse(JSON.stringify(this.customSearch.customize))
            newData.splice(this.rightClickSearchIndex, 1)
            this.setConfigUpdate({
              todo_list_config: {
                ...this.customSearch,
                customize: newData,
              },
            })
              .then(() => {
                this.useSwalAlertCenter(true, '刪除成功!', '自定義篩選已移除')
                this.refreshTodoListPageData()
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },

    // (儲存)設定檔
    saveSearchConfig(resolveCache) {
      this.setConfigUpdate({
        todo_list_config: {
          ...resolveCache,
        },
      })
      // .then(() => {
      //   this.$nextTick(() => {
      //     this.refreshTodoListPageData()
      //   })
      // })
    },

    // ------------------------------------------------------------------------------------------------

    // (觸發)標示為已讀/未讀
    submitUpdateTodoView(type, arr) {
      this.setTodoViewUpdate({ type, data: { todo_id: arr } })
      // .then(() => {
      //   // response
      //   // this.useHttpCodeAlert(response)
      //   // this.refreshTodoListPageData()
      // })
      // .catch(error => {
      //   this.useHttpCodeAlert(error.response)
      // })
    },

    // (觸發)開新分頁
    // submitOpenTodo() {
    //   const url = `/admin/todo/${this.rightClick.id}`
    //   window.open(url, '_blank')
    // },

    // (觸發)分享
    submitShareTodo() {
      if (!navigator.share) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      const url = `/admin/todo?fid=${this.rightClick.id}`
      navigator.share({
        title: '曠世國際管理後台',
        text: '我在待辦系統找到一則緊急的待辦，趕緊來看看吧',
        url,
      })
    },

    // (觸發)完成
    submitConfirmTodo(arr) {
      if (arr.length <= 0) return

      this.useSwalAlertInfo('完成待辦', arr.length > 1 ? `你確定要完成所選的 ${arr.length} 個待辦 嗎?` : `你確定要完成待辦 ${this.rightClick.title ? this.rightClick.title : '未命名標題'} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setTodoComplete({
              data: {
                todo_id: arr,
              },
            })
              .then(() => {
                this.refreshTodoListPageData()
                // if (arr.length === 1) this.$emit('refresh-todo-info')
                this.useSwalAlertCenter(true, '設定成功!', '待辦已完成')
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '設定失敗!', error.response.data.message)
              })
          }
        })
    },

    // (觸發)刪除
    submitDeleteTodo(arr) {
      if (arr.length <= 0) return

      const [todoId] = arr

      const matchingTodo = this.todoListData.find(todoItem => todoItem.id === todoId)

      this.useSwalAlertWarning('刪除待辦', !matchingTodo || arr.length > 1 ? `你確定要永久刪除所選的 ${arr.length} 個待辦 嗎?` : `你確定要永久刪除待辦 ${matchingTodo.title ? matchingTodo.title : '未命名標題'} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setTodoDelete({
              data: {
                todo_id: arr,
              },
            })
              .then(() => {
                this.refreshTodoListPageData()
                if (arr.length === 1) {
                  if (todoId === this.activeChatContactId) this.$emit('clear-active-info-data')
                }
                this.useSwalAlertCenter(true, '刪除成功!', '待辦已移除')
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },

    // (觸發)選取
    submitSelectedTodo(id) {
      const indexInSelected = this.selectedList.indexOf(id)
      if (indexInSelected !== -1) {
        this.selectedList.splice(indexInSelected, 1)
      } else {
        this.selectedList.push(id)
      }
    },

    // (是否)選取
    isIndexInSelectedTodo(id) {
      return this.selectedList.includes(id)
    },

    // (自動)加載資料
    isScrollBottom() {
      const { scrollTop, scrollHeight, clientHeight } = this.$refs.todoListContent.$el
      if (scrollTop + clientHeight + 1 >= scrollHeight && this.isAll) {
        this.currentPage += 1
      }
    },

    // (開啟)創建時間篩選器
    openCreatedModel() {
      this.$bvModal.show('sidebarFilterCreated')
      setTimeout(() => { this.$refs.dateRangeCustomizeCreated.initDataTime() }, 200)
    },

    // (開啟)截止時間篩選器
    openDeadlineModel() {
      this.$bvModal.show('sidebarFilterDeadline')
      setTimeout(() => { this.$refs.dateRangeCustomizeDeadline.initDataTime() }, 200)
    },

    // (初始化)側欄
    getData() {
      // refetchData()
    },
  },
  setup() {
    const searchTodo = router.currentRoute.query

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      searchOptions,
      setConfigUpdate,
    } = useSearchList()

    const {
      isBusy,
      isBusyLoading,
      todoListData,
      totalNum,
      perPage,
      sortBy,
      isSortDirDesc,
      currentPage,
      priorityOptions,
      statusOptions,
      departmentsOptions,
      adminBrandOptions,
      executionTypeOptions,
      typeOptions,
      typeGroupOptions,
      executionTypeGroupOptions,
      searchPriority,
      searchStatus,
      searchQuery,
      searchDisplay,
      searchDisplayself,
      searchDepartment,
      searchExecutionType,
      searchType,
      searchAdminBrand,
      searchTimeWarning,
      searchTimeDeadline,
      timeRange,
      timeWarningOptions,
      customSearch,
      getTodoListInitData,
      getTodoListNextData,
      refreshTodoListPageData,
      setTodoUpdate,
      setTodoDelete,
      setTodoViewUpdate,
      setTodoComplete,
      setTodoReview,
      refetchData,
      selectedList,
      syncObject,
    } = useTodoList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    if (searchTodo.fid) {
      searchQuery.value = `#${searchTodo.fid}`
    }

    getTodoListInitData()

    return {
      perfectScrollbarSettings,

      isBusy,
      isBusyLoading,
      todoListData,
      totalNum,
      perPage,
      sortBy,
      isSortDirDesc,
      currentPage,
      searchPriority,
      priorityOptions,
      statusOptions,
      departmentsOptions,
      adminBrandOptions,
      executionTypeOptions,
      typeOptions,
      typeGroupOptions,
      executionTypeGroupOptions,
      searchStatus,
      searchQuery,
      searchDisplay,
      searchDisplayself,
      searchDepartment,
      searchExecutionType,
      searchType,
      searchAdminBrand,
      searchTimeWarning,
      searchTimeDeadline,
      timeRange,
      timeWarningOptions,
      customSearch,
      // useAudio,
      // useFullScreen,
      getTodoListNextData,
      getTodoListInitData,
      refreshTodoListPageData,
      setTodoUpdate,
      setTodoDelete,
      setTodoViewUpdate,
      setTodoComplete,
      setTodoReview,
      refetchData,
      selectedList,

      useAlertToast,
      useHttpCodeAlert,
      syncObject,

      searchOptions,
      setConfigUpdate,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-context.scss';
</style>

<style lang="scss" scoped>
.todo-link-btn {
  transition: transform ease-out 200ms;
  // padding: 5px;
  border-radius: 5px;
  .todo-link-btn-image {
    width: 22px;
    height: 22px;
  }

  &:hover {
    cursor: pointer;
    .todo-link-btn-image {
      transform: scale(1.1);
      // animation: btn-breathe .8s linear infinite;
    }
  }
}

.chat-contact {
  position: relative;
}

.context-menu-title {
  padding: 0.26rem 1rem;
  max-width: 150px;
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.context-menu-btn {
  transition: transform ease-out 200ms;
  // padding: 5px;
  border-radius: 5px;
  .context-menu-btn-image {
    width: 18px;
    height: 18px;
  }

  &:hover {
    cursor: pointer;
    // background-color: #a4a9b080;
    .context-menu-btn-image {
      transform: scale(1.05);
      animation: btn-breathe .8s linear infinite;
    }
  }
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  // display: flex;
  // align-items: center;
  .dropdown-item-btn-image {
    width: 12px;
    height: 12px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-todo-dropdown .8s linear infinite;
    }
  }
}

.sidebar-search-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  // height: 100%;
  height: calc(100% - 90px);
}

.sidebar-search-content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.icon-link-btn {
  .icon-link-btn-image {
    height: 25px;
    width: 25px;
    &:hover {
      animation: rotateAnimation 3s linear infinite;
    }
  }
}

.icon-expand-link-btn {
  .icon-expand-link-btn-image {
    height: 25px;
    width: 25px;
  }
  &:hover {
    animation: btn-breathe-todo-dropdown .8s linear infinite;
  }
}

.search-icon-div {
  transition: transform 0.5s ease-in-out;
  .search-icon:hover {
    // background-color: red;
    transform: scale(1.2);
  }
}

.sidebar-search-footer {
  width: 100%;
  border-top: 1px #a4a9b080 solid;
}

.model-header {
  color: white;
  padding: 3px 0;
}
.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
@keyframes btn-breathe-todo-dropdown {
  0%{ transform: scale(0.8); }
  50%{ transform: scale(1.2); }
  100%{ transform: scale(0.8); }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}
</style>
