<template>
  <div>
    <b-modal
      id="todoQuickModal"
      no-close-on-backdrop
      centered
      header-bg-variant="primary"
      body-class="p-0"
      @hidden="handleHideListModal"
      @close="handleHideListModal"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          快速新增自定義
        </h4>
      </template>

      <div class="list-modal">
        <div class="text-nowrap d-flex justify-content-end align-items-center m-1">
          <div
            v-b-tooltip.hover.focus.v-secondary
            title="新增"
            class="d-flex align-items-center actions-link-btn"
            @click="onSubmitUpdate({})"
          >
            <b-img
              src="/admin/images/table/plus.svg"
              class="actions-link-btn-image"
              rounded
            />
          </div>

          <!-- <div
            v-b-tooltip.hover.focus.v-secondary
            title="重新整理"
            class="d-flex align-items-center actions-link-btn ml-25"
            @click="refetchTable"
          >
            <b-img
              src="/admin/images/table/refresh.svg"
              class="actions-link-btn-image"
              rounded
            />
          </div> -->

          <!-- <div
            v-b-tooltip.hover.focus.v-secondary
            title="篩選器"
            class="d-flex align-items-center actions-link-btn ml-25"
          >
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              right
            >
              <template #button-content>
                <b-img
                  src="/admin/images/table/filter.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </template>

              <b-dropdown-form style="min-width: 300px">
                <b-form-group>
                  <label class="mb-50">
                    搜尋
                  </label>

                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="searchQuery"
                      debounce="500"
                      class="d-inline-block"
                      placeholder="搜尋..."
                    />
                    <b-input-group-append
                      v-if="searchQuery"
                      is-text
                    >
                      <feather-icon
                        icon="XIcon"
                        class="cursor-pointer text-muted"
                        @click="searchQuery = null"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-dropdown-form>

            </b-dropdown>
          </div> -->
        </div>

        <div>
          <!-- v-if="dragState" -->
          <b-table
            ref="refDataListTable"
            :items="tableData"
            :fields="tableColumns"
            responsive
            striped
            hover
            primary-key="id"
            show-empty
            :busy="isTableBusy"
            class="m-0 position-relative"
            :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <!-- 忙碌中 -->
            <template #table-busy>
              <b-skeleton-table
                :rows="5"
                :columns="tableColumns.length"
                :table-props="{ bordered: true, striped: true }"
              />
            </template>

            <!-- 查無資料 -->
            <template #empty>
              <div class="text-center my-5 animate__animated animate__fadeIn">
                <b-img
                  :src="$store.state.app.notFoundImg"
                  fluid
                  width="480"
                  alt="查無資料"
                />
              </div>
            </template>

            <!-- 欄位: 編號 -->
            <template #cell(id)="data">
              <div style="min-width: 40px">
                <span>{{ data.item.id }}</span>
              </div>
            </template>

            <!-- 欄位: 名稱 -->
            <template #cell(name)="data">
              <div class="table-title">
                <b-link
                  class="font-weight-bold d-block text-nowrap mb-0"
                  @click="onSubmitUpdate(data.item)"
                >
                  <div class="item-description">
                    {{ data.item.name }}
                  </div>
                </b-link>
                <small class="text-muted">{{ data.item.account }}</small>
              </div>
            </template>

            <!-- 欄位: 動作 -->
            <template #cell(actions)="data">
              <div class="d-flex mb-50">
                <div
                  class="actions-link-btn-sm mr-25"
                  @click="onSubmitUpdate(data.item)"
                >
                  <b-img
                    v-b-tooltip.hover.v-secondary
                    title="編輯"
                    src="/admin/images/table/edit.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  class="actions-link-btn-sm mr-25"
                  @click="onSubmitDelete(data.item)"
                >
                  <b-img
                    v-b-tooltip.hover.v-secondary
                    title="刪除"
                    src="/admin/images/table/delete.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            :disabled="isTableBusy"
          >
            <!--  || (botInfo.id === null && selectBotData.id === null) -->
            <span v-if="!isTableBusy">確認</span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="todoQuickUpdateModal"
      no-close-on-backdrop
      centered
      @hidden="handleHideQuickUpdateModal"
      @close="handleHideQuickUpdateModal"
    >
      <template #modal-title>
        <h4 class="m-0">
          自定義功能
        </h4>
      </template>

      <div>
        <div v-if="!isLoadingBusy && configData">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <!-- 自定義名稱 -->
              <validation-provider name="自定義名稱">
                <b-form-group label-for="title">
                  <template #label>
                    <label class="mb-0 pb-0">自定義名稱</label>
                  </template>
                  <b-form-input
                    id="name"
                    ref="titleInput"
                    v-model="configData.name"
                    trim
                    placeholder="請輸入自定義名稱"
                  />
                </b-form-group>
              </validation-provider>

            </b-form>
          </validation-observer>

          <div class="my-2">
            <h5 class="text-body mb-0">
              基本設定
            </h5>
          </div>

          <div>
            <!-- 待辦標題 -->
            <validation-provider name="待辦標題">
              <b-form-group label-for="title">
                <template #label>
                  <label class="mb-0 pb-0">待辦標題</label>
                </template>

                <div class="d-flex align-items-center">
                  <div class="mr-1">
                    <toggle-button
                      v-model="configData.content.setting.show_title"
                      :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                      :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                      :width="60"
                      :height="24"
                      class="m-25"
                      :labels="{checked: '顯示', unchecked: '隱藏'}"
                      :sync="true"
                    />
                  </div>

                  <b-form-input
                    id="title"
                    ref="titleInput"
                    v-model="configData.content.data.title"
                    trim
                    placeholder="未命名標題"
                  />
                </div>
              </b-form-group>
            </validation-provider>

            <!-- 委託客戶 -->
            <validation-provider name="委託客戶">
              <b-form-group label-for="client_name">
                <template #label>
                  <div class="d-flex align-items-center">
                    <label class="mb-0">委託客戶(手動)</label>

                    <span>
                      <div
                        v-b-tooltip.hover.v-secondary
                        title="可辨識名稱即可"
                        class="d-flex align-items-center"
                        style="padding: 0 5px;"
                      >
                        <b-img
                          src="/admin/images/common/info.svg"
                          class="actions-link-btn-image cursor-pointer"
                          rounded
                          style=" width: 14px; height: 14px;"
                        />
                      </div>
                    </span>
                  </div>
                </template>

                <div class="d-flex align-items-center">
                  <div class="mr-1">
                    <toggle-button
                      v-model="configData.content.setting.show_client_name"
                      :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                      :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                      :width="60"
                      :height="24"
                      class="m-25"
                      :labels="{checked: '顯示', unchecked: '隱藏'}"
                      :sync="true"
                    />
                  </div>

                  <b-form-input
                    id="client_name"
                    v-model="configData.content.data.client_name"
                    trim
                    placeholder="委託客戶 預設值"
                  />
                </div>

              </b-form-group>
            </validation-provider>

            <!-- 委託客戶 -->
            <validation-provider name="委託客戶">
              <b-form-group label-for="customer_id">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">委託客戶(資料庫)</label>

                    <span>
                      <div
                        v-b-tooltip.hover.v-secondary
                        title="新增客戶"
                        class="d-flex align-items-center actions-link-btn-sm"
                        @click="submitCustomerUpdateModal(null)"
                      >
                        <b-img
                          src="/admin/images/table/plus.svg"
                          class="actions-link-btn-image"
                          rounded
                        />
                      </div>
                    </span>
                  </div>
                </template>

                <div class="d-flex">
                  <div class="mr-1 mt-25">
                    <toggle-button
                      v-model="configData.content.setting.show_client_name"
                      :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                      :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                      :width="60"
                      :height="24"
                      class="m-25"
                      :labels="{checked: '顯示', unchecked: '隱藏'}"
                      :sync="true"
                    />
                  </div>

                  <div class="w-100">
                    <v-select
                      v-model="configData.content.data.customer"
                      :options="customerListOptions"
                      label="name"
                      placeholder="請選擇委託客戶 預設值"
                      :filterable="false"
                      @search="fetchCustomerOptions"
                      @input="() => configData.content.data.customer_id = configData.content.data.customer ? configData.content.data.customer.id : null"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          沒有<em>{{ search }}</em> 的相關結果
                          <span
                            class="text-primary cursor-pointer"
                            @click="submitCustomerUpdateModal({name: search})"
                          >
                            立即新增
                          </span>
                        </template>

                        <div v-else>
                          <small>暫無資料</small>
                        </div>
                      </template>

                      <template v-slot:option="option">
                        <div class="d-flex align-items-center">
                          <div>
                            <b-avatar
                              variant="secondary"
                              :src="option.image"
                            />
                          </div>

                          <div class="ml-1">
                            <div class="font-weight-bold d-block text-nowrap mb-0 text-truncate">
                              {{ option.name }}
                            </div>
                            <small class="text-muted">{{ option.email }}</small>
                          </div>
                        </div>
                      </template>

                      <template #selected-option="option">
                        <div class="text-nowrap mb-0 text-truncate">
                          {{ option.name }}
                        </div>
                      </template>
                    </v-select>

                    <div
                      v-if="configData.content.data.customer"
                      class="p-1 border mt-50"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center auth-users"
                      >
                        <div class="d-flex justify-content-start align-items-center">
                          <div class="mr-1">
                            <b-avatar
                              size="40"
                              variant="light-primary"
                              :src="configData.content.data.customer.image"
                            />
                          </div>

                          <div class="profile-user-info">
                            <h6 class="mb-0">
                              <div class="text-truncate">
                                {{ configData.content.data.customer.name }}
                              </div>
                            </h6>
                            <small
                              class="text-muted text-truncate"
                              style="word-break: break-all"
                            >
                              {{ configData.content.data.customer.email }}
                            </small>
                          </div>
                        </div>

                        <div class="text-nowrap">
                          <b-button
                            v-b-tooltip.hover.focus.v-secondary
                            title="編輯"
                            variant="flat-secondary"
                            size="sm"
                            class="text-nowrap p-50 btn-icon"
                            @click="submitCustomerUpdateModal(configData.content.data.customer)"
                          >
                            <feather-icon icon="EditIcon" />
                          </b-button>

                          <b-button
                            v-b-tooltip.hover.focus.v-secondary
                            title="詳情"
                            variant="flat-secondary"
                            size="sm"
                            class="text-nowrap p-50 btn-icon"
                            @click="submitCustomerLinkUser(configData.content.data.customer)"
                          >
                            <feather-icon icon="ExternalLinkIcon" />
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </validation-provider>

            <!-- 群組 -->
            <b-form-group label-for="group">
              <template #label>
                <label class="mb-0">
                  群組
                </label>
              </template>

              <div class="d-flex align-items-center">
                <div class="mr-1">
                  <toggle-button
                    v-model="configData.content.setting.show_group_id"
                    :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                    :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                    :width="60"
                    :height="24"
                    class="m-25"
                    :labels="{checked: '顯示', unchecked: '隱藏'}"
                    :sync="true"
                  />
                </div>

                <v-select
                  v-model="configData.content.data.group_id"
                  :options="groupOptions"
                  :clearable="false"
                  label="name"
                  :reduce="option => option.id"
                  placeholder="群組 預設值"
                  class="w-100"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </div>

            </b-form-group>

            <!-- 優先程度 -->
            <b-form-group label-for="priority">
              <template #label>
                <label class="mb-0">
                  優先程度
                </label>
              </template>

              <div class="d-flex align-items-center">
                <div class="mr-1">
                  <toggle-button
                    v-model="configData.content.setting.show_priority"
                    :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                    :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                    :width="60"
                    :height="24"
                    class="m-25"
                    :labels="{checked: '顯示', unchecked: '隱藏'}"
                    :sync="true"
                  />
                </div>

                <v-select
                  v-model="configData.content.data.priority"
                  :options="priorityOptions"
                  :clearable="false"
                  label="title"
                  :reduce="option => option.value"
                  placeholder="優先程度 預設值"
                  class="w-100"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>

                  <template v-slot:option="option">
                    <div class="d-flex align-items-center">
                      <span
                        class="bullet mr-1 border filter-dot"
                        :style="{'background-color': option.color}"
                      />
                      {{ option.title }} ( {{ option.sign }} )
                    </div>
                  </template>

                  <template #selected-option="option">
                    <div class="d-flex align-items-center">
                      <span
                        class="bullet mr-1 border filter-dot"
                        :style="{'background-color': option.color}"
                      />
                      {{ option.title }} ( {{ option.sign }} )
                    </div>
                  </template>
                </v-select>
              </div>
            </b-form-group>
          </div>

          <div
            class="my-2 cursor-pointer d-flex justify-content-between align-items-center"
            @click="showMoreSetting = !showMoreSetting"
          >
            <div>
              <h5 class="text-body mb-0">
                進階設定
              </h5>
            </div>
            <div class="text-right show-more-arrow">
              <span :class="{ 'show': showMoreSetting }">
                {{ !showMoreSetting ? '顯示更多' : '' }}
              </span>
              <!-- animate__animated animate__backOutLeft -->
              <feather-icon
                :icon="'ChevronRightIcon'"
                size="16"
                class="align-bottom cursor-pointer mb-25"
                :class="{ 'active': showMoreSetting }"
              />
            </div>
          </div>

          <b-collapse v-model="showMoreSetting">
            <!-- 顯示狀態 -->
            <b-form-group label-for="display_status">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <label class="mb-0">顯示狀態</label>

                    <span>
                      <div
                        v-b-tooltip.hover.v-secondary
                        title="設定為關閉，會在排定時間自動開啟"
                        class="d-flex align-items-center"
                        style="padding: 0 5px;"
                      >
                        <b-img
                          src="/admin/images/common/info.svg"
                          class="actions-link-btn-image cursor-pointer"
                          rounded
                          style=" width: 14px; height: 14px;"
                        />
                      </div>
                    </span>
                  </div>

                  <toggle-button
                    v-model="configData.content.data.display_status"
                    :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                    :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                    :width="60"
                    :height="24"
                    class="m-25 mb-50"
                    :labels="{checked: '開啟', unchecked: '關閉'}"
                    :sync="true"
                  />
                </div>
              </template>
            </b-form-group>

            <!-- 狀態 -->
            <b-form-group label-for="status">
              <template #label>
                <label class="mb-0">
                  狀態
                </label>
              </template>

              <v-select
                v-model="configData.content.data.status"
                :options="todoStatusOptions"
                :clearable="false"
                label="title"
                :reduce="option => option.value"
                placeholder="請選擇狀態"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="bullet mr-1 border filter-dot"
                      :style="{'background-color': option.color}"
                    />
                    {{ option.title }} ( {{ option.value }} )
                  </div>
                </template>

                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="bullet mr-1 border filter-dot"
                      :style="{'background-color': option.color}"
                    />
                    {{ option.title }} ( {{ option.value }} )
                  </div>
                </template>
              </v-select>
            </b-form-group>

            <!-- 截止時間 -->
            <b-form-group label-for="deadline_at">
              <template #label>
                <label class="mb-0">截止時間</label>
              </template>

              <div class="d-flex align-items-center">
                <div class="mr-1">
                  <toggle-button
                    v-model="configData.content.setting.show_deadline_at"
                    :color="{checked: '#4683bb', unchecked: '#20c997'}"
                    :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                    :width="60"
                    :height="24"
                    class="m-25"
                    :labels="{checked: '預設', unchecked: '自動'}"
                    :sync="true"
                  />
                </div>

                <div
                  v-if="configData.content.setting.show_deadline_at"
                  class="w-100"
                >
                  <flat-pickr
                    v-model="configData.content.data.deadline_at"
                    class="date-form-control form-control"
                    :config="dateConfig"
                    placeholder="YYYY-MM-DD"
                  />
                </div>

                <div v-else>
                  <div class="mt-25">
                    <b-badge
                      v-for="btn in timeOptions"
                      :key="btn.value"
                      :variant="configData.content.setting.set_deadline_at === btn.value ? 'primary' : 'light-primary'"
                      class="mr-50 mb-25 cursor-pointer px-1 py-50"
                      @click="() => {
                        configData.content.setting.set_deadline_at = btn.value
                      }"
                    >
                      {{ btn.label }}
                    </b-badge>
                  </div>
                </div>

              </div>

              <!-- show_scheduled_at: false,
              set_scheduled_at: null,
              show_deadline_at: false,
              set_deadline_at: null, -->
            </b-form-group>

            <!-- 排定時間 -->
            <b-form-group label-for="scheduled_at">
              <template #label>
                <div class="d-flex align-items-center">
                  <label class="mb-0">排定時間</label>

                  <span>
                    <div
                      v-b-tooltip.hover.v-secondary
                      title="指定時間此待辦才會顯示出來"
                      class="d-flex align-items-center"
                      style="padding: 0 5px;"
                    >
                      <b-img
                        src="/admin/images/common/info.svg"
                        class="actions-link-btn-image cursor-pointer"
                        rounded
                        style=" width: 14px; height: 14px;"
                      />
                    </div>
                  </span>
                </div>
              </template>

              <div class="d-flex align-items-center">
                <div class="mr-1">
                  <toggle-button
                    v-model="configData.content.setting.show_scheduled_at"
                    :color="{checked: '#4683bb', unchecked: '#20c997'}"
                    :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                    :width="60"
                    :height="24"
                    class="m-25"
                    :labels="{checked: '預設', unchecked: '自動'}"
                    :sync="true"
                  />
                </div>

                <div
                  v-if="configData.content.setting.show_scheduled_at"
                  class="w-100"
                >
                  <flat-pickr
                    v-model="configData.content.data.scheduled_at"
                    class="date-form-control form-control"
                    :config="dateConfig"
                    placeholder="YYYY-MM-DD"
                  />
                </div>

                <div v-else>
                  <div class="mt-25">
                    <b-badge
                      v-for="btn in timeOptions"
                      :key="btn.value"
                      :variant="configData.content.setting.set_scheduled_at === btn.value ? 'primary' : 'light-primary'"
                      class="mr-50 mb-25 cursor-pointer px-1 py-50"
                      @click="() => {
                        configData.content.setting.set_scheduled_at = btn.value
                      }"
                    >
                      {{ btn.label }}
                    </b-badge>
                  </div>
                </div>

              </div>

              <!-- show_scheduled_at: false,
              set_scheduled_at: null,
              show_deadline_at: false,
              set_deadline_at: null, -->
            </b-form-group>
            <!-- <b-form-group label-for="scheduled_at">
              <template #label>
                <div class="d-flex align-items-center">
                  <label class="mb-0">排定時間</label>

                  <span>
                    <div
                      v-b-tooltip.hover.v-secondary
                      title="指定時間此待辦才會顯示出來"
                      class="d-flex align-items-center"
                      style="padding: 0 5px;"
                    >
                      <b-img
                        src="/admin/images/common/info.svg"
                        class="actions-link-btn-image cursor-pointer"
                        rounded
                        style=" width: 14px; height: 14px;"
                      />
                    </div>
                  </span>
                </div>
              </template>

              <flat-pickr
                v-model="configData.content.data.scheduled_at"
                class="date-form-control form-control"
                :config="{
                  ...dateConfig,
                  minDate: new Date(),
                }"
                placeholder="YYYY-MM-DD"
              />

              <div class="mt-25">
                <b-badge
                  v-for="btn in timeOptions"
                  :key="btn.value"
                  variant="light-primary"
                  class="mr-50 mt-25 cursor-pointer badge-btn"
                  @click="choiceScheduledTime(btn.value)"
                >
                  {{ btn.label }}
                </b-badge>
              </div>
            </b-form-group> -->

            <!-- 負責品牌 -->
            <b-form-group label-for="admin_brand_id">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">負責品牌</label>

                  <span>
                    <div
                      v-b-tooltip.hover.v-secondary
                      title="公司品牌設定"
                      class="d-flex align-items-center actions-link-btn-sm"
                      @click="() => { $refs.adminBrandExplorerQuickModal.getData(null) }"
                    >
                      <b-img
                        src="/admin/images/table/settings.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>
                  </span>
                </div>
              </template>

              <v-select
                v-model="configData.content.data.admin_brand_id"
                :options="adminBrandOptions"
                label="name"
                :reduce="option => option.id"
                placeholder="請選擇品牌"
              >
                <!-- :clearable="false" -->
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>

            <!-- 負責部門 -->
            <b-form-group label-for="department_id">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <label class="mb-0">負責部門</label>

                  <span>
                    <div
                      v-b-tooltip.hover.v-secondary
                      title="部門設定"
                      class="d-flex align-items-center actions-link-btn-sm"
                      @click="() => { $refs.departmentExplorerQuickModal.getData(null) }"
                    >
                      <b-img
                        src="/admin/images/table/settings.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>
                  </span>
                </div>
              </template>

              <v-select
                v-model="configData.content.data.department_id"
                :options="departmentsOptions"
                label="name"
                :reduce="option => option.id"
                placeholder="請選擇部門"
                @input="() => {
                  filterTodoTypeOptions(configData.content.data.department_id)
                  filterTodoExecutionTypeOptions(configData.content.data.department_id)
                }"
              >
                <!-- :clearable="false" -->
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>

            <div>
              <!-- 作業分類 -->
              <b-form-group label-for="type_id">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">
                      作業分類
                    </label>

                    <span>
                      <div
                        v-b-tooltip.hover.v-secondary
                        title="作業分類設定"
                        class="d-flex align-items-center actions-link-btn-sm"
                        @click="submitTodoTypeExplorerModal"
                      >
                        <b-img
                          src="/admin/images/table/settings.svg"
                          class="actions-link-btn-image"
                          rounded
                        />
                      </div>
                    </span>
                  </div>
                </template>

                <v-select
                  v-model="configData.content.data.type_id"
                  :options="typeOptions"
                  label="name"
                  :reduce="option => option.id"
                  placeholder="請選擇作業分類"
                  :disabled="!configData.content.data.department_id"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>

              <!-- 項目設定 -->
              <b-form-group label-for="execution_type_id">
                <template #label>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0">項目設定</label>

                    <span>
                      <div
                        v-b-tooltip.hover.v-secondary
                        title="項目設定設定"
                        class="d-flex align-items-center actions-link-btn-sm"
                        @click="submitTodoExecutionTypeExplorerModal"
                      >
                        <b-img
                          src="/admin/images/table/settings.svg"
                          class="actions-link-btn-image"
                          rounded
                        />
                      </div>
                    </span>
                  </div>
                </template>

                <v-select
                  v-model="configData.content.data.execution_type_id"
                  :options="executionTypeOptions"
                  label="name"
                  :reduce="option => option.id"
                  placeholder="請選擇項目設定"
                  :disabled="!configData.content.data.department_id"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </div>
          </b-collapse>

          <!-- {{ configData }} -->
        </div>

        <div
          v-else
          class="loading-area"
        >
          <b-img
            src="/admin/images/common/loading-2.png"
            rounded
            height="60"
            width="60"
          />
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            :disabled="isUpdateBusy"
            @click="handleOk"
          >
            <!--  || (botInfo.id === null && selectBotData.id === null) -->
            <span v-if="!isUpdateBusy">確認</span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </template>
    </b-modal>

    <department-explorer-modal
      v-if="configData"
      ref="departmentExplorerQuickModal"
      department-explorer-id="departmentExplorerQuickModal"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

    <admin-brand-explorer-modal
      v-if="configData"
      ref="adminBrandExplorerQuickModal"
      brand-explorer-id="adminBrandExplorerQuickModal"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

    <todo-type-explorer-modal
      v-if="configData"
      ref="todoTypeExplorerQuickModal"
      todo-type-explorer-id="todoTypeExplorerQuickModal"
      :table-filter="{ department_id: configData.content.data.department_id}"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

    <todo-execution-type-explorer-modal
      v-if="configData"
      ref="todoExecutionTypeExplorerQuickModal"
      todo-execution-type-explorer-id="todoExecutionTypeExplorerQuickModal"
      :table-filter="{ department_id: configData.content.data.department_id}"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

    <user-customer-explorer-modal
      ref="userCustomerExplorerQuickListModal"
      user-explorer-id="userCustomerExplorerQuickListModal"
      :admin-brand-options="adminBrandOptions"
      @call-back-data="callbackCustomerUpdate"
    />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import {
  BModal, BButton, VBTooltip, BImg, BTable, BSkeletonTable, BSpinner, BFormGroup, BFormInput, BForm, BLink,
  BCollapse, BBadge, BAvatar,
  // BRow, BCol,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import flatPickr from 'vue-flatpickr-component'
// import Ripple from 'vue-ripple-directive'
import { ToggleButton } from 'vue-js-toggle-button'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { useTodoSetting, useQuickList, useTodoList } from '../useTodo'
import { useAlert, useSwalToast } from '@/libs/mixins/index'
import DepartmentExplorerModal from '@/layouts/components/Modal/department-modal/departmentModal.vue'
import AdminBrandExplorerModal from '@/layouts/components/Modal/admin-brand-modal/brandModal.vue'
import TodoTypeExplorerModal from '@/layouts/components/Modal/todo-type-modal/todoTypeModal.vue'
import TodoExecutionTypeExplorerModal from '@/layouts/components/Modal/todo-execution-type-modal/todoExecutionTypeModal.vue'
import userCustomerExplorerModal from './UserCustomerModal.vue'
// import store from '@/store'
// import MyEmojiComponent from '@/layouts/components/EmojiPicker/EmojiPicker.vue'
// import MyDropdown from '@/layouts/components/Dropdown/Dropdown.vue'

export default {
  components: {
    // BRow,
    // BCol,
    BImg,
    BForm,
    BModal,
    BButton,
    BTable,
    BSpinner,
    BSkeletonTable,
    BFormGroup,
    BFormInput,
    BLink,
    BCollapse,
    BBadge,
    BAvatar,
    // MyEmojiComponent,
    // MyDropdown,

    vSelect,
    flatPickr,
    ToggleButton,
    ValidationProvider,
    ValidationObserver,

    DepartmentExplorerModal,
    AdminBrandExplorerModal,
    TodoTypeExplorerModal,
    TodoExecutionTypeExplorerModal,
    userCustomerExplorerModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
  },
  data() {
    return {
      isLoadingBusy: true,
      isUpdateBusy: false,
      missingFields: [],
      showMoreSetting: false,
    }
  },
  methods: {
    moment,
    // (查詢)使用者
    fetchCustomerOptions(search, loading) {
      if (search.length) {
        loading(true)
        this.getCustomerListData({
          search,
          limit: 100,
          page: 1,
          _: Date.now(),
        })
          .then(response => {
            const { data } = response.data.data
            this.customerListOptions = data.map(item => this.syncObjectDeep(this.blankCustomerData, item))
            loading(false)
          })
      } else {
        this.getCustomerListData({
          limit: 100,
          page: 1,
          _: Date.now(),
        })
          .then(response => {
            const { data } = response.data.data
            this.customerListOptions = data.map(item => this.syncObjectDeep(this.blankCustomerData, item))
          })
      }
    },

    // (查詢)使用者
    fetchCustomerData(searchId) {
      this.getCustomerData({
        customer_id: searchId,
      })
        .then(response => {
          const { data } = response.data
          this.configData.content.data.customer = this.syncObjectDeep(this.blankCustomerData, data)
        })
    },

    // (觸發)新增客戶彈窗
    submitCustomerUpdateModal(customer) {
      setTimeout(() => { this.$refs.userCustomerExplorerQuickListModal.getData(customer) }, 200)
    },

    // (觸發)跳轉
    submitCustomerLinkUser(customer) {
      if (!customer.id) return
      const routeUrl = `${window.location.origin}/admin/customer/${customer.id}`
      window.open(routeUrl, '_blank')
    },

    // (回傳)
    callbackCustomerUpdate(customer) {
      this.configData.content.data.customer = this.syncObject(this.blankCustomerData, customer)
    },
    // --------------------------------------------------------------------------------------------
    // (顯示)彈窗
    handleShowListModal() {
      // const [groupOption] = this.$store.state['admin-todo'].groupOptions
      // this.groupId = groupOption
      this.getTodoConfigList()
      this.$bvModal.show('todoQuickModal')
    },

    // (關閉)彈窗
    handleHideListModal() {
      this.$emit('refresh-todo-list')
      if (this.isTableBusy) return
      this.$bvModal.hide('todoQuickModal')
    },
    // ------------------------------------------------------------------------------------------------
    // (觸發)作業分類彈窗
    submitTodoTypeExplorerModal() {
      if (!this.configData.content.data.department_id) {
        this.useSwalAlertCenter(false, '尚未選擇部門', '請先選擇待辦部門')
        return
      }
      this.$refs.todoTypeExplorerQuickModal.getData(null)
    },

    // (觸發)項目設定彈窗
    submitTodoExecutionTypeExplorerModal() {
      if (!this.configData.content.data.department_id) {
        this.useSwalAlertCenter(false, '尚未選擇部門', '請先選擇待辦部門')
        return
      }
      this.$refs.todoExecutionTypeExplorerQuickModal.getData(null)
    },

    // (過濾)作業分類
    filterTodoTypeOptions(departmentId) {
      const findTypeGroup = this.typeGroupOptions.find(el => el.department_id === departmentId)
      if (findTypeGroup === undefined) {
        this.configData.content.data.type_id = null
        this.typeOptions = []
        return
      }

      this.typeOptions = findTypeGroup.data

      const findType = this.typeOptions.find(el => el.id === this.configData.content.data.type_id)

      if (findType === undefined) {
        this.configData.content.data.type_id = null
      }
    },

    // (過濾)項目設定
    filterTodoExecutionTypeOptions(departmentId) {
      const findexEcutionTypeGroup = this.executionTypeGroupOptions.find(el => el.department_id === departmentId)
      if (findexEcutionTypeGroup === undefined) {
        this.configData.content.data.execution_type_id = null
        this.executionTypeOptions = []
        return
      }

      this.executionTypeOptions = findexEcutionTypeGroup.data

      const findexEcutionType = this.executionTypeOptions.find(el => el.id === this.configData.content.data.execution_type_id)

      if (findexEcutionType === undefined) {
        this.configData.content.data.execution_type_id = null
      }
    },
    // ------------------------------------------------------------------------------------------------
    // (顯示)新增/編輯彈窗
    onSubmitUpdate(item) {
      this.isLoadingBusy = true
      this.$bvModal.show('todoQuickUpdateModal')
      const resolveData = this.syncObject(this.blankConfigData, item)
      resolveData.content = this.syncObject({ data: {}, setting: {} }, resolveData.content)
      resolveData.content.data = this.syncObject(this.blankContentData, resolveData.content.data)
      resolveData.content.setting = this.syncObject(this.blankContentSetting, resolveData.content.setting)
      this.configData = resolveData

      if (this.configData.content.data.customer_id) this.fetchCustomerData(this.configData.content.data.customer_id)

      this.getMetaListData()
        .then(response => {
          // 陣列更新中
          const { departments, priority, groups } = response.data.data
          this.departmentsOptions = departments
          this.priorityOptions = priority
          this.groupOptions = groups
          if (groups.length > 0) this.configData.content.data.group_id = groups[0].id

          const adminBrands = response.data.data.admin_brands
          this.adminBrandOptions = adminBrands

          const todoTypes = response.data.data.todo_types
          this.typeGroupOptions = todoTypes

          const todoExecutionTypes = response.data.data.execution_types
          this.executionTypeGroupOptions = todoExecutionTypes

          const todoStatus = response.data.data.todo_status
          this.todoStatusOptions = todoStatus

          // 資料同步
          // const resolveData = {
          //   ...this.syncObject(this.blankTodoData, this.contact.data),
          // }

          // 判斷是否有在陣列中
          const findDepartment = departments.find(el => el.id === this.configData.content.data.department_id)

          if (findDepartment === undefined) {
            this.configData.content.data.department_id = null
          } else {
            this.filterTodoTypeOptions(this.configData.content.data.department_id)
            this.filterTodoExecutionTypeOptions(this.configData.content.data.department_id)
          }
          this.isLoadingBusy = false
        })
        .catch(() => {
          this.isLoadingBusy = false
        })

      this.getCustomerListData({
        limit: 100,
        page: 1,
        _: Date.now(),
      })
        .then(response => {
          const { data } = response.data.data
          this.customerListOptions = data.map(el => this.syncObjectDeep(this.blankCustomerData, el))
        })
    },

    // (刪除)分類
    onSubmitDelete(item) {
      this.useSwalAlertWarning('刪除自定義新增', `你確定要永久刪除自定義新增 ${item.name} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setTodoConfigDelete({
              config_id: item.id,
            }).then(() => {
              this.refetchTable()
              this.useSwalAlertCenter(true, '刪除成功!', '自定義新增已移除')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },

    handleOk() {
      // bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '自訂義名稱',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.configData[Object.keys(requiredFields)[i]] === null || this.configData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      // setTodoConfigDelete,
      // setTodoConfigCreate,
      // setTodoConfigUpdate,
      // isUpdateBusy

      this.isUpdateBusy = true

      if (this.configData.id) {
        this.setTodoConfigUpdate({
          config_id: this.configData.id,
          data: {
            ...this.configData,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.refetchTable()
            this.$nextTick(() => {
              this.handleHideQuickUpdateModal()
            })
            this.isUpdateBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isUpdateBusy = false
          })
      } else {
        this.setTodoConfigCreate({
          ...this.configData,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.refetchTable()
            this.$nextTick(() => {
              this.handleHideQuickUpdateModal()
            })
            this.isUpdateBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isUpdateBusy = false
          })
      }
    },

    // (關閉)彈窗
    handleHideQuickUpdateModal() {
      this.$bvModal.hide('todoQuickUpdateModal')
      this.showMoreSetting = false
    },

    // (重新)獲取Meta
    resetMetaList() {
      // this.getMetaListData()
      //   .then(response => {
      //     // 陣列更新中
      //     const { departments, priority } = response.data.data
      //     this.departmentsOptions = departments
      //     this.priorityOptions = priority

      //     const adminBrands = response.data.data.admin_brands
      //     this.adminBrandOptions = adminBrands

      //     const todoTypes = response.data.data.todo_types
      //     this.typeGroupOptions = todoTypes

      //     const executionTypes = response.data.data.execution_types
      //     this.executionTypeGroupOptions = executionTypes

      //     const todoStatus = response.data.data.todo_status
      //     this.todoStatusOptions = todoStatus

      //     // 判斷是否有在陣列中
      //     const findDepartment = departments.find(el => el.id === this.configData.content.data.department_id)

      //     if (findDepartment === undefined) {
      //       this.configData.content.data.department_id = null
      //     } else {
      //       this.filterTodoTypeOptions(this.configData.content.data.department_id)
      //       this.filterTodoExecutionTypeOptions(this.configData.content.data.department_id)
      //     }

      //     const findAdminBrand = adminBrands.find(el => el.id === this.configData.content.data.admin_brand_id)

      //     if (findAdminBrand === undefined) {
      //       this.configData.content.data.admin_brand_id = null
      //     }
      //   })
    },
  },
  setup() {
    localize('tw')
    moment.locale('zh-tw')

    const dateConfig = {
      locale: language.zh_tw,
      dateFormat: 'Y-m-d H:i',
      allowInput: true,
      static: true,
      enableTime: true,
      // inline: true,
    }

    const timeOptions = [
      { label: '1H', value: '1h' },
      { label: '2H', value: '2h' },
      { label: '3H', value: '3h' },
      { label: '5H', value: '5h' },
      { label: '一天', value: '1d' },
      { label: '一周', value: '7d' },
    ]

    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const {
      getMetaListData,
      departmentsOptions,
      adminBrandOptions,
      executionTypeOptions,
      typeGroupOptions,
      executionTypeGroupOptions,
      priorityOptions,
      todoStatusOptions,
      typeOptions,
      syncObject,
      syncObjectDeep,

      blankCustomerData,
      customerListOptions,
      getCustomerListData,
      getCustomerData,
    } = useTodoSetting()

    const {
      setTodoCreateQuick,
    } = useTodoList()

    const {
      tableData,
      isTableBusy,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchTable,

      setTodoConfigDelete,
      setTodoConfigCreate,
      setTodoConfigUpdate,
      getTodoConfigList,

      configData,
      blankContentSetting,
      blankContentData,
      blankConfigData,
    } = useQuickList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    return {
      isDark,

      tableData,
      isTableBusy,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchTable,

      setTodoConfigDelete,
      setTodoConfigCreate,
      setTodoConfigUpdate,
      getTodoConfigList,

      configData,
      blankContentSetting,
      blankContentData,
      blankConfigData,
      blankCustomerData,

      useAlertToast,
      useHttpCodeAlert,
      setTodoCreateQuick,

      getMetaListData,
      departmentsOptions,
      adminBrandOptions,
      executionTypeOptions,
      typeGroupOptions,
      executionTypeGroupOptions,
      priorityOptions,
      todoStatusOptions,
      typeOptions,
      syncObject,
      syncObjectDeep,
      dateConfig,
      timeOptions,

      customerListOptions,
      getCustomerData,
      getCustomerListData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.show-more-arrow {
  transition: transform 0.3s ease-in-out;
  .active {
    transition: transform 0.3s ease-in-out;
    transform: rotate(90deg);
  }
}

.text-truncate {
  max-width: 200px;
}
</style>
