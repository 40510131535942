var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.updateModalId,"no-close-on-backdrop":"","ok-title":"確認","centered":"","header-bg-variant":"primary","ok-only":""},on:{"hidden":_vm.handleHide},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h4',{staticClass:"m-0 model-header"},[_vm._v(" "+_vm._s(_vm.selectedItem.id ? '編輯' : '新增')+"作業分類 ")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 text-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isBusy},on:{"click":_vm.handleOk}},[(!_vm.isBusy)?_c('span',[_vm._v("確認")]):_c('b-spinner',{attrs:{"small":""}})],1)],1)]},proxy:true}])},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[(!_vm.selectedItem.id)?_c('b-form-group',{attrs:{"label-for":"department_id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 部門 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,true)},[_c('v-select',{attrs:{"options":_vm.departmentsOptions,"clearable":false,"label":"name","reduce":function (option) { return option.id; },"placeholder":"請選擇部門"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,true),model:{value:(_vm.classData.department_id),callback:function ($$v) {_vm.$set(_vm.classData, "department_id", $$v)},expression:"classData.department_id"}})],1):_vm._e(),_c('validation-provider',{attrs:{"name":"作業分類名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"mb-0"},[_vm._v(" 作業分類名稱 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"name","autofocus":"","state":errors.length > 0 ? false : null,"trim":"","placeholder":_vm.classData.name ? _vm.classData.name : '請輸入作業分類名稱'},model:{value:(_vm.classData.name),callback:function ($$v) {_vm.$set(_vm.classData, "name", $$v)},expression:"classData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',[_c('label',[_vm._v("作業分類說明")]),_c('b-form-textarea',{attrs:{"placeholder":_vm.classData.description ? _vm.classData.description : '請輸入作業分類說明',"state":_vm.classData.description.length <= _vm.maxChar ? null : false,"rows":"5"},model:{value:(_vm.classData.description),callback:function ($$v) {_vm.$set(_vm.classData, "description", $$v)},expression:"classData.description"}}),_c('small',{staticClass:"textarea-counter-value float-right",class:_vm.classData.description.length > _vm.maxChar ? 'bg-danger' : ''},[_c('span',{staticClass:"char-count"},[_vm._v(_vm._s(_vm.classData.description.length))]),_vm._v(" / "+_vm._s(_vm.maxChar)+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }