var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":_vm.userExplorerId,"no-close-on-backdrop":"","centered":"","header-bg-variant":"primary"},on:{"hidden":_vm.handleHide,"close":_vm.handleHide},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h4',{staticClass:"m-0 model-header"},[_vm._v(" "+_vm._s(_vm.customerData.id ? '編輯' : '新增')+"委託客戶 ")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isBusy},on:{"click":_vm.handleOk}},[(!_vm.isBusy)?_c('span',[_vm._v("確認")]):_c('b-spinner',{attrs:{"small":""}})],1)],1)]},proxy:true}])},[_c('div',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"客戶名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"mb-0"},[_vm._v(" 客戶名稱 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"name","autofocus":"","state":errors.length > 0 ? false : null,"trim":"","placeholder":"請輸入委託客戶名稱"},model:{value:(_vm.customerData.name),callback:function ($$v) {_vm.$set(_vm.customerData, "name", $$v)},expression:"customerData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"信箱","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"mb-0"},[_vm._v(" 信箱 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"trim":"","placeholder":"請輸入委託客戶信箱"},model:{value:(_vm.customerData.email),callback:function ($$v) {_vm.$set(_vm.customerData, "email", $$v)},expression:"customerData.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',[_c('b-form-group',{attrs:{"label-for":"admin_brand"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"mb-0"},[_vm._v(" 公司品牌 ")])]},proxy:true}],null,true)},[_c('v-select',{attrs:{"options":_vm.adminBrandOptions,"label":"name","reduce":function (option) { return option.id; },"placeholder":"請選擇公司品牌"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}}],null,true),model:{value:(_vm.customerData.admin_brand_id),callback:function ($$v) {_vm.$set(_vm.customerData, "admin_brand_id", $$v)},expression:"customerData.admin_brand_id"}})],1)],1)],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }