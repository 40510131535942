<template>
  <div>

    <b-tabs
      pills
    >
      <b-tab
        title="部門"
        :class="!isDark ? 'chart-content-light' : 'chart-content-dark'"
      >
        <b-card no-body>
          <b-card-header class="pb-0">

            <b-card-title class="mb-0">
              <div class="d-flex">
                部門統計列表 {{ formatDateRange(timeRange) }}
              </div>
            </b-card-title>

            <div>
              <!-- <v-select
                v-model="searchDepartment"
                :options="departmentsOptions"
                :clearable="false"
                label="name"
                :reduce="option => option.id"
                placeholder="請選擇部門"
                class="department-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select> -->
              <span
                v-if="showChart"
                class="d-flex align-items-center actions-link-btn-sm ml-50"
                @click="() => {
                  isBusy = true
                  showChart = false
                  initAnalytic()
                }"
              >
                <!-- getDepartmentLineChart -->
                <b-img
                  src="/admin/images/table/reload.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </span>

              <span
                v-if="!showChart"
                v-b-tooltip.hover.v-secondary
                title="只篩選月份"
                class="d-flex align-items-center ml-50"
              >
                <b-img
                  src="/admin/images/common/info.svg"
                  rounded
                  style=" width: 16px; height: 16px;"
                />
              </span>
            </div>
          </b-card-header>

          <div
            v-if="showChart"
            class="chart-content"
          >
            <div
              v-if="!isTableBusy"
              class="mx-1"
            >
              <vue-apex-charts
                v-if="departmentChartData"
                type="line"
                height="300"
                width="100%"
                :options="departmentChartData.options"
                :series="departmentChartData.series"
              />
            </div>

            <div v-else>
              <div class="chart-content-loading">
                <b-img
                  src="/admin/images/common/loading-2.png"
                  rounded
                  height="60"
                  width="60"
                  alt="loading"
                />
              </div>
            </div>
          </div>

          <div
            v-if="!showChart"
            class="chart-content"
          >
            <div>
              <!-- {{ departmentsOptions }} -->

              <div class="mx-2 mb-2 mt-1">
                <date-range-customize
                  ref="dateRangeCustomizeAnalytic"
                  date-range-customize-id="dateRangeCustomizeAnalytic"
                  :use-time="false"
                  :init-data="timeRange"
                  :max-date-time="moment().format('YYYY-MM-DD HH:mm')"
                  @call-back-time="(time) => timeRange = time"
                >
                  <template slot="quick-time-btn">
                    <div class="mb-50">
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-50 mb-25"
                        @click="getLastYearRange"
                      >
                        去年
                      </b-button>
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-50 mb-25"
                        @click="getThisYearRange"
                      >
                        今年
                      </b-button>
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-50 mb-25"
                        @click="getBeforeMonthRange(6)"
                      >
                        前六個月
                      </b-button>
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-50 mb-25"
                        @click="getBeforeMonthRange(3)"
                      >
                        前三個月
                      </b-button>

                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-50 mb-25"
                        @click="getThisMonthRange"
                      >
                        本月
                      </b-button>
                    </div>
                  </template>
                </date-range-customize>
              </div>

              <b-row
                v-if="!isBusy"
                class="px-2"
              >
                <b-col
                  cols="6"
                  md="4"
                  sm="6"
                >
                  <div
                    class="department-option"
                    :class="{ 'department-option-active': searchDepartment === null }"
                    @click="searchDepartment = null"
                  >
                    全部
                  </div>
                </b-col>

                <b-col
                  v-for="department in departmentsOptions"
                  :key="department.id"
                  cols="6"
                  md="4"
                  sm="6"
                >
                  <div
                    class="department-option"
                    :class="{ 'department-option-active': searchDepartment === department.id }"
                    @click="searchDepartment = department.id"
                  >
                    {{ department.name }}
                  </div>
                </b-col>
              </b-row>

              <div class="d-flex justify-content-end px-2 mb-2">
                <button
                  class="content-analytic"
                  :disabled="isBusy"
                  @click="submitAnalytic"
                >
                  <span class="text">立即統計</span>
                  <svg
                    class="arrow"
                    viewBox="0 0 448 512"
                    height="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </b-card>

        <!-- <b-card
          v-else
          no-body
        >
          <b-card-header class="pb-0">
            <b-card-title class="mb-0">
              <div class="d-flex">
                部門統計列表 {{ formatDateRange(timeRange) }}
              </div>
            </b-card-title>

          </b-card-header>

          <div class="chart-content">
            <div>
              <div class="mx-2 mb-2 mt-1">
                <date-range-customize
                  ref="dateRangeCustomizeAnalytic"
                  date-range-customize-id="dateRangeCustomizeAnalytic"
                  :use-time="false"
                  :init-data="timeRange"
                  :max-date-time="moment().format('YYYY-MM-DD HH:mm')"
                  @call-back-time="(time) => timeRange = time"
                >
                  <template slot="quick-time-btn">
                    <div class="mb-50">
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-50 mb-25"
                        @click="getLastYearRange"
                      >
                        去年
                      </b-button>
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-50 mb-25"
                        @click="getThisYearRange"
                      >
                        今年
                      </b-button>
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-50 mb-25"
                        @click="getBeforeMonthRange(6)"
                      >
                        前六個月
                      </b-button>
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-50 mb-25"
                        @click="getBeforeMonthRange(3)"
                      >
                        前三個月
                      </b-button>

                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-50 mb-25"
                        @click="getThisMonthRange"
                      >
                        本月
                      </b-button>
                    </div>
                  </template>
                </date-range-customize>
              </div>

              <b-row
                v-if="!isBusy"
                class="px-2"
              >
                <b-col
                  cols="6"
                  md="4"
                  sm="6"
                >
                  <div
                    class="department-option"
                    :class="{ 'department-option-active': searchDepartment === null }"
                    @click="searchDepartment = null"
                  >
                    全部
                  </div>
                </b-col>

                <b-col
                  v-for="department in departmentsOptions"
                  :key="department.id"
                  cols="6"
                  md="4"
                  sm="6"
                >
                  <div
                    class="department-option"
                    :class="{ 'department-option-active': searchDepartment === department.id }"
                    @click="searchDepartment = department.id"
                  >
                    {{ department.name }}
                  </div>
                </b-col>
              </b-row>

              <div class="d-flex justify-content-end px-2 mb-2">
                <button
                  class="content-analytic"
                  :disabled="isBusy"
                  @click="submitAnalytic"
                >
                  <span class="text">立即統計</span>
                  <svg
                    class="arrow"
                    viewBox="0 0 448 512"
                    height="1em"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </b-card> -->
      </b-tab>

      <b-tab title="月份">
        <b-card>
          <b-card-header class="p-0">

            <b-card-title class="mb-0">
              月份統計列表
            </b-card-title>

            <div />
          </b-card-header>

          <div class="chart-content">
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </div>
          <!-- :options="apexChatData.lineChartSimple.chartOptions"
            :series="apexChatData.lineChartSimple.series" -->
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import {
  BTabs, BTab, BCard, BCardHeader, BCardTitle, BImg, BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import moment from 'moment'
// import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import DateRangeCustomize from '@/layouts/components/Time/DateRangeCustomize.vue'
import { useTodoLineChart } from '../useTodo'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BImg,
    BRow,
    BCol,
    BButton,
    // BCardBody,
    BCardHeader,
    BCardTitle,

    VueApexCharts,
    // vSelect,
    DateRangeCustomize,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mounted() {
    this.initAnalytic()
  },
  methods: {
    moment,
    formatDateRange(dateRange) {
      if (dateRange === null) {
        return ''
      }
      // 切分日期範圍
      const [startDate, endDate] = dateRange.split(' to ')

      // 解析開始日期
      const startDateObj = new Date(startDate)
      const startYear = startDateObj.getFullYear()
      const startMonth = String(startDateObj.getMonth() + 1).padStart(2, '0')

      // 解析結束日期
      const endDateObj = new Date(endDate)
      const endYear = endDateObj.getFullYear()
      const endMonth = String(endDateObj.getMonth() + 1).padStart(2, '0')

      // 返回格式化的日期範圍
      return `${startYear}/${startMonth} ~ ${endYear}/${endMonth}`
    },

    // (獲取)本月
    getThisMonthRange() {
      const today = new Date()
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

      // 设置日期对象
      const date = {
        start: firstDayOfMonth,
        end: lastDayOfMonth,
      }
      this.$refs.dateRangeCustomizeAnalytic.specifiedTime(date.start, date.end)
    },

    // (獲取)去年年度
    getLastYearRange() {
      const today = new Date()
      const lastYear = today.getFullYear() - 1
      const startOfLastYear = new Date(lastYear, 0, 1)
      const endOfLastYear = new Date(lastYear, 11, 31)
      this.$refs.dateRangeCustomizeAnalytic.specifiedTime(startOfLastYear, endOfLastYear)
    },

    // (獲取)今年年度
    getThisYearRange() {
      const today = new Date()
      const startOfYear = new Date(today.getFullYear(), 0, 1)
      this.$refs.dateRangeCustomizeAnalytic.specifiedTime(startOfYear, today)
    },

    // (獲取)前N月
    getBeforeMonthRange(number) {
      const today = new Date()
      const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - number, 1)
      const endDate = new Date(today.getFullYear(), today.getMonth(), 0)
      this.$refs.dateRangeCustomizeAnalytic.specifiedTime(threeMonthsAgo, endDate)
    },

    // (初始化)日曆
    initAnalytic() {
      this.getMetaListData(() => {
        this.isBusy = false
        setTimeout(() => { this.$refs.dateRangeCustomizeAnalytic.initDataTime() }, 200)
      })
    },

    // (立即)統計
    submitAnalytic() {
      this.getDepartmentLineChart()
    },
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const {
      showChart,
      isBusy,
      isTableBusy,
      searchDepartment,
      timeRange,
      departmentsOptions,
      departmentChartData,
      getMetaListData,
      getDepartmentLineChart,
    } = useTodoLineChart()

    return {
      isDark,
      showChart,
      isBusy,
      isTableBusy,
      departmentChartData,
      searchDepartment,
      timeRange,
      departmentsOptions,
      getMetaListData,
      getDepartmentLineChart,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<style lang="scss" scoped>
.department-select {
  min-width: 150px;
}
.chart-content {
  min-height: 250px;
  .chart-content-loading {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .department-option {
    padding: 10px 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    &:hover {
      cursor: pointer;
    }
  }

  .content-analytic {
      width: 120px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      cursor: pointer;
      border: none;
      background-color: transparent;
      .text {
        // width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bolder;
        color: #419fd9;
      }
      .arrow path {
        fill: #419fd9;
      }
      &:hover .arrow {
        animation: slide-in-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }

      @keyframes slide-in-left {
        0% {
          transform: translateX(-8px);
          opacity: 0;
        }

        100% {
          transform: translateX(0px);
          opacity: 1;
        }
      }

      &:active {
        transform: scale(0.97);
      }
    }
}

.chart-content-light {
  .chart-content {
    .department-option {
      background-color:#00000010;
      &:hover {
        background-color: #b4d5ff4d
      }
    }

    .department-option-active {
      background-color: #b4d5ff80;
      border: 2px solid #b4d5ff;
      margin: -2px;
    }
  }
}

.chart-content-dark {
  .chart-content {
    .department-option {
      background-color:#ffffff10;
      &:hover {
        background-color: #ffffff39;
      }
    }

    .department-option-active {
      background-color: #b4d5ff4d;
      border: 1px solid #b4d5ff80;
      margin: -2px;
    }
  }
}
</style>
