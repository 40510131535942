<template>
  <div
    :class="!isDark ? 'editor' : 'editor-dark'"
  >
    <toolbar
      :editor="editor"
      :default-config="toolbarConfig"
      :mode="mode"
      class="editor-toolbar"
    />
    <editor
      v-model="html"
      :mode="mode"
      class="editor-area"
      :default-config="editorConfig"
      @onCreated="onCreated"
    />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { i18nAddResources, i18nChangeLanguage } from '@wangeditor/editor'
import '@wangeditor/editor/dist/css/style.css'

export default {
  components: {
    Editor,
    Toolbar,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editor: null,
      html: this.content,
    }
  },
  beforeDestroy() {
    const editorStill = this.editor
    if (editorStill == null) return
    this.editor.destroy()
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor)
    },

    getEditorContent() {
      return this.html
    },
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    i18nAddResources('ja', {
      listModule: {
        unOrderedList: '无序列表',
        orderedList: '有序列表',
      },
      common: {
        ok: '確定',
        delete: '刪除',
        enter: '回復',
      },

      blockQuote: {
        title: '引用',
      },
      codeBlock: {
        title: '程式碼',
      },
      color: {
        color: '文字顏色',
        bgColor: '背景色',
        default: '默認顏色',
        clear: '清除背景色',
      },
      divider: {
        title: '分隔線',
      },
      emotion: {
        title: '表情',
      },
      fontSize: {
        title: '字體大小',
        default: '默認大小',
      },
      fontFamily: {
        title: '字體',
        default: '默認字體',
      },
      fullScreen: {
        title: '全屏',
      },
      header: {
        title: '標題',
        text: '標題',
      },
      uploadImgModule: {
        uploadImage: '上傳圖片',
        uploadError: '{{fileName}} 上傳錯誤',
      },
      image: {
        netImage: '圖片連結',
        delete: '刪除圖片',
        edit: '編輯圖片',
        viewLink: '查看連結',
        src: '圖片網址',
        desc: '圖片描述',
        link: '圖片連結',
      },
      indent: {
        decrease: '减少縮排',
        increase: '增加縮排',
      },
      justify: {
        left: '靠左對齊',
        right: '靠右對齊',
        center: '置中',
        justify: '左右對齊',
      },
      lineHeight: {
        title: '行高',
        default: '默認行高',
      },
      link: {
        insert: '插入超連結',
        text: '連結文本',
        url: '超連結網址',
        unLink: '取消链接',
        edit: '修改超連結',
        view: '查看超連結',
      },
      // videoModule: {
      //   delete: '删除影片',
      //   uploadVideo: '上傳影片',
      //   insertVideo: '插入影片',
      //   videoSrc: '影片網址',
      //   insertPlaceHolder: '影片url 或第三方 <iframe>',
      //   ok: '確定',
      //   editSize: '修改尺寸',
      //   width: '寬度',
      //   height: '高度',
      //   videoPoster: '影片封面',
      //   videoSrcPlaceHolder: '影片url',
      //   videoPosterPlaceHolder: '影片封面url',
      // },
      textStyle: {
        bold: '粗體',
        clear: '清除格式',
        code: '程式碼',
        italic: '斜體',
        sub: '下標',
        sup: '上標',
        through: '刪除線',
        underline: '下底線',
      },
      tableModule: {
        deleteCol: '删除列',
        deleteRow: '删除行',
        deleteTable: '删除表格',
        widthAuto: '滿版寬度',
        insertCol: '插入列',
        insertRow: '插入行',
        insertTable: '插入表格',
        header: '表頭',
      },
      undo: {
        undo: '撤銷',
        redo: '重做',
      },
      todo: {
        todo: '待辦',
      },
    })

    i18nChangeLanguage('ja')

    const mode = 'default' // or 'simple'

    const toolbarConfig = {
      excludeKeys: ['fontFamily', 'fullScreen', 'uploadImage', 'group-video', 'uploadVideo', 'insertVideo', 'codeBlock'],
    }
    const editorConfig = { placeholder: '請輸入內容...' }

    return {
      isDark,
      mode,
      toolbarConfig,
      editorConfig,
    }
  },
}
</script>

<style lang="scss" scoped>
.editor-area {
  height: 300px;
}
.editor {
  .editor-toolbar {
    border-bottom: 1px solid #ccc;
  }
  --w-e-textarea-bg-color: #fff; //#fff
  --w-e-textarea-color: #333;
  --w-e-textarea-border-color: #ccc;
  --w-e-textarea-slight-border-color: #e8e8e8;
  --w-e-textarea-slight-color: #d4d4d4;
  --w-e-textarea-slight-bg-color: #f5f2f0;
  --w-e-textarea-selected-border-color: #B4D5FF;
  --w-e-textarea-handler-bg-color: #4290f7;
  --w-e-toolbar-color: #595959;
  --w-e-toolbar-bg-color: #fff; //#fff
  --w-e-toolbar-active-color: #333;
  --w-e-toolbar-active-bg-color: #f1f1f1;
  --w-e-toolbar-disabled-color: #999;
  --w-e-toolbar-border-color: #e8e8e8;
  --w-e-modal-button-bg-color: #fafafa;
  --w-e-modal-button-border-color: #d9d9d9;
  border: 1px solid #ccc;
  border-radius: 5px;
  .w-e-bar {
    border-radius: 5px 5px 0 0;
  }
  .w-e-text-container {
    border-radius: 0 0 5px 5px;
  }
  .w-e-scroll {
    margin-right: 14px;
    background-color: #161d31;
    padding: 10px;
  }
}

.editor-dark {
  .editor-toolbar {
    border-bottom: 1px solid #596987;
  }
  // #344464
  --w-e-textarea-bg-color: #344464;
  --w-e-textarea-color: #d0d2d6;
  --w-e-textarea-border-color: #b4b7bd;
  --w-e-textarea-slight-border-color: #ffc800;
  --w-e-textarea-slight-color: #b0b1b5aa ;
  --w-e-textarea-slight-bg-color: #283856;
  --w-e-textarea-selected-border-color: #24c9ff;
  --w-e-textarea-handler-bg-color: #24c9ff;
  --w-e-toolbar-color: #d0d2d6;
  --w-e-toolbar-bg-color: #344464;
  --w-e-toolbar-active-color: #d0d2d6;
  --w-e-toolbar-active-bg-color: #161d31;
  --w-e-toolbar-disabled-color: #b0b1b5aa;
  --w-e-toolbar-border-color: #596987;
  --w-e-modal-button-bg-color: #24c9ff;
  --w-e-modal-button-border-color: #596987;
  border: 1px solid #596987;
  border-radius: 5px;
  .w-e-bar {
    border-radius: 5px 5px 0 0;
  }
  .w-e-text-container {
    border-radius: 0 0 5px 5px;
  }
}
</style>
