<template>
  <div
    class="chat-right-sidebar"
    :class="{'show': shallShowActiveChatContactSidebar}"
  >
    <header
      v-if="contact"
      class="user-profile-header"
    >
      <div
        class="cursor-pointer table-header-close close-icon"
        style="height: 15px; width: 20px;"
        @click="$emit('update:shall-show-active-chat-contact-sidebar', false)"
      >
        <div class="line" />
        <div class="line" />
      </div>

      <div class="header-profile-sidebar">
        <div>
          <h4 class="chat-user-name">
            {{ contact.data.title ? contact.data.title : '未命名標題' }}
          </h4>
        </div>

        <div v-if="contact.state">
          <b-badge
            class="border mr-50 todo-tag-area-badge"
            :style="{
              'background-color': contact.priorityLists[contact.data.priority].color,
              'color': contact.priorityLists[contact.data.priority].font_color,
            }"
          >
            {{ contact.priorityLists[contact.data.priority].title }}
          </b-badge>

          <b-badge
            class="border todo-tag-area-badge"
            :style="{
              'background-color': contact.statusLists[contact.data.status].color,
              'color': contact.statusLists[contact.data.status].font_color,
            }"
          >
            {{ contact.statusLists[contact.data.status].title }}
          </b-badge>
        </div>
      </div>
    </header>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="chat-right-sidebar-area scroll-area"
    >

      <h6 class="section-label mb-50">
        執行人員
      </h6>

      <div class="text-right mb-2">
        <div
          v-if="contact.data.assigned_user.length > 0"
          class="cursor-pointer text-right"
          @click="submitEditAssignedUsers"
        >

          <b-avatar
            v-if="contact.data.assigned_user.length === 1"
            v-b-tooltip.hover.bottom="contact.data.show_user.array[0].user_info.name"
            :text="avatarText(contact.data.show_user.array[0].user_info.name)"
            class="pull-up cursor-pointer"
          />

          <div
            v-else-if="contact.data.assigned_user.length > 1"
            class="w-100 d-flex justify-content-end"
          >
            <b-avatar-group>
              <b-avatar
                v-for="user in contact.data.show_user.array"
                :key="user.id"
                v-b-tooltip.hover.bottom="user.user_info.name"
                :text="avatarText(user.user_info.name)"
                class="pull-up cursor-pointer"
              />
              <h6
                v-if="contact.data.show_user.number"
                class="align-self-center cursor-pointer ml-1 mb-0"
              >
                +{{ contact.data.show_user.number }}
              </h6>
            </b-avatar-group>
          </div>

        </div>

        <div
          v-else
          @click="submitEditAssignedUsers"
        >
          <b-avatar
            v-b-tooltip.hover.bottom.focus
            title="尚未設定"
            class="cursor-pointer"
          />
        </div>
      </div>

      <!-- <h6 class="section-label mb-50">
        待辦詳情
      </h6> -->

      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h6 class="section-label mb-50">
            待辦詳情
          </h6>
        </div>

        <div v-if="!isReview">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="$emit('submit-update-todo')"
          >
            編輯
          </b-button>
        </div>
      </div>

      <div class="mb-2">
        <b-form-group
          label-cols="4"
          label="待辦編號"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ contact.data.serialNumber ? `#${contact.data.serialNumber}` : '---' }}
          </div>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label="創建人員"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ contact.data.created_user_id ? contact.data.created_user_info.name : '---' }}
          </div>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label="負責部門"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            <b-badge
              v-if="contact.data.department_info.id"
              variant="light-info"
            >
              {{ contact.data.department_info.id ? contact.data.department_info.name : contact.data.department_id }}
            </b-badge>

            <span v-else>---</span>

          </div>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label="委託客戶"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ contact.data.client_name ? contact.data.client_name : '---' }}
          </div>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label="作業分類"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            <b-badge
              v-if="contact.data.type_id"
              variant="light-success"
            >
              {{ contact.data.type_info.name }}
            </b-badge>

            <span v-else>---</span>
          </div>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label="項目設定"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            <b-badge
              v-if="contact.data.execution_type_id"
              variant="light-warning"
            >
              {{ contact.data.execution_type_info.name }}
            </b-badge>

            <span v-else>---</span>
          </div>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label="顯示狀態"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ contact.data.display_status ? '開啟' : '關閉' }}
          </div>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label="排定時間"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ contact.data.scheduled_at ? moment(contact.data.scheduled_at).format('YYYY-MM-DD HH:mm') : '---' }}
          </div>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label="截止時間"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ contact.data.deadline_at ? moment(contact.data.deadline_at).format('YYYY-MM-DD HH:mm') : '---' }}
          </div>
        </b-form-group>

        <div v-if="!contact.data.completed_user_id">
          <b-form-group
            v-if="contact.data.deadline_at && updateExtendedOnline(contact.data.deadline_at) !== '---'"
            label-cols="4"
            label=""
            class="m-0 selection-group"
          >
            <div class="mt-50">
              <div v-if="calcRemainDays(contact.data.deadline_at) >= 3">
                剩餘 {{ calcRemainDays(contact.data.deadline_at) }} 天
              </div>

              <div v-else>
                剩餘 {{ updateExtendedOnline(contact.data.deadline_at, refonlineTime) }}
              </div>
            </div>
          </b-form-group>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h6 class="section-label mb-50">
            完成狀態
          </h6>
        </div>
      </div>

      <div class="mb-2">
        <b-form-group
          label-cols="4"
          label="完成人員"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ contact.data.completed_user_id ? contact.data.complete_user_info.name : '---' }}
          </div>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label="完成時間"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ contact.data.completed_at ? moment(contact.data.completed_at).format('YYYY-MM-DD HH:mm') : '---' }}
          </div>
        </b-form-group>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h6 class="section-label mb-50">
            複查狀態
          </h6>
        </div>

        <div>
          <b-button
            variant="outline-primary"
            size="sm"
            @click="$emit('emit-review-todo', [contact.data.id])"
          >
            複查
          </b-button>
        </div>
      </div>

      <div>
        <b-form-group
          label-cols="4"
          label="複查人員"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ contact.data.review_user_id ? contact.data.review_user_info.name : '---' }}
          </div>
        </b-form-group>

        <b-form-group
          label-cols="4"
          label="複查時間"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ contact.data.review_at ? moment(contact.data.review_at).format('YYYY-MM-DD HH:mm') : '---' }}
          </div>
        </b-form-group>
      </div>
    </vue-perfect-scrollbar>

    <b-modal
      id="todoAssignedUser"
      no-close-on-backdrop
      centered
    >
      <template #modal-title>
        <h4 class="m-0">
          執行人員
        </h4>
      </template>

      <div>
        <div
          v-for="role in contact.roleOptions"
          :key="role.value"
          class="mb-1"
        >
          <div>
            <div class="d-flex justify-content-between align-items-center mb-75">
              <h5 class="mb-0">
                {{ role.title }}
                <!-- <b-img
                  v-b-tooltip.hover.bottom.focus
                  :title="role.description"
                  src="/admin/images/common/info.svg"
                  class="cursor-pointer"
                  style="height: 16px; margin-top: -3px"
                  rounded
                /> -->
                <!-- ( {{ capitalizeFirstLetter(role.value) }} ) -->
              </h5>

              <span>
                <b-img
                  v-b-tooltip.hover.bottom.focus
                  :title="role.description"
                  src="/admin/images/common/info.svg"
                  class="cursor-pointer"
                  style="height: 17px;"
                  rounded
                />

                <b-img
                  v-if="!isReview"
                  v-b-tooltip.hover.bottom.focus
                  :title="`新增${role.title}`"
                  src="/admin/images/table/plus.svg"
                  class="image-icon cursor-pointer ml-50"
                  rounded
                  @click="submitEditAssignedUser(groupedUsers[role.value] ? groupedUsers[role.value] : [], role.value)"
                />
              </span>
            </div>

            <div
              v-if="groupedUsers[role.value]"
              class="ml-1"
            >
              <!-- {{ groupedUsers[role.value] }} -->
              <div
                v-for="(user, index) in groupedUsers[role.value]"
                :key="`auth-${index}`"
                class="mb-2"
              >
                <div class="d-flex justify-content-between align-items-center auth-users">
                  <div
                    class="d-flex justify-content-start align-items-center"
                    @click="submitDescriptionAssignedUser(user, role.value, index)"
                  >
                    <div class="mr-1">
                      <b-avatar
                        size="40"
                        variant="light-primary"
                      />
                    </div>

                    <div class="profile-user-info cursor-pointer">
                      <h6
                        class="mb-0"
                      >
                        <span class="text-primary">{{ user.user_info.name }}</span>
                        <small
                          v-if="user.department_info.id"
                          style="word-break: break-all"
                        >
                          ( {{ user.department_info.name }} )
                        </small>
                      </h6>

                      <small
                        style="word-break: break-all"
                        class="text-muted"
                      >{{ user.description }}</small>
                    </div>
                  </div>

                  <div v-if="!isReview">
                    <feather-icon
                      v-b-tooltip.hover.bottom.focus
                      title="移除"
                      icon="XIcon"
                      size="20"
                      class="cursor-pointer text-muted"
                      @click="submitRemoveAssignedUser(user, role.value, index)"
                    />
                  </div>

                  <!-- <div>
                    <b-dropdown
                      variant="link"
                      no-caret
                      class="chart-dropdown"
                      toggle-class="p-0"
                      right
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="18"
                          class="text-body cursor-pointer"
                        />
                      </template>

                      <b-dropdown-item @click="setAuthUser(data, 'auth')">
                        <feather-icon icon="LockIcon" />
                        <span class="align-middle ml-50">權限管理</span>
                      </b-dropdown-item>

                      <b-dropdown-item @click="removeUser(data, 'auth')">
                        <feather-icon icon="Trash2Icon" />
                        <span class="align-middle ml-50">移除</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div> -->
                </div>
              </div>

            </div>

            <div
              v-if="!groupedUsers[role.value] || groupedUsers[role.value].length === 0"
              class="cursor-pointer mb-2 ml-1"
            >
              <div
                class="d-flex justify-content-start align-items-center"
                @click="submitEditAssignedUser(groupedUsers[role.value] ? groupedUsers[role.value] : [], role.value)"
              >
                <div class="mr-1">
                  <b-avatar
                    size="40"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="21"
                      icon="PlusIcon"
                    />
                  </b-avatar>
                </div>

                <div class="profile-user-info">
                  新增{{ role.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            :disabled="isBusy"
            @click="confirmEditAssignedUsers"
          >
            確認
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="todoAssignedUserUpdate"
      no-close-on-backdrop
      centered
    >
      <template #modal-title>
        <h4 class="m-0">
          協助說明 - {{ assignedUserData.user_info.name }}
        </h4>
      </template>

      <div v-if="assignedUserData">
        <div>
          <b-form-textarea
            v-model="assignedUserData.description"
            placeholder="請輸入協助說明"
            :state="assignedUserData.description.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="assignedUserData.description.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ assignedUserData.description.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            @click="confirmEditAssignedUser"
          >
            確認
          </b-button>
        </div>
      </template>

    </b-modal>

    <users-explorer-modal
      ref="usersExplorerUpdateModal"
      :choices-users="assignedUsers"
      :is-edit-auth="false"
      user-explorer-id="usersExplorerUpdateModal"
      :is-hiden-emit="false"
      :use-callback-data="true"
      @call-back-data="callbackUsersExplorer"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BFormGroup, BBadge, BAvatar, BAvatarGroup, VBTooltip, BModal, BButton, BImg, BFormTextarea,
} from 'bootstrap-vue'
import { useTodoView, useTodoSetting, useTodoList } from '../useTodo'
import { useCommenSettings, useAlert, useSwalToast } from '@/libs/mixins/index'
import usersExplorerModal from './UsersModal.vue'

export default {
  components: {
    BImg,
    BModal,
    BBadge,
    BFormGroup,
    BAvatar,
    BButton,
    BAvatarGroup,
    BFormTextarea,

    VuePerfectScrollbar,
    usersExplorerModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    shallShowActiveChatContactSidebar: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      groupedUsers: {},
      assignedUserData: null,
      assignedUsers: [],
      assignedUser: {
        type: null,
        index: null,
      },
      maxChar: 255,
    }
  },
  computed: {
    isReview() {
      return this.contact.data.review_user_id
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (處理時間)
    resolveTime(time) {
      if (time) return moment(time).format('YYYY/MM/DD HH:mm')
      return moment().format('YYYY/MM/DD HH:mm')
    },

    // (計算)剩餘天數
    calcRemainDays(end) {
      const startDate = moment(new Date())
      let daysDiff = 0
      const endDate = moment(end)
      daysDiff = endDate.diff(startDate, 'days')
      return daysDiff
    },

    updateExtendedOnline(time) {
      const endTime = Math.floor(new Date(this.resolveTime(time).replace(/-/g, '/')).getTime() / 1000)
      const today = Math.floor(new Date().getTime() / 1000)
      let diffTime = endTime - today
      if (diffTime <= 0) return '---'
      // 定義單位時間
      const second = 1
      const minute = second * 60
      const hour = minute * 60
      const day = hour * 24

      const days = Math.floor(diffTime / day)
      diffTime -= days * 24 * 60 * 60

      const hours = Math.floor(diffTime / hour)
      diffTime -= hours * 60 * 60

      const minutes = Math.floor(diffTime / minute)
      diffTime -= minutes * 60

      if (days > 0) return `${days} 天 ${hours} 小時`
      if (hours > 0) return `${hours} 小時 ${minutes} 分`
      return `${minutes} 分 ${diffTime} 秒`
    },

    capitalizeFirstLetter(str) {
      return str.replace(/\b\w/g, char => char.toUpperCase())
    },
    // ---------------------------------------------------------------------------------
    // (觸發)編輯執行人員
    submitEditAssignedUsers() {
      const groupedUserArray = this.contact.data.assigned_user.reduce((grouped, user) => {
        const roleListItem = this.contact.roleOptions.find(roleItem => roleItem.value === user.role)

        const groupedArr = grouped

        if (roleListItem) {
          const groupName = roleListItem.value

          if (!grouped[groupName]) {
            groupedArr[groupName] = []
          }

          groupedArr[groupName].push(user)
        }

        return groupedArr
      }, {})
      this.groupedUsers = groupedUserArray
      this.assignedUsers = JSON.parse(JSON.stringify(this.contact.data.assigned_user))
      this.$bvModal.show('todoAssignedUser')
    },

    // (觸發)編輯單一執行人員
    submitEditAssignedUser(users, type) {
      if (this.isReview) return
      this.assignedUser.type = type
      setTimeout(() => { this.$refs.usersExplorerUpdateModal.getData(users) }, 200)
    },

    // (觸發)編輯單一執行人員
    submitDescriptionAssignedUser(users, type, index) {
      if (this.isReview) return
      this.assignedUser.type = type
      this.assignedUser.index = index
      this.assignedUserData = JSON.parse(JSON.stringify(users))
      this.$bvModal.show('todoAssignedUserUpdate')
    },

    // (觸發)移除單一執行人員
    submitRemoveAssignedUser(user, type, index) {
      this.useSwalAlertWarning('移除執行人員', `你確定要移除執行人員 ${user.user_info.name} 嗎?`)
        .then(result => {
          if (result.value) {
            if (this.groupedUsers[type] && this.groupedUsers[type].length > index) {
              this.groupedUsers[type].splice(index, 1)
            }
            this.assignedUsers = this.assignedUsers.filter(item => item.user_id !== user.user_id)
          }
        })
    },

    // (回調)選擇的使用者
    callbackUsersExplorer(users) {
      if (!Object.prototype.hasOwnProperty.call(this.groupedUsers, this.assignedUser.type)) {
        this.$set(this.groupedUsers, this.assignedUser.type, this.groupedUsers[this.assignedUser.type] || [])
      }

      users.forEach(user => {
        const resolveUser = {
          ...this.syncObject(this.blankAssignedData, user),
          user_info: this.syncObject(this.blankSimpleInfo, user.user_info),
          department_info: this.syncObject(this.blankSimpleInfo, user.department_info),
        }
        // resolveUser.role = this.assignedUser.type
        // if (this.groupedUsers[this.assignedUser.type])
        // if (this.assignedUsers.some(item => item.user_id === resolveUser.id)) {
        this.groupedUsers[this.assignedUser.type].push(resolveUser)
        this.assignedUsers.push(resolveUser)
        // }
      })
    },

    // (編輯)單一執行人員
    confirmEditAssignedUser() {
      if (this.assignedUserData.description && this.assignedUserData.description.length > this.maxChar) {
        this.useAlertToast(false, '字數超過上限')
        return
      }

      this.groupedUsers[this.assignedUser.type].splice(this.assignedUser.index, 1, this.assignedUserData)

      this.assignedUsers = this.assignedUsers.map(item => {
        if (item.user_id === this.assignedUserData) {
          const resolve = {
            ...item, description: this.assignedUserData.description,
          }
          return resolve
        }
        return item
      })
      this.$bvModal.hide('todoAssignedUserUpdate')
    },

    // (編輯)執行人員
    confirmEditAssignedUsers() {
      const flatArray = []
      const keys = Object.keys(this.groupedUsers)
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i]
        if (Object.prototype.hasOwnProperty.call(this.groupedUsers, key)) {
          const innerArray = this.groupedUsers[key]
          for (let j = 0; j < innerArray.length; j += 1) {
            innerArray[j].role = key
            flatArray.push(innerArray[j])
          }
        }
      }
      const resolveData = {
        assigned_user: flatArray,
      }
      this.isBusy = true
      this.setTodoUpdate({
        todo_id: this.contact.data.id,
        data: { ...resolveData },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.$bvModal.hide('todoAssignedUser')
          this.$emit('update-refresh', this.contact.data.id)
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      isBusy,
      setTodoUpdate,
    } = useTodoList()

    const {
      blankAssignedData,
    } = useTodoView()

    const {
      blankSimpleInfo,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useTodoSetting()

    const {
      syncObject,
    } = useCommenSettings()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    return {
      syncObject,
      avatarText,
      useAlertToast,
      useHttpCodeAlert,
      isBusy,
      setTodoUpdate,
      perfectScrollbarSettings,

      blankAssignedData,
      blankSimpleInfo,
      refonlineTime,
      onlineTime,
      updateOnline,
    }
  },
}
</script>

<style lang="scss" scoped>
.header-profile-sidebar {
  height: 180px !important;

  .chat-user-name {
    word-break: break-all;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 10px;
  }
}
.image-icon {
  height: 16px;
}
</style>
