<template>
  <div
    class="chat-profile-sidebar"
    :class="{'show': shallOpenTodoSettingSidebarState}"
  >
    <!-- Header -->
    <header class="d-flex align-items-center justify-content-between mx-1 my-2">
      <div>
        <h4 class="chat-user-name mb-0">
          個人設定
        </h4>
      </div>

      <div
        class="cursor-pointer table-header-close"
        style="height: 15px; width: 20px;"
        @click="$emit('close-sidebar')"
      >
        <div class="line" />
        <div class="line" />
      </div>
    </header>

    <hr class="mb-0 dividing-line">

    <div class="d-flex align-items-center justify-content-between flex-column scroll-area w-100">
      <div class="w-100">
        <div class="p-1">
          <!-- 全屏模式 -->
          <b-form-group label-for="display_status">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">全屏模式</label>

                <toggle-button
                  v-model="customSearch.useFullScreen"
                  :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                  :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                  :width="60"
                  :height="24"
                  class="m-25 mb-50"
                  :labels="{checked: '開啟', unchecked: '關閉'}"
                  :sync="true"
                />
              </div>
            </template>
          </b-form-group>

          <!-- 提醒音效 -->
          <b-form-group label-for="display_status">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <label class="mb-0">提醒音效</label>

                  <!-- <span>
                    <div
                      v-b-tooltip.hover.v-secondary
                      title="設定為關閉，會在排定時間自動開啟"
                      class="d-flex align-items-center"
                      style="padding: 0 5px;"
                    >
                      <b-img
                        src="/admin/images/common/info.svg"
                        class="actions-link-btn-image cursor-pointer"
                        rounded
                        style=" width: 14px; height: 14px;"
                      />
                    </div>
                  </span> -->
                </div>

                <!-- v-model="todoData.display_status" -->
                <toggle-button
                  v-model="customSearch.useAudio"
                  :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                  :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                  :width="60"
                  :height="24"
                  class="m-25 mb-50"
                  :labels="{checked: '開啟', unchecked: '關閉'}"
                  :sync="true"
                />
              </div>
            </template>
          </b-form-group>

          <!-- 顯示自訂新增 -->
          <b-form-group label-for="display_status">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <label class="mb-0">顯示自訂新增</label>
                </div>

                <!-- v-model="todoData.display_status" -->
                <toggle-button
                  v-model="customSearch.useQuick"
                  :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                  :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                  :width="60"
                  :height="24"
                  class="m-25 mb-50"
                  :labels="{checked: '開啟', unchecked: '關閉'}"
                  :sync="true"
                />
              </div>
            </template>
          </b-form-group>

          <!-- 快速新增自定義 -->
          <div class="text-nowrap d-flex justify-content-between align-items-center my-2">
            <label class="mb-0">快速新增自定義</label>

            <div
              v-b-tooltip.hover.focus.v-secondary
              title="設定"
              class="d-flex align-items-center actions-link-btn"
              @click="$emit('open-todo-quick-list-data')"
            >
              <b-img
                src="/admin/images/table/setting.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>

          <!-- 篩選自定義 -->
          <div class="text-nowrap d-flex justify-content-between align-items-center my-2">
            <label class="mb-0">篩選自定義</label>

            <div
              v-b-tooltip.hover.focus.v-secondary
              title="設定"
              class="d-flex align-items-center actions-link-btn"
              @click="$emit('open-todo-config-list-data')"
            >
              <b-img
                src="/admin/images/table/setting.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, VBTooltip, BImg,
} from 'bootstrap-vue'
import { ToggleButton } from 'vue-js-toggle-button'
import { useQuickList } from '../useTodo'

export default {
  components: {
    BImg,
    BFormGroup,
    ToggleButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    shallOpenTodoSettingSidebarState: {
      type: Boolean,
      required: true,
    },
    customSearch: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      isTableBusy: isTableBusyQuick,
      refDataListTable: refDataListTableQuick,
      tableColumns: tableColumnsQuick,

      setTodoConfigDelete,
      setTodoConfigCreate,
      setTodoConfigUpdate,
      // getTodoConfigList,

      configData,
      blankContentSetting,
      blankContentData,
      blankConfigData,
    } = useQuickList()

    return {
      tableColumnsQuick,
      isTableBusyQuick,
      refDataListTableQuick,

      setTodoConfigDelete,
      setTodoConfigCreate,
      setTodoConfigUpdate,
      // getTodoConfigList,

      configData,
      blankContentSetting,
      blankContentData,
      blankConfigData,
    }
  },
}
</script>

<style lang="scss" scoped>
.scroll-area {
  height: calc(100% - 130px);
  overflow-y: scroll;
}
</style>
